import { failure, request, success } from "..";
import { MentorServices } from "../../services";

export const FEATURED_MENTORS_LIST_REQUEST = 'FEATURED_MENTORS_LIST_REQUEST';
export const FEATURED_MENTORS_LIST_SUCCESS = 'FEATURED_MENTORS_LIST_SUCCESS';
export const FEATURED_MENTORS_LIST_FAILURE = 'FEATURED_MENTORS_LIST_FAILURE';

export const MENTOR_REFER_ID_REQUEST = 'MENTOR_REFER_ID_REQUEST';
export const MENTOR_REFER_ID_SUCCESS = 'MENTOR_REFER_ID_SUCCESS';
export const MENTOR_REFER_ID_FAILURE = 'MENTOR_REFER_ID_FAILURE';

export const MentorAction = {

    /**
     * This is an action creator function called `signIn` that dispatches actions to sign in a user.
     * It takes in a `params` object as an argument which contains the email and password of the user.
     * @param {Object} params - The params which are used for registration api.
     * @param {String} params.email - Email of the user
     * @param {String} params.password - Password of the user
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    getFeaturedMentorsList: (params) => {
        return dispatch => {
            dispatch(request(FEATURED_MENTORS_LIST_REQUEST, params));
            return MentorServices.getFeaturedMentorsList(params)
                .then(
                    response => { return dispatch(success(FEATURED_MENTORS_LIST_SUCCESS, params, response)); },
                    error => { return dispatch(failure(FEATURED_MENTORS_LIST_FAILURE, params, error)); }
                );
        };
    },

};