import React from 'react';
import { Box, styled } from '@mui/material';

import backgroundImage from './../../../../_theme/images/bg-05.png';
import backgroundImageWeb from './../../../../_theme/images/bg-05-web.png';

const BannerStyled = styled(({ ...props }) => <Box {...props} />)`
	& .section {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		background-image: linear-gradient(rgba(0, 0, 0, 0.3) 80%, rgba(255, 255, 255, 0.3) 90%,rgba(255, 255, 255, 1) 100%) , url(${backgroundImage});
		${(props) => props.theme.breakpoints.up('sm')} {
			background-image:linear-gradient(rgba(0, 0, 0, 0.3) 80%, rgba(255, 255, 255, 0.3) 90%,rgba(255, 255, 255, 1) 100%) , url(${backgroundImageWeb});
		}
		& .section-content {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			min-height: ${(props) => `${props.theme.functions.pxToRem(380)}`};
			${(props) => props.theme.breakpoints.up('sm')} {
				min-height: ${(props) => `${props.theme.functions.pxToRem(580)}`};
			}
			& .for-mentors-btn {
				position: absolute;
				bottom: ${(props) => props.theme.spacing(4)};
				${(props) => props.theme.breakpoints.up('sm')} {
					min-height: ${(props) => `${props.theme.functions.pxToRem(60)}`};
					padding-left: ${(props) => props.theme.spacing(2)};
					padding-right: ${(props) => props.theme.spacing(2)};
					bottom: ${(props) => props.theme.spacing(16)};
				}
			}

			& .description {
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				color: ${(props) => props.theme.palette.light.main};
				font-weight: 550;
				${(props) => props.theme.breakpoints.up('md')} {
					font-size: ${(props) => props.theme.typography.h1.fontSize};
				}
				& a {
					margin-left: ${(props) => props.theme.spacing(1)};
					font-size: ${(props) => props.theme.typography.h5.fontSize};
				}
			}

			& .MuiStack-root {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
	& .section-footer {
		background: ${(props) => props.theme.palette.dark.main};
		padding: ${(props) => props.theme.spacing(2)};
		display: flex;
		align-items: center;
		justify-content: center;
		${(props) => props.theme.breakpoints.up('sm')} {
			min-height: ${(props) => `${props.theme.functions.pxToRem(110)}`};
		}
		& .description {
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			color: ${(props) => props.theme.palette.common.white};
			font-size: ${(props) => props.theme.typography.h6.fontSize};
			${(props) => props.theme.breakpoints.up('sm')} {
				font-size: ${(props) => props.theme.typography.d6.fontSize};
			}
		}
	}
`;

export { BannerStyled };
