import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleInputChange, MENTEE_CAPITALIZE, validateForm } from '../../../../_helpers';
import { PreAuth } from '../../../../_layouts/pre-auth';
import { postAuthRoutes, preAuthRoutes } from '../../../../routes';
import { FormInput, FormPassword } from '../../../../_components/form';
import { RouteLink, SubmitButton } from '../../../../_components/controls';
import { Box, CardMedia, Grid, Typography } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { MenteeAuthAction } from '../../../../redux/actions';
import { MenteeSignInStyled, validate } from '.';
import { Animate } from '../../../../_components';

import bannerImage from './../../../../_theme/images/bg-01.png';

/* Initializing the input with the default values. */
const inputs = { email: '', password: '' };

function MenteeSignIn() {

    /** Initialize plugins and variables */
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    /* Destructuring the state from redux store. */
    const { sign_in_mentee_loading, sign_in_mentee_errors } = useSelector((state) => state.MenteeAuthReducer);
    const signInMentee = (params) => dispatch(MenteeAuthAction.signInMentee(params));

    /* Initializing the state with the default values. */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, remember_me: '' });
    const [action, setAction] = useState({ isSubmitted: false });

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        setErrors({ ...sign_in_mentee_errors });
    }, [sign_in_mentee_errors]);

    /**
     * It takes the name and value of the input, validates it, and sets the errors and data state
     * @param {Object} e form object
     * @param {String} e.name input name
     * @param {String} e.value input value
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        /* Validating the input and setting the errors state. */
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * The function handles the submit event of the form. It prevents the default action of the form,
     * sets the isSubmitted state to true, and then validates the form. If the form is valid, it
     * dispatches the corresponding action
     * @param {Object} e the event object
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    function handleSubmit(e) {

        e.preventDefault();
        setAction({ isSubmitted: true });

        /* Iterating through the data object and validating each key value pair. */
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value);
            formErrors[name] = inputError[name];
        }

        /* Checking if the form is valid or not. If the form is not valid, it sets the errors state and returns. */
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchSignInAction();
    }

    /**
     * It checks if the user is signed in, and if so, redirects to the home page
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    async function dispatchSignInAction() {
        /* Dispatching the signIn action and waiting for the response. */
        const isSignIn = await signInMentee(data);
        if (isSignIn.status === 1) {
            /**
             * Checking if the user is redirected from a page, and
             * if so, redirects to that page, else redirects to the home page.
             */
            let redirectPath = ((location.state && location.state.from.pathname) ? (location.state.from.pathname) : postAuthRoutes('dashboard').path);
            navigate(`${redirectPath}`);
        }
        else if (isSignIn.status === 3) {
            let redirectPath = `${preAuthRoutes('mentee_register_basic_details').path}?authToken=${isSignIn.data._id}`;
            navigate(redirectPath);
        }
    }

    return (
        <PreAuth>
            <MenteeSignInStyled>
                <Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} display={`flex`} justifyContent={`center`} sx={{ alignItems: { xs: `flex-start`, md: `center` } }}>
                            <Animate>
                                <form onSubmit={handleSubmit} noValidate>
                                    <Grid container spacing={2} >
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography variant="h5" >{`${preAuthRoutes('mentee_sign_in').name}`}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={1}
                                                label='Email'
                                                name='email'
                                                value={data.email}
                                                error={action.isSubmitted && errors.email ? errors.email : ''}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormPassword
                                                tabIndex={1}
                                                label='Password'
                                                name='password'
                                                value={data.password}
                                                error={action.isSubmitted && errors.password ? errors.password : ''}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" alignItems="center" justifyContent="right"  >
                                            <RouteLink to={preAuthRoutes('forgot_password').path} label={preAuthRoutes('forgot_password').name} tabIndex={4} className='f-password' />
                                        </Grid> */}
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" alignItems="center" justifyContent="center" >
                                            <SubmitButton fullWidth label={`Sign In`} loading={sign_in_mentee_loading} tabIndex={3} endIcon={<ArrowForward />} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" alignItems="center" justifyContent="center"  >
                                            <RouteLink to={preAuthRoutes('mentee_register_auth_details').path} label={<React.Fragment>or&nbsp;<b>{`Join as a ${MENTEE_CAPITALIZE}`}</b></React.Fragment>} tabIndex={5} />
                                        </Grid>
                                    </Grid>
                                </form>
                            </Animate>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={`center`} justifyContent={`center`}>
                            <Animate>
                                <CardMedia component={`img`} src={bannerImage} />
                            </Animate>
                        </Grid>
                    </Grid>
                </Box>
            </MenteeSignInStyled>
        </PreAuth>
    );
}

export { MenteeSignIn };