import React, { Fragment, useState } from 'react';
import { Box, Button, Divider, IconButton, Menu, MenuItem, Stack } from '@mui/material';
import { MenuBarStyled, SwipeableDrawerStyled } from '.';
import { Close, Home, Menu as MenuIcon } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { RouteLink, Logo } from '../../../_components';
import { preAuthRoutes } from '../../../routes';
import { IOS_APP_URL, MENTEES_CAPITALIZE } from '../../../_helpers';

function MenuBar({ ismenuwhite }) {

    /** Initialize plugins and variables */
    const location = useLocation();
    const menuRoutes = [
        { path: preAuthRoutes('mentor_pre_register').path, name: "Mentors", },
        { path: preAuthRoutes('mentee_landing_page').path, name: MENTEES_CAPITALIZE, },
        // { path: preAuthRoutes('giveaways').path, name: "Monthly Giveaways", },
        { path: `${preAuthRoutes('pave_partner_refer_and_earn').path}`, name: "Refer and Earn", },
        { path: preAuthRoutes('about_us').path, name: "About Pave", },
        { path: preAuthRoutes('contact_us').path, name: "Contact Us", },
        { path: IOS_APP_URL, name: "Download Pave", }
    ];

    /* Initializing the state with the default values. */
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const [opens, setOpens] = useState(false);
    const [drawer, setDrawer] = useState(false);

    /**
     * This function sets the anchor element and toggles the state of a boolean variable.
     * @param {Object} event - The event parameter is an object that contains information about the event that
     * triggered the handleClick function. This could be a mouse click, a keyboard press, or any other
     * type of user interaction with the application. The event object contains properties such as the
     * target element, the type of event, and any additional data
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpens(!opens);
    };

    /**
     * The function handleClose sets the value of AnchorEl to null.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const handleClose = () => { setAnchorEl(null); };

    /**
     * The function toggles the state of a drawer and sets the hovered state to false.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const toggleDrawer = () => {
        setDrawer(!drawer);
    };


    return (
        <MenuBarStyled ismenuwhite={ismenuwhite} >

            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} className="menu-bar-web">
                {menuRoutes.map((route, index) => {
                    var splitPathName = location.pathname.split('/');
                    var parentPathName = splitPathName.slice(0, 2).join('/');
                    return (<RouteLink key={index} to={route.path} label={<Button className={(route.path === location.pathname) ? `menu-active` : ((parentPathName === route.path) ? 'menu-active' : '')}>{route.name}</Button>} />);
                })}
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton onClick={() => toggleDrawer(true)}  > <MenuIcon className='hamburger' /> </IconButton>
                <SwipeableDrawerStyled anchor={'top'} open={drawer} onClose={() => toggleDrawer(false)} onOpen={() => toggleDrawer(true)} >

                    <Stack direction={'row'} display={`flex`} justifyContent={`space-between`} alignItems={`center`}>
                        <IconButton><Logo className='logo' fill="#000" /></IconButton>
                        <IconButton onClick={() => toggleDrawer(false)}>
                            <Close />
                        </IconButton>
                    </Stack>
                    <Box className="menu-items">

                        {menuRoutes.map((route, index) => {

                            var splitPathName = location.pathname.split('/');
                            var parentPathName = splitPathName.slice(0, 2).join('/');

                            if (route.hasOwnProperty('children')) {

                                const isParentActive = route.children.some((routeChild, index) => {
                                    return ((routeChild.path === location.pathname || parentPathName === routeChild.path || route.path === parentPathName) ? `active` : '');
                                });

                                return (
                                    <Fragment key={index}>
                                        <Button onClick={handleClick} startIcon={<Home />} className={isParentActive ? 'active' : ''}>{route.name} </Button>
                                        <Menu anchorEl={anchorEl} open={openMenu} onClose={handleClose}   >
                                            {route.children.map((routeChild, index) => {
                                                return (
                                                    <MenuItem key={index} component={Link} to={routeChild.path} className={(routeChild.path === location.pathname) ? `active` : ((parentPathName === routeChild.path) ? 'active' : '')}>
                                                        {routeChild.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Menu>
                                    </Fragment>
                                );
                            } else {
                                return (
                                    <Fragment key={index}>
                                        {route.hasSeparation && <Divider sx={{ margin: `5px 0` }} />}
                                        <Button component={Link} to={route.path} className={(route.path === location.pathname) ? `active` : ((parentPathName === route.path) ? 'active' : '')} >
                                            {route.name}
                                        </Button>
                                    </Fragment>
                                );
                            }
                        })}
                    </Box>
                </SwipeableDrawerStyled>
            </Box>
        </MenuBarStyled>
    );
}

export { MenuBar };
