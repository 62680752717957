import React from 'react';
import { Box, styled } from '@mui/material';

import topSvg from './images/top-design.svg';
import bottomSvg from './images/bottom-design.svg';

const InitialLoaderStyled = styled(({ ...props }) => <Box {...props} />)`
	background-color: ${(props) => props.theme.palette.background.default};
	background-image: url(${topSvg}), url(${bottomSvg});
	background-position: top right, bottom left;
	background-repeat: no-repeat;
	overflow: hidden;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	z-index: 99999;
	& .initial-loader-box {
		bottom: 0;
		height: ${(props) => `${props.theme.functions.pxToRem(55)}`};
		left: 0;
		margin: auto;
		position: absolute;
		right: 0;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		& img {
			height: ${(props) => `${props.theme.functions.pxToRem(55)}`};
		}
	}
`;

export { InitialLoaderStyled };
