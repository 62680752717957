/**
 * The function validates user profile updates by checking for errors in the name, email, and mobile
 * number fields.
 * @param {String} name - The name of the input field being validated (e.g. "name", "email", "mobile_number").
 * @param {String} value - The value of the input field being validated (e.g. the name, email, or mobile number
 * entered by the user).
 * @param {Object} data - The `data` parameter is an object that contains the current user profile data,
 * including the `name`, `email`, and `mobile_number` properties. This function is used to validate
 * updates to these properties before they are saved to the user's profile.
 * @returns The function `validateProfileUpdate` returns an object `errors` which contains error
 * messages for the input fields `name`, `email`, and `mobile_number`. The error messages are empty
 * strings if there are no errors, and contain a specific error message if there is an error with the
 * input value.
 * @author Akshay N
 * @created_at 21/05/2023
 */
const validateProfileContactUpdate = (name, value, data) => {
    // eslint-disable-next-line no-useless-escape
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    const errors = {};
    switch (name) {
        case 'name': {
            errors.name = '';
            if (value === '')
                errors.name = 'Enter name';
            break;
        } case 'email': {
            errors.email = validEmailRegex.test(value) ? '' : 'Email provided is invalid';
            if (value === '')
                errors.email = 'Enter an email address';
            break;

        } case 'mobile_number': {
            errors.mobile_number = value.length < 10 ? 'Mobile Number must be 10 characters long.' : '';
            if (value === '')
                errors.mobile_number = 'Enter your mobile number.';
            break;
        } default: {
            errors[name] = '';
            break;
        }
    }
    return errors;
};
export default validateProfileContactUpdate;