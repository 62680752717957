import React from 'react';
import { Logo } from '../..';
import { Box } from '@mui/material';
import { InitialLoaderStyled } from '.';

function InitialLoader({ loader }) {

    if (!loader) {
        return '';
    }

    return (
        <InitialLoaderStyled>
            <Box className="initial-loader-box">
                <Logo className="blink-image" />
            </Box>
        </InitialLoaderStyled>
    );
}

export { InitialLoader };
