// eslint-disable-next-line import/no-anonymous-default-export
export default {
    defaultProps: {},
    styleOverrides: {
        root: {},
        weekDayLabel: {},
        weekContainer: {},
        weekNumberLabel: {},
        weekNumber: {},
    },
    variants: []
};
