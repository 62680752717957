import React from 'react';
import { Box, styled } from '@mui/material';

import backgroundImage from './../../../_theme/images/banner-pave-partner-image.png';

const BecomePavePartnerStyled = styled(({ ...props }) => <Box {...props} />)`
	& .section {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		background-image: linear-gradient(rgba(0, 0, 0, 0.3) 80%, rgba(255, 255, 255, 0.3) 90%,rgba(255, 255, 255, 1) 100%) , url(${backgroundImage});

		& .section-content {
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			min-height: ${(props) => `${props.theme.functions.pxToRem(250)}`};
			& .MuiStack-root {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			& .description {
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				font-weight: 550;
				color: ${(props) => props.theme.palette.light.main};
				margin-top: 100px;
				${(props) => props.theme.breakpoints.up('md')} {
					font-size: ${(props) => props.theme.typography.h1.fontSize};
				}
				/* & a {
					margin-left: ${(props) => props.theme.spacing(1)};
					font-size: ${(props) => props.theme.typography.h5.fontSize};
				} */
			}
		}
	}
	& .section-footer {
		padding: ${(props) => props.theme.spacing(2)};
		display: flex;
		align-items: center;
		justify-content: center;
		${(props) => props.theme.breakpoints.up('sm')} {
			min-height: ${(props) => `${props.theme.functions.pxToRem(110)}`};
		}
		& .MuiButton-root {
			min-width: 175px;
			border-radius: ${(props) => props.theme.borders.borderRadius.section};
		}
	}

	& .MuiList-root {
		margin-left: 24px;
		margin-right: 24px;
		& .MuiListItem-root {
			padding: 4px 0;
			color: ${(props) => props.theme.palette.dark.main};
			& .MuiTypography-root {
				color: ${(props) => props.theme.palette.dark.main};
			}
		}
	}

	& .sub-list {
		& .MuiListItem-root {
			padding: 0;
			& .MuiTypography-root {
				font-weight: 550;
			}
		}
	}
	& .MuiContainer-root {
		${(props) => props.theme.breakpoints.up('md')} {
			min-height: calc(100vh - 670px);
		}
	}
`;

export { BecomePavePartnerStyled };
