import React from 'react';
import { Box, styled } from '@mui/material';

const PrivacyAndPolicyStyled = styled(({ ...props }) => <Box {...props} />)`
	margin-top: 56px;
	padding: 30px;
	min-height: 90vh;

	${(props) => props.theme.breakpoints.up('md')} {
		min-height: 90vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	& .MuiCardMedia-root {
		width: 100%;
		margin: 0;
	}

	& .MuiListItemText-root{
		& .MuiTypography-root{
			font-size: 14px;
		}
	}
`;

export { PrivacyAndPolicyStyled };
