import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { handleInputChange, validateForm } from '../../../../../_helpers';
import { preAuthRoutes } from '../../../../../routes';

import { FormDatePicker, FormInput } from '../../../../../_components/form';
import { SubmitButton, CancelButton } from '../../../../../_components/controls';
import { Box, CardMedia, Grid, Stack } from '@mui/material';
import { PreAuth } from '../../../../../_layouts/pre-auth';

import { MenteeAuthAction } from '../../../../../redux/actions';
import { validate } from '.';
import { MenteeRegisterBasicDetailsStyled } from './MenteeRegisterBasicDetailsStyled';
import { Animate, PageHeader } from '../../../../../_components';

import bannerImage from './../../../../../_theme/images/bg-02.png';


// Initialize form input values to null
const inputs = { first_name: '', middle_initial: '', last_name: '', dob: '' };

function MenteeRegisterBasicDetails() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();
    const params = new URLSearchParams(search);


    /* Destructuring the state from redux store. */
    const { register_mentee_basic_details_loading, register_mentee_basic_details_errors } = useSelector((state) => state.MenteeAuthReducer);
    const registerMenteeBasicDetails = (params) => dispatch(MenteeAuthAction.registerMenteeBasicDetails(params));

    /* Initializing the state with the default values. */
    const [data, setData] = useState({ ...inputs, _id: params.get('authToken') });
    const [errors, setErrors] = useState({ ...inputs, remember_me: '' });
    const [action, setAction] = useState({ isSubmitted: false });

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        setErrors({ ...register_mentee_basic_details_errors });
    }, [register_mentee_basic_details_errors]);

    /**
      * It takes the name and value of the input, validates it, and sets the errors and data state
      * @param {Object} e form object
      * @param {String} e.name input name
      * @param {String} e.value input value
      * @return {null}
      * @author Akshay N
      * @created_at 20/05/2023
      */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        /* Validating the input and setting the errors state. */
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
      * The function handles the submit event of the form. It prevents the default action of the form,
      * sets the isSubmitted state to true, and then validates the form. If the form is valid, it
      * dispatches the corresponding action
      * @param {Object} e the event object
      * @return {null}
      * @author Akshay N
      * @created_at 20/05/2023
      */
    function handleSubmit(e) {

        e.preventDefault();
        setAction({ isSubmitted: true });

        /* Iterating through the data object and validating each key value pair. */
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value);
            formErrors[name] = inputError[name];
        }

        /* Checking if the form is valid or not. If the form is not valid, it sets the errors state and returns. */
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchRegisterAction();
    }

    /**
     * function to dispatch registration api
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    async function dispatchRegisterAction() {
        const registered = await registerMenteeBasicDetails(data);
        if (registered && registered.status === 1) {
            /**
            * Checking if the user is redirected from a page, and
            * if so, redirects to that page, else redirects to the sign in page.
            */
            let redirectPath = `${preAuthRoutes('mentee_register_checkout').path}?authToken=${registered.data._id}`;
            navigate(redirectPath);
        }
    }

    const handleBackBtn = () => {
        navigate(preAuthRoutes('mentee_pre_register').path);
    };


    return (
        <PreAuth>
            <MenteeRegisterBasicDetailsStyled>
                <Box className='content'>
                    <PageHeader title={"Sign Up as a Athlete"} />
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} display={`flex`} justifyContent={`center`} alignItems={{ xs: `flex-start`, md: `center` }} >
                            <form onSubmit={handleSubmit} noValidate>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormInput
                                            tabIndex={1}
                                            label='First Name'
                                            name='first_name'
                                            value={data.first_name}
                                            error={action.isSubmitted && errors.first_name ? errors.first_name : ''}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormInput
                                            tabIndex={1}
                                            label='Middle Initial'
                                            name='middle_initial'
                                            value={data.middle_initial}
                                            error={action.isSubmitted && errors.middle_initial ? errors.middle_initial : ''}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormInput
                                            tabIndex={1}
                                            label='Last Name'
                                            name='last_name'
                                            value={data.last_name}
                                            error={action.isSubmitted && errors.last_name ? errors.last_name : ''}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormDatePicker
                                            tabIndex={1}
                                            label='Date of Birth'
                                            name='dob'
                                            value={data.dob}
                                            error={action.isSubmitted && errors.dob ? errors.dob : ''}
                                            onChange={handleChange}
                                            disableFuture={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" alignItems="center" justifyContent="center" >
                                        <Stack spacing={1} direction={'row'} className='w-100'>
                                            <CancelButton fullWidth label={'Back'} color={`light`} loading={register_mentee_basic_details_loading} onClick={handleBackBtn} />
                                            <SubmitButton fullWidth label={'Next'} loading={register_mentee_basic_details_loading} />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={`center`} justifyContent={`center`}>
                            <Animate>
                                <CardMedia component={`img`} src={bannerImage} />
                            </Animate>
                        </Grid>
                    </Grid>
                </Box>
            </MenteeRegisterBasicDetailsStyled>
        </PreAuth>
    );
}

export { MenteeRegisterBasicDetails };
