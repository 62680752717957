import React from 'react'
import { Box, styled } from '@mui/material'

const MenteeBenefitsStyled = styled(({ ...props }) => <Box {...props} />)`
	width: 100%;
	& .heading {
		padding-bottom: ${(props) => props.theme.spacing(1)};
	}
	& .main-stack {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		& .MuiCardMedia-root {
			width: 100%;
			margin: 0;
		}
	}
	& .swiper-button-next {
		display: none;
	}
	& .swiper-button-prev {
		display: none;
	}
	& .SwiperButtons {
		position: absolute;
		top: 0;
		right: 0;
	}
`

export { MenteeBenefitsStyled }
