import React from 'react'
import { Box, styled } from '@mui/material';
import { DRAWER_WIDTH } from '../../_helpers';

const PostAuthStyled = styled(({ ...props }) => (<Box {...props} />))`
    & main {
        padding: ${props => props.theme.spacing(3)};
    }
    & main.drawer-open {
        margin-left: ${DRAWER_WIDTH}px;
    }
`;

export { PostAuthStyled }
