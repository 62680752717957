import { MentorAuthServices } from "../../services";
import { failure, request, success, GlobalAction } from "..";

export const MENTOR_SIGN_IN_REQUEST = 'MENTOR_SIGN_IN_REQUEST';
export const MENTOR_SIGN_IN_SUCCESS = 'MENTOR_SIGN_IN_SUCCESS';
export const MENTOR_SIGN_IN_FAILURE = 'MENTOR_SIGN_IN_FAILURE';

export const MENTOR_REGISTER_AUTH_DETAILS_REQUEST = 'MENTOR_REGISTER_AUTH_DETAILS_REQUEST';
export const MENTOR_REGISTER_AUTH_DETAILS_SUCCESS = 'MENTOR_REGISTER_AUTH_DETAILS_SUCCESS';
export const MENTOR_REGISTER_AUTH_DETAILS_FAILURE = 'MENTOR_REGISTER_AUTH_DETAILS_FAILURE';

export const MENTOR_REGISTER_BASIC_DETAILS_REQUEST = 'MENTOR_REGISTER_BASIC_DETAILS_REQUEST';
export const MENTOR_REGISTER_BASIC_DETAILS_SUCCESS = 'MENTOR_REGISTER_BASIC_DETAILS_SUCCESS';
export const MENTOR_REGISTER_BASIC_DETAILS_FAILURE = 'MENTOR_REGISTER_BASIC_DETAILS_FAILURE';

export const MENTOR_REGISTER_CHECKOUT_REQUEST = 'MENTOR_REGISTER_CHECKOUT_REQUEST';
export const MENTOR_REGISTER_CHECKOUT_SUCCESS = 'MENTOR_REGISTER_CHECKOUT_SUCCESS';
export const MENTOR_REGISTER_CHECKOUT_FAILURE = 'MENTOR_REGISTER_CHECKOUT_FAILURE';

export const MENTOR_SEND_PWD_RST_LINK_REQUEST = 'MENTOR_SEND_PWD_RST_LINK_REQUEST';
export const MENTOR_SEND_PWD_RST_LINK_SUCCESS = 'MENTOR_SEND_PWD_RST_LINK_SUCCESS';
export const MENTOR_SEND_PWD_RST_LINK_FAILURE = 'MENTOR_SEND_PWD_RST_LINK_FAILURE';

export const MENTOR_RESET_PASSWORD_REQUEST = 'MENTOR_RESET_PASSWORD_REQUEST';
export const MENTOR_RESET_PASSWORD_SUCCESS = 'MENTOR_RESET_PASSWORD_SUCCESS';
export const MENTOR_RESET_PASSWORD_FAILURE = 'MENTOR_RESET_PASSWORD_FAILURE';

export const CONTACT_US_REQUEST = 'CONTACT_US_REQUEST';
export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS';
export const CONTACT_US_FAILURE = 'CONTACT_US_FAILURE';

export const CHECK_MENTOR_AUTH_STATUS_REQUEST = 'CHECK_MENTOR_AUTH_STATUS_REQUEST';
export const CHECK_MENTOR_AUTH_STATUS_SUCCESS = 'CHECK_MENTOR_AUTH_STATUS_SUCCESS';
export const CHECK_MENTOR_AUTH_STATUS_FAILURE = 'CHECK_MENTOR_AUTH_STATUS_FAILURE';

export const MentorAuthAction = {

    /**
     * This is an action creator function called `signIn` that dispatches actions to sign in a user.
     * It takes in a `params` object as an argument which contains the email and password of the user.
     * @param {Object} params - The params which are used for registration api.
     * @param {String} params.email - Email of the user
     * @param {String} params.password - Password of the user
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    signInMentor: (params) => {
        return dispatch => {
            dispatch(request(MENTOR_SIGN_IN_REQUEST, params));
            return MentorAuthServices.signInMentor(params)
                .then(
                    response => { return dispatch(success(MENTOR_SIGN_IN_SUCCESS, params, response)); },
                    error => { return dispatch(failure(MENTOR_SIGN_IN_FAILURE, params, error)); }
                );
        };
    },

    /**
     * This is an action creator function called `register` that dispatches actions to register a new admin user.
     * It takes in a `params` object as an argument which contains the name, email, password, and confirm password of the user.
     * @param {Object} params - The params which are used for registration api.
     * @param {String} params.name - Name of the admin
     * @param {String} params.email - Email of the admin
     * @param {String} params.password - Password entered by the admin
     * @param {String} params.confirm_password - Confirm password entered by the admin
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    registerMentorAuthDetails: (params) => {
        return dispatch => {
            dispatch(request(MENTOR_REGISTER_AUTH_DETAILS_REQUEST, params));
            return MentorAuthServices.registerMentorAuthDetails(params)
                .then(
                    response => { return dispatch(success(MENTOR_REGISTER_AUTH_DETAILS_SUCCESS, params, response)); },
                    error => { return dispatch(failure(MENTOR_REGISTER_AUTH_DETAILS_FAILURE, params, error)); }
                );
        };
    },
    /**
     * This is an action creator function called `register` that dispatches actions to register a new admin user.
     * It takes in a `params` object as an argument which contains the name, email, password, and confirm password of the user.
     * @param {Object} params - The params which are used for registration api.
     * @param {String} params.name - Name of the admin
     * @param {String} params.email - Email of the admin
     * @param {String} params.password - Password entered by the admin
     * @param {String} params.confirm_password - Confirm password entered by the admin
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    registerMentorBasicDetails: (params) => {
        return dispatch => {
            dispatch(request(MENTOR_REGISTER_BASIC_DETAILS_REQUEST, params));
            return MentorAuthServices.registerMentorBasicDetails(params)
                .then(
                    response => { return dispatch(success(MENTOR_REGISTER_BASIC_DETAILS_SUCCESS, params, response)); },
                    error => { return dispatch(failure(MENTOR_REGISTER_BASIC_DETAILS_FAILURE, params, error)); }
                );
        };
    },
    /**
     * This is an action creator function called `register` that dispatches actions to register a new admin user.
     * It takes in a `params` object as an argument which contains the name, email, password, and confirm password of the user.
     * @param {Object} params - The params which are used for registration api.
     * @param {String} params.name - Name of the admin
     * @param {String} params.email - Email of the admin
     * @param {String} params.password - Password entered by the admin
     * @param {String} params.confirm_password - Confirm password entered by the admin
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    registerMentorCheckout: (params) => {
        return dispatch => {
            dispatch(request(MENTOR_REGISTER_CHECKOUT_REQUEST, params));
            return MentorAuthServices.registerMentorCheckout(params)
                .then(
                    response => { return dispatch(success(MENTOR_REGISTER_CHECKOUT_SUCCESS, params, response)); },
                    error => { return dispatch(failure(MENTOR_REGISTER_CHECKOUT_FAILURE, params, error)); }
                );
        };
    },


    registerMentorSetupPaymentIntent: (params) => {
        return dispatch => {
            return MentorAuthServices.registerMentorSetupPaymentIntent(params)
                .then(
                    response => { return response; },
                    error => { return error; }
                );
        };
    },

    registerMentorCheckoutPayment: (params) => {
        return dispatch => {
            return MentorAuthServices.registerMentorCheckoutPayment(params)
                .then(
                    response => { return response; },
                    error => { return error; }
                );
        };
    },

    /**
     * This is an action creator function called `sendPasswordResetLink` that dispatches actions to send a password reset link to a user.
     * It takes in a `params` object as an argument which contains the email of the user.
     * @param {Object} params - The params which are used for send password reset link api.
     * @param {String} params.email - Email of the admin user
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    sendPasswordResetLink: (params) => {
        return dispatch => {
            dispatch(request(MENTOR_SEND_PWD_RST_LINK_REQUEST, params));
            return MentorAuthServices.sendPasswordResetLink(params)
                .then(
                    response => { return dispatch(success(MENTOR_SEND_PWD_RST_LINK_SUCCESS, params, response)); },
                    error => { return dispatch(failure(MENTOR_SEND_PWD_RST_LINK_FAILURE, params, error)); }
                );
        };
    },

    /**
     * This is an action creator function called `resetPassword` that dispatches actions to reset the password of a user.
     * It takes in a `params` object as an argument which contains the email and new password of the user.
     * @param {Object} params - The params which are used for send password reset link api.
     * @param {String} params.email - Email of the  user
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    resetPassword: (params) => {
        return dispatch => {
            dispatch(request(MENTOR_RESET_PASSWORD_REQUEST, params));
            return MentorAuthServices.resetPassword(params)
                .then(
                    response => { return dispatch(success(MENTOR_RESET_PASSWORD_SUCCESS, params, response)); },
                    error => { return dispatch(failure(MENTOR_RESET_PASSWORD_FAILURE, params, error)); }
                );
        };
    },
    /**
     * This is an action creator function called `resetPassword` that dispatches actions to reset the password of a user.
     * It takes in a `params` object as an argument which contains the email and new password of the user.
     * @param {Object} params - The params which are used for send password reset link api.
     * @param {String} params.email - Email of the  user
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    contactUs: (params) => {
        return dispatch => {
            dispatch(request(CONTACT_US_REQUEST, params));
            return MentorAuthServices.contactUs(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(CONTACT_US_SUCCESS, params, response)); },
                    error => { return dispatch(failure(CONTACT_US_FAILURE, params, error)); }
                );
        };
    }
};
