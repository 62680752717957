import React, { useEffect, useState } from 'react';
import { FeaturedMentorsStyled } from '.';
import Typography from '@mui/material/Typography';
import { Swiper } from 'swiper/react';
import { SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { Box, Paper, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { MentorAction } from '../../../../../redux/actions';

import defaultMentorImage from './../../../../../_theme/images/default/mentor.jpg';

const FeaturedMentors = () => {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();


    /** Redux actions and state */
    const { featured_mentors } = useSelector((state) => state.MentorReducer);
    const getFeaturedMentorsList = (params) => dispatch(MentorAction.getFeaturedMentorsList(params));

    const [payload, setPayload] = useState({ limit: 100, page: 1 });

    const getNext = async () => {
        const result = await getFeaturedMentorsList(payload);
        if (result.status) {
            setPayload({ ...payload });
        }
    };

    useEffect(() => {
        getNext();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    if (featured_mentors.length === 0)
        return '';

    return (
        <FeaturedMentorsStyled>
            <Typography variant="h5">Featured Mentors</Typography>
            <Swiper slidesPerView={4} spaceBetween={15} navigation loop={true} modules={[Autoplay, Navigation]} autoplay={{ delay: 30000, disableOnInteraction: false }} breakpoints={{ 0: { slidesPerView: 1.1 }, 576: { slidesPerView: 1.7, }, 768: { slidesPerView: 2.2, }, 992: { slidesPerView: 3.2 }, 1200: { slidesPerView: 4.2 }, 1400: { slidesPerView: 4.2 } }}>
                {featured_mentors.map((mentor, i) =>
                    <SwiperSlide key={i}>
                        <Paper elevation={0}>
                            <Stack spacing={0.5} display={`flex`} alignItems={`flex-start`} justifyContent={`center`} className='main-stack'>
                                <Box className='profile-media'>
                                    <Box style={{ background: `url(${mentor.profile_picture_url ?? defaultMentorImage}) center center,  url(${mentor.default_profile_picture_url})` }} />
                                </Box>
                                <Stack direction={`row`} display={`flex`} alignItems={`center`} justifyContent={`space-between`}>
                                    <Typography variant="h5">{mentor.name}</Typography>
                                </Stack>
                                <Typography variant="subtitle1" sx={{ fontStyle: `italic`, fontSize: `16px` }}>Starts at &#36;{mentor.price_starts_at ?? '0'}</Typography>
                                <Typography variant="body2" component="div" style={{ whiteSpace: 'pre-line' }}>{mentor.bio}</Typography>
                            </Stack>
                        </Paper>
                    </SwiperSlide>
                )}
            </Swiper>

        </FeaturedMentorsStyled>
    );
};

export { FeaturedMentors };