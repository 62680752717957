import React from 'react';
import { CardContent, styled } from '@mui/material';

const CardContentStyled = styled(({ ...props }) => (<CardContent {...props} />))`
         padding: ${props => props.theme.spacing(0)};
         padding-top: ${props => props.theme.spacing(3)};
         &:last-child {
              padding: ${props => props.theme.spacing(0)};
              padding-top: ${props => props.theme.spacing(3)};
        }
`;

export { CardContentStyled };