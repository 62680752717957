import React from 'react'
import { Box, styled } from '@mui/material'

const SpotlightStyled = styled(({ ...props }) => <Box {...props} />)`
	& .heading {
		padding-bottom: ${(props) => props.theme.spacing(1)};
	}
	& .main-stack {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		& .MuiCardMedia-root {
			width: 50%;
			margin: 0;
		}
	}

	& .spotlight-media {
		width: 100%;
		overflow: hidden;
		border-radius: ${(props) => props.theme.borders.borderRadius.md};
		& .MuiBox-root {
			padding-top: 100%;
			object-fit: contain;
			background-size: cover !important;
			background-repeat: no-repeat !important;
		}

		${(props) => props.theme.breakpoints.up('md')} {
			/* height: 100px; */
		}
	}
`

export { SpotlightStyled }
