import {
    FEATURED_MENTORS_LIST_REQUEST, FEATURED_MENTORS_LIST_SUCCESS, FEATURED_MENTORS_LIST_FAILURE,
    MENTOR_REFER_ID_REQUEST, MENTOR_REFER_ID_SUCCESS, MENTOR_REFER_ID_FAILURE
} from '../../actions';

const initialState = {
    featured_mentors_loading: false, featured_mentors: [], featured_mentors_errors: {}, featured_mentors_total: 0, featured_mentors_total_pages: 0, featured_mentors_per_page: 10, featured_mentors_page: 1,
    mentor_refer_id_loading: false, mentor_refer_id: null, mentor_refer_id_errors: {}
};

export function MentorReducer(state = initialState, action) {
    switch (action.type) {
        //  begin::Get email logs list redux
        case FEATURED_MENTORS_LIST_REQUEST: {
            return {
                ...state,
                featured_mentors_loading: true,
                featured_mentors_errors: false,
            };
        }
        case FEATURED_MENTORS_LIST_SUCCESS: {
            let newArray = [...state.featured_mentors]; //making a new array
            if (action.data.page === 1) {
                newArray = [];
            }
            action.data.mentors.forEach((payload) => {
                const mentorIndex = newArray.findIndex(mentor => mentor._id === payload._id);
                if (mentorIndex !== -1) {
                    newArray[mentorIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                featured_mentors_loading: false,
                featured_mentors: newArray,
                featured_mentors_errors: {},
                featured_mentors_page: action.data.page,
                featured_mentors_per_page: action.data.per_page,
                featured_mentors_total: action.data.total,
                featured_mentors_total_pages: action.data.total_pages
            };
        }
        case FEATURED_MENTORS_LIST_FAILURE: {
            return {
                ...state,
                featured_mentors_loading: false,
                featured_mentors_errors: action.errors,
            };
        }
        // begin::Get email logs list redux

        //  begin::Get email logs list redux
        case MENTOR_REFER_ID_REQUEST: {
            return {
                ...state,
                mentor_refer_id_loading: true,
                mentor_refer_id_errors: false,
            };
        }
        case MENTOR_REFER_ID_SUCCESS: {
            return {
                ...state,
                mentor_refer_id_loading: false,
                mentor_refer_id: action.data.refer_id,
                mentor_refer_id_errors: {},
            };
        }
        case MENTOR_REFER_ID_FAILURE: {
            return {
                ...state,
                mentor_refer_id_loading: false,
                mentor_refer_id_errors: action.errors,
            };
        }
        // begin::Get email logs list redux

        default:
            return state;
    }

}
