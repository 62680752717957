
import { Fragment, useContext, useEffect, useState } from 'react';
import { cookieServices } from '../../_helpers';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import { Footer, Header, PreAuthStyled } from '.';
import { getPreElementNameByPath, postAuthRoutes, preAuthRoutes } from '../../routes';
import { Helmet } from 'react-helmet-async';
import { AppNameContext } from '../../_contexts';
import { useDispatch } from 'react-redux';
import { GlobalAction } from '../../redux/actions';

const PreAuth = ({ isheaderwhite = "false", havebanner = "false", children }) => {

    /** Initialize plugins and variables */
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const location = useLocation();
    const APP_NAME = useContext(AppNameContext);

    /* Initializing the state with the default values. */
    const [pageTitle, setPageTitle] = useState('Loading');

    useEffect(() => {
        setPageTitle(getPreElementNameByPath(location.pathname));
    }, [location]);

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        dispatch(GlobalAction.initialLoader(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {

        /* This code block is checking if there is an access token stored in the browser's cookies using
        the `cookieServices.get()` method. If an access token is found, it will navigate the user to
        the home page using the `navigate()` method from the `useNavigate()` hook and the
        `postAuthRoutes()` function to get the path for the home page. This is likely used to
        redirect users who are already authenticated to the appropriate page upon visiting the login
        or registration page. */
        if (cookieServices.get('accessToken') && (location.pathname !== `${preAuthRoutes('faq').path}` && location.pathname !== `${preAuthRoutes('contact_us').path}`))
            navigate(`${postAuthRoutes('dashboard').path}`);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate]);


    return (
        <Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${pageTitle}`}</title>
            </Helmet>
            <PreAuthStyled justifyContent="center" alignItems="center" havebanner={havebanner}>
                <Header isheaderwhite={isheaderwhite} />
                <Box className='grid-wrapper'>
                    {children}
                </Box>
                <Footer />
            </PreAuthStyled>
        </Fragment>
    );
};


export { PreAuth };
