import React from 'react'
import { AppBar, styled } from '@mui/material'

const HeaderStyled = styled(({ ...props }) => <AppBar {...props} />)`
	z-index: 1200;
	& .MuiSvgIcon-root {
		color: ${(props) => props.theme.palette.common.white} !important;
	}
	& .MuiChip-root {
		& .MuiAvatar-root {
			background-color: ${(props) => props.theme.palette.common.white} !important;
			& .MuiSvgIcon-root {
				color: ${(props) => props.theme.palette.primary.main} !important;
			}
		}
	}
	&.vertical {
		& .logo {
			${(props) => props.theme.breakpoints.up('sm')} {
				margin-left: ${(props) => `${props.theme.functions.pxToRem(70)}`};
			}
		}
	}
`

export { HeaderStyled }
