import { FormControl, styled } from '@mui/material';

const FormPhoneInputStyled = styled(({ ...props }) => <FormControl {...props} />)`
	& .MuiFormLabel-root {
		left: 65px;
	}

	& .MuiInputLabel-shrink {
		left: 0;
	}

    & .MuiOutlinedInput-input{
        padding-left: 0;
        padding-right: 0;
    }

    & .MuiIconButton-root{
        padding: 0;
    }

    & .MuiTypography-root{
        font-size: 12px;
    }
`;

export { FormPhoneInputStyled };
