import React from 'react';
import { Container, styled } from '@mui/material';

const BecomePavePartnerReferAndEarnFormStyled = styled(({ ...props }) => <Container {...props} />)`
	min-height: 50vh;
	margin-top: 100px;
	${(props) => props.theme.breakpoints.up('md')} {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: calc(100vh - 382px);
	}
	& .MuiList-root {
		margin: 0 0 0 24px;
		& .MuiListItem-root {
			padding: 0;
		}
	}
	& .disabled {
		pointer-events: none;
		opacity: 0.4;
	}
`;

export { BecomePavePartnerReferAndEarnFormStyled };
