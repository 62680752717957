import { failure, request, success } from "..";
import { MenteeAuthServices } from "../../services";

export const MENTEE_SIGN_IN_REQUEST = 'MENTEE_SIGN_IN_REQUEST';
export const MENTEE_SIGN_IN_SUCCESS = 'MENTEE_SIGN_IN_SUCCESS';
export const MENTEE_SIGN_IN_FAILURE = 'MENTEE_SIGN_IN_FAILURE';

export const MENTEE_REGISTER_AUTH_DETAILS_REQUEST = 'MENTEE_REGISTER_AUTH_DETAILS_REQUEST';
export const MENTEE_REGISTER_AUTH_DETAILS_SUCCESS = 'MENTEE_REGISTER_AUTH_DETAILS_SUCCESS';
export const MENTEE_REGISTER_AUTH_DETAILS_FAILURE = 'MENTEE_REGISTER_AUTH_DETAILS_FAILURE';

export const MENTEE_REGISTER_BASIC_DETAILS_REQUEST = 'MENTEE_REGISTER_BASIC_DETAILS_REQUEST';
export const MENTEE_REGISTER_BASIC_DETAILS_SUCCESS = 'MENTEE_REGISTER_BASIC_DETAILS_SUCCESS';
export const MENTEE_REGISTER_BASIC_DETAILS_FAILURE = 'MENTEE_REGISTER_BASIC_DETAILS_FAILURE';

export const MENTEE_REGISTER_CHECKOUT_REQUEST = 'MENTEE_REGISTER_CHECKOUT_REQUEST';
export const MENTEE_REGISTER_CHECKOUT_SUCCESS = 'MENTEE_REGISTER_CHECKOUT_SUCCESS';
export const MENTEE_REGISTER_CHECKOUT_FAILURE = 'MENTEE_REGISTER_CHECKOUT_FAILURE';

export const MENTEE_SEND_PWD_RST_LINK_REQUEST = 'MENTEE_SEND_PWD_RST_LINK_REQUEST';
export const MENTEE_SEND_PWD_RST_LINK_SUCCESS = 'MENTEE_SEND_PWD_RST_LINK_SUCCESS';
export const MENTEE_SEND_PWD_RST_LINK_FAILURE = 'MENTEE_SEND_PWD_RST_LINK_FAILURE';

export const MENTEE_RESET_PASSWORD_REQUEST = 'MENTEE_RESET_PASSWORD_REQUEST';
export const MENTEE_RESET_PASSWORD_SUCCESS = 'MENTEE_RESET_PASSWORD_SUCCESS';
export const MENTEE_RESET_PASSWORD_FAILURE = 'MENTEE_RESET_PASSWORD_FAILURE';

export const MenteeAuthAction = {

    /**
     * This is an action creator function called `signIn` that dispatches actions to sign in a user.
     * It takes in a `params` object as an argument which contains the email and password of the user.
     * @param {Object} params - The params which are used for registration api.
     * @param {String} params.email - Email of the user
     * @param {String} params.password - Password of the user
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    signInMentee: (params) => {
        return dispatch => {
            dispatch(request(MENTEE_SIGN_IN_REQUEST, params));
            return MenteeAuthServices.signInMentee(params)
                .then(
                    response => { return dispatch(success(MENTEE_SIGN_IN_SUCCESS, params, response)); },
                    error => { return dispatch(failure(MENTEE_SIGN_IN_FAILURE, params, error)); }
                );
        };
    },

    /**
     * This is an action creator function called `register` that dispatches actions to register a new admin user.
     * It takes in a `params` object as an argument which contains the name, email, password, and confirm password of the user.
     * @param {Object} params - The params which are used for registration api.
     * @param {String} params.name - Name of the admin
     * @param {String} params.email - Email of the admin
     * @param {String} params.password - Password entered by the admin
     * @param {String} params.confirm_password - Confirm password entered by the admin
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    registerMenteeAuthDetails: (params) => {
        return dispatch => {
            dispatch(request(MENTEE_REGISTER_AUTH_DETAILS_REQUEST, params));
            return MenteeAuthServices.registerMenteeAuthDetails(params)
                .then(
                    response => { return dispatch(success(MENTEE_REGISTER_AUTH_DETAILS_SUCCESS, params, response)); },
                    error => { return dispatch(failure(MENTEE_REGISTER_AUTH_DETAILS_FAILURE, params, error)); }
                );
        };
    },
    /**
     * This is an action creator function called `register` that dispatches actions to register a new admin user.
     * It takes in a `params` object as an argument which contains the name, email, password, and confirm password of the user.
     * @param {Object} params - The params which are used for registration api.
     * @param {String} params.name - Name of the admin
     * @param {String} params.email - Email of the admin
     * @param {String} params.password - Password entered by the admin
     * @param {String} params.confirm_password - Confirm password entered by the admin
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    registerMenteeBasicDetails: (params) => {
        return dispatch => {
            dispatch(request(MENTEE_REGISTER_BASIC_DETAILS_REQUEST, params));
            return MenteeAuthServices.registerMenteeBasicDetails(params)
                .then(
                    response => { return dispatch(success(MENTEE_REGISTER_BASIC_DETAILS_SUCCESS, params, response)); },
                    error => { return dispatch(failure(MENTEE_REGISTER_BASIC_DETAILS_FAILURE, params, error)); }
                );
        };
    },
    /**
     * This is an action creator function called `register` that dispatches actions to register a new admin user.
     * It takes in a `params` object as an argument which contains the name, email, password, and confirm password of the user.
     * @param {Object} params - The params which are used for registration api.
     * @param {String} params.name - Name of the admin
     * @param {String} params.email - Email of the admin
     * @param {String} params.password - Password entered by the admin
     * @param {String} params.confirm_password - Confirm password entered by the admin
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    registerMenteeCheckout: (params) => {
        return dispatch => {
            dispatch(request(MENTEE_REGISTER_CHECKOUT_REQUEST, params));
            return MenteeAuthServices.registerMenteeCheckout(params)
                .then(
                    response => { return dispatch(success(MENTEE_REGISTER_CHECKOUT_SUCCESS, params, response)); },
                    error => { return dispatch(failure(MENTEE_REGISTER_CHECKOUT_FAILURE, params, error)); }
                );
        };
    },

    registerMenteeSetupPaymentIntent: (params) => {
        return dispatch => {
            return MenteeAuthServices.registerMenteeSetupPaymentIntent(params)
                .then(
                    response => { return response; },
                    error => { return error; }
                );
        };
    },

    registerMenteeCheckoutPayment: (params) => {
        return dispatch => {
            return MenteeAuthServices.registerMenteeCheckoutPayment(params)
                .then(
                    response => { return response; },
                    error => { return error; }
                );
        };
    },


    /**
     * This is an action creator function called `sendPasswordResetLink` that dispatches actions to send a password reset link to a user.
     * It takes in a `params` object as an argument which contains the email of the user.
     * @param {Object} params - The params which are used for send password reset link api.
     * @param {String} params.email - Email of the admin user
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    sendPasswordResetLink: (params) => {
        return dispatch => {
            dispatch(request(MENTEE_SEND_PWD_RST_LINK_REQUEST, params));
            return MenteeAuthServices.sendPasswordResetLink(params)
                .then(
                    response => { return dispatch(success(MENTEE_SEND_PWD_RST_LINK_SUCCESS, params, response)); },
                    error => { return dispatch(failure(MENTEE_SEND_PWD_RST_LINK_FAILURE, params, error)); }
                );
        };
    },

    /**
     * This is an action creator function called `resetPassword` that dispatches actions to reset the password of a user.
     * It takes in a `params` object as an argument which contains the email and new password of the user.
     * @param {Object} params - The params which are used for send password reset link api.
     * @param {String} params.email - Email of the  user
     * @return {null}
     * @author Akshay N
     * @created_at 03/04/2023
     */
    resetPassword: (params) => {
        return dispatch => {
            dispatch(request(MENTEE_RESET_PASSWORD_REQUEST, params));
            return MenteeAuthServices.resetPassword(params)
                .then(
                    response => { return dispatch(success(MENTEE_RESET_PASSWORD_SUCCESS, params, response)); },
                    error => { return dispatch(failure(MENTEE_RESET_PASSWORD_FAILURE, params, error)); }
                );
        };
    },
};
