import React, { useEffect, useState } from 'react';
import { CheckoutForm } from './form/CheckoutForm';
import { PreAuth } from '../../../../../_layouts/pre-auth';
import { MentorRegisterCheckoutPaymentStyled } from './MentorRegisterCheckoutPaymentStyled';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useDispatch } from 'react-redux';
import { MentorAuthAction } from '../../../../../redux/actions';
import { useLocation } from 'react-router-dom';
import { PageHeader } from '../../../../../_components';
import { Box } from '@mui/material';
import { STRIPE_PUBLISHABLE_KEY } from '../../../../../_helpers';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const MentorRegisterCheckoutPayment = () => {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    let mentorId = params.get('authToken');

    const [clientSecret, setClientSecret] = useState("");
    const [mentor, setMentor] = useState({});

    const setupIntent = (params) => dispatch(MentorAuthAction.registerMentorSetupPaymentIntent(params));

    useEffect(() => {
        setupIntent({ _id: mentorId }).then((res) => {
            if (res.status) {
                setClientSecret(res.data.client_secret);
                setMentor({
                    _id: res.data.mentor_id,
                    name: res.data.name,
                    phone_number: res.data.phone_number,
                    email: res.data.email,
                    price: res.data.price
                });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const options = {
        clientSecret: clientSecret,
        appearance: { theme: 'stripe' }
    };

    return (
        <PreAuth>
            <React.StrictMode>
                <MentorRegisterCheckoutPaymentStyled>
                    <Box className='content'>
                        <PageHeader title={"Checkout"} style={{ textDecoration: `underline` }} />
                        {clientSecret && (
                            <Elements stripe={stripePromise} options={options} >
                                <CheckoutForm mentor={mentor} />
                            </Elements>
                        )}
                    </Box>
                </MentorRegisterCheckoutPaymentStyled>
            </React.StrictMode>
        </PreAuth>
    );
};

export { MentorRegisterCheckoutPayment };