import React from 'react'
import { Container, styled } from '@mui/material'

const MenteeSignInStyled = styled(({ ...props }) => <Container {...props} />)`
	min-height: 70vh;
	display: flex;
	align-items: center;
	justify-content: center;
	${(props) => props.theme.breakpoints.up('md')} {
		min-height: calc(100vh - 382px);
	}
`

export { MenteeSignInStyled }
