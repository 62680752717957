import pxToRem from "../../functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {

    root: {
      "&.MuiDatePickerDialog": {
        "& .MuiPaper-root": {
          padding: `0 !important`
        },
        "& .MuiDialogContent-root": {
          padding: `0 !important`
        }
      }
    },

    paper: {
      padding: pxToRem(24),
    },
    paperFullScreen: {
      borderRadius: 0,
    },


  },
  variants: []
};
