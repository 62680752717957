import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { handleInputChange, MENTEE_CAPITALIZE, validateForm } from '../../../../../_helpers';
import { preAuthRoutes } from '../../../../../routes';

import { FormInput, FormPassword } from '../../../../../_components/form';
import { SubmitButton, RouteLink, CancelButton } from '../../../../../_components/controls';
import { Box, CardMedia, Grid, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { PreAuth } from '../../../../../_layouts/pre-auth';

import { MentorAuthAction } from '../../../../../redux/actions';
import { MentorRegisterAuthDetailsStyled, validate } from '.';
import { Animate, PageHeader } from '../../../../../_components';
import { FormPhoneInput } from '../../../../../_components/form/form-phone-input';

import bannerImage from './../../../../../_theme/images/bg-01.png';

// Initialize form input values to null
const inputs = { email: '', phone_number: '', password: '', confirm_password: '', reference_id: '' };

function MentorRegisterAuthDetails() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const navigate = useNavigate();

    /* Destructuring the state from redux store. */
    const { register_mentor_auth_details_loading, register_mentor_auth_details_errors } = useSelector((state) => state.MentorAuthReducer);
    const registerMentorAuthDetails = (params) => dispatch(MentorAuthAction.registerMentorAuthDetails(params));

    /* Initializing the state with the default values. */
    const [data, setData] = useState({ ...inputs, reference_id: sessionStorage.getItem('reference_id') ?? '' });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        setErrors({ ...register_mentor_auth_details_errors });
    }, [register_mentor_auth_details_errors]);

    /**
      * It takes the name and value of the input, validates it, and sets the errors and data state
      * @param {Object} e form object
      * @param {String} e.name input name
      * @param {String} e.value input value
      * @return {null}
      * @author Akshay N
      * @created_at 20/05/2023
      */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        /* Validating the input and setting the errors state. */
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
      * The function handles the submit event of the form. It prevents the default action of the form,
      * sets the isSubmitted state to true, and then validates the form. If the form is valid, it
      * dispatches the corresponding action
      * @param {Object} e the event object
      * @return {null}
      * @author Akshay N
      * @created_at 20/05/2023
      */
    function handleSubmit(e) {

        e.preventDefault();
        setAction({ isSubmitted: true });

        /* Iterating through the data object and validating each key value pair. */
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value);
            formErrors[name] = inputError[name];
        }

        /* Checking if the form is valid or not. If the form is not valid, it sets the errors state and returns. */
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchRegisterAction();
    }

    /**
     * function to dispatch registration api
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    async function dispatchRegisterAction() {
        const registered = await registerMentorAuthDetails(data);
        if (registered && registered.status === 1) {
            /**
            * Checking if the user is redirected from a page, and
            * if so, redirects to that page, else redirects to the sign in page.
            */
            let redirectPath = `${preAuthRoutes('mentor_register_basic_details').path}?authToken=${registered.data._id}`;
            navigate(redirectPath);
        }
    }


    const handleBackBtn = () => {
        navigate(preAuthRoutes('mentor_pre_register').path);
    };

    return (
        <PreAuth>
            <MentorRegisterAuthDetailsStyled>
                <Box className='content'>
                    <PageHeader title={"Are YOU a Pave Mentor?"} />
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} display={`flex`} justifyContent={`center`} alignItems={{ xs: `flex-start`, md: `center` }}>
                            <Stack spacing={2}>
                                <Typography variant="body2" >{`Are you a current/alumni collegiate athlete and/or successful business professional and possess:`}</Typography>
                                <List sx={{ listStyleType: 'disc' }}>
                                    <ListItem sx={{ display: 'list-item' }}><ListItemText sx={{ fontStyle: 'italic' }} primaryTypographyProps={{ variant: 'body2' }} primary="A wealth knowledge and proven experience in your category" /> </ListItem>
                                    <ListItem sx={{ display: 'list-item' }}><ListItemText sx={{ fontStyle: 'italic' }} primaryTypographyProps={{ variant: 'body2' }} primary="Proficient communication and motivational skills" /> </ListItem>
                                    <ListItem sx={{ display: 'list-item' }}><ListItemText sx={{ fontStyle: 'italic' }} primaryTypographyProps={{ variant: 'body2' }} primary="Strong time management skills" /> </ListItem>
                                </List>
                                <Animate>
                                    <form onSubmit={handleSubmit} noValidate>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FormInput
                                                    tabIndex={3}
                                                    label='Email'
                                                    name='email'
                                                    value={data.email}
                                                    error={action.isSubmitted && errors.email ? errors.email : ''}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FormPhoneInput
                                                    tabIndex={3}
                                                    label='Phone Number'
                                                    name='phone_number'
                                                    value={data.phone_number}
                                                    error={action.isSubmitted && errors.phone_number ? errors.phone_number : ''}
                                                    onChange={handleChange}
                                                    maskType={`digit-only`}
                                                    inputProps={{ maxLength: 12 }}
                                                    autoComplete={`phone_number`}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FormPassword
                                                    tabIndex={4}
                                                    label='Password'
                                                    name='password'
                                                    value={data.password}
                                                    error={action.isSubmitted && errors.password ? errors.password : ''}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FormPassword
                                                    tabIndex={5}
                                                    label='Confirm password'
                                                    name='confirm_password'
                                                    value={data.confirm_password}
                                                    error={action.isSubmitted && errors.confirm_password ? errors.confirm_password : ''}
                                                    onChange={handleChange}
                                                />
                                                <Typography variant="caption" component={`p`}>Use 6 or more characters with a mix of letters, numbers and symbols</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" alignItems="center" justifyContent="center" >
                                                <Stack spacing={1} direction={'row'} className='w-100'>
                                                    <CancelButton fullWidth label={'Back'} color={`light`} loading={register_mentor_auth_details_loading} onClick={handleBackBtn} />
                                                    <SubmitButton fullWidth label={'Create'} loading={register_mentor_auth_details_loading} />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" alignItems="center" justifyContent="center">
                                                <RouteLink to={preAuthRoutes('mentee_register_auth_details').path} label={<React.Fragment>or&nbsp;<b>{`Join as a ${MENTEE_CAPITALIZE}`}</b></React.Fragment>} />
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Animate>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={`center`} justifyContent={`center`}>
                            <Animate>
                                <CardMedia component={`img`} src={bannerImage} />
                            </Animate>
                        </Grid>
                    </Grid>
                </Box>
            </MentorRegisterAuthDetailsStyled>
        </PreAuth>
    );
}

export { MentorRegisterAuthDetails };
