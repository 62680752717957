import {
    REFERENCE_DETAILS_REQUEST, REFERENCE_DETAILS_SUCCESS, REFERENCE_DETAILS_FAILURE
} from '../actions';

const initialState = {
    reference_details_loading: false, reference_details: null, reference_details_errors: {}
};

export function ReferAndEarnReducer(state = initialState, action) {
    switch (action.type) {

        //  begin::Get email logs list redux
        case REFERENCE_DETAILS_REQUEST: {
            return {
                ...state,
                reference_details_loading: true,
                reference_details_errors: false,
            };
        }
        case REFERENCE_DETAILS_SUCCESS: {
            return {
                ...state,
                reference_details_loading: false,
                reference_details: action.data.refer_token,
                reference_details_errors: {},
            };
        }
        case REFERENCE_DETAILS_FAILURE: {
            return {
                ...state,
                reference_details_loading: false,
                reference_details_errors: action.errors,
            };
        }
        // begin::Get email logs list redux

        default:
            return state;
    }

}
