module.exports = {
    /**
     * function to validate the registration form inputs
     * @param {String} name Input name
     * @param {String} value Input value
     * @param {Object} [data={}] All input values stored in the state
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    validate: (name, value, data = {}) => {
        //eslint-disable-next-line
        const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        const errors = {};
        switch (name) {
            case 'agree_to_terms_and_conditions': {
                errors.agree_to_terms_and_conditions = '';
                if (value === false)
                    errors.agree_to_terms_and_conditions = 'Please check the terms and conditions.';
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
};