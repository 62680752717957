import React from 'react';
import { PreAuth } from '../../../_layouts/pre-auth';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Stack, Typography } from '@mui/material';
import { FaqStyled } from '.';
import { Animate, PageHeader, RouteLink } from '../../../_components';
import { ExpandMore } from '@mui/icons-material';
import Linkify from 'react-linkify';
import { preAuthRoutes } from '../../../routes';
import { MENTEE, MENTEE_CAPITALIZE, MENTEES, MENTEES_CAPITALIZE } from '../../../_helpers';


let forMentors = [
    { title: "How do I become a mentor on Pave?", description: "To become a mentor, you need to register on our platform, complete your profile with relevant experience and qualifications, and indicate your areas of expertise. Set up your offering and start getting paid to mentor!" },
    { title: "Who can be a Pave Mentor?", description: "Ideally, a mentor should be someone who has accomplished something in a specific field, has extensive experience and knowledge of that field, and has a good network to leverage for their " + MENTEES + "." },
    { title: "What qualifications or experience do I need to be a mentor?", description: "We look for mentors who have substantial experience or qualifications in their field. This can include professional experience, achievements, certifications, or demonstrable skills in specific areas." },
    { title: "How do I connect with potential " + MENTEES + "?", description: "Once your profile is set up, " + MENTEES + " can view your profile and reach out to you (and vice versa). You can also browse through " + MENTEE + " requests and offer your mentorship to those you think you can help the most." },
    { title: "Am I compensated for my time as a mentor?", description: "Yes, mentors are compensated. You can set your own rates based on your experience and the nature of the mentorship. Payment terms and methods are detailed in our mentor agreement." },
    { title: "How do I manage my availability and appointments?", description: "With Pave, YOU decide when you want to mentor. There are live chat features that allow you to schedule on-the-fly video calls with your " + MENTEE + ". You can also set expectations for when/how often you will be able to meet and respond to messages prior to getting booked for a mentorship." },
];

let forStudents = [
    { title: "How do I find the right mentors for me?", description: "We auto-match you with similar mentors based on shared interests you select when signing up. You can search for mentors based on specific skills, industry, experience level, or other criteria. Each mentor's profile provides detailed information about their expertise and experience, helping you make an informed choice." },
    { title: "What can I expect from a mentorship?", description: "Mentorship sessions are opportunities for you to learn, ask questions, and get guidance on specific challenges or goals. The format can vary from casual messaging discussions to structured learning and video calls, depending on your needs and the mentor's style. You and your mentor can share audio files/images/videos(that adhere to our guidelines)." },
    { title: "How do I prepare for my first mentorship?", description: "It's helpful to define your goals and challenges before starting a mentorship so that no time is wasted. Prepare specific questions or goals you want help achieving. Also, ensure you're familiar with our platform's tools for communication and session management." },
    {
        title: "Is there a fee to use this service, and how are payments handled?", description: MENTEES_CAPITALIZE + " are required to pay for mentorship sessions, this price is set by the mentors. " + MENTEES_CAPITALIZE + " are charged a small service fee to Pave as well as an annual membership fee of $30. Payments are securely processed through our platform, and details about refunds and cancellations are available in our " + MENTEE_CAPITALIZE + " agreement."
    },
    { title: "What happens if I’m not satisfied with a mentorship session?", description: "We strive to ensure a high-quality experience for all users. If you're not satisfied with a session, please contact our support team to discuss your concerns. We offer a resolution process, which may include refunds or re-matching with a different mentor." },
];


const Faq = () => {

    return (
        <PreAuth>
            <FaqStyled>
                <PageHeader title={`FAQ`} action={<RouteLink to={preAuthRoutes('contact_us').path} label={<Button variant='contained' color='dark' className='for-mentors-btn'>Contact Us</Button>} />} />
                <Grid container spacing={3}>
                    <Grid item xs={12} display={`flex`} justifyContent={`center`} >
                        <Animate animate='right-left'>
                            <Typography variant="h6" mb={1}>For Mentors:</Typography>
                            {
                                forMentors.map((faq, index) => {
                                    return (
                                        <Accordion key={index}>
                                            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header" >
                                                <Typography>{faq.title}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a target="blank" href={decoratedHref} key={key}> {decoratedText} </a>)}>
                                                    <Typography style={{ whiteSpace: 'pre-line' }} variant="body2" paddingTop={2} paddingBottom={1}>{`${faq.description}`}</Typography>
                                                </Linkify>
                                            </AccordionDetails>
                                        </Accordion>
                                    );
                                })
                            }
                        </Animate>
                    </Grid>
                    <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                        <Animate >
                            <Typography variant="h6" mb={1}>For Students</Typography>
                            {
                                forStudents.map((faq, index) => {
                                    return (
                                        <Accordion key={index}>
                                            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header" >
                                                <Typography>{faq.title}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a target="blank" href={decoratedHref} key={key}> {decoratedText} </a>)}>
                                                    <Typography style={{ whiteSpace: 'pre-line' }} variant="body2" paddingTop={2} paddingBottom={1}>{`${faq.description}`}</Typography>
                                                </Linkify>
                                            </AccordionDetails>
                                        </Accordion>
                                    );
                                })
                            }
                        </Animate>
                    </Grid>
                    <Grid item xs={12} display={`flex`} justifyContent={`center`} alignItems={`center`}>
                        <Stack spacing={0.5} display={`flex`} justifyContent={`center`} alignItems={`center`} >
                            <Typography variant="h6">Didn’t answer your question?</Typography>
                            <RouteLink to={preAuthRoutes('contact_us').path} label={<Button variant='contained' color='dark' className='for-mentors-btn'>Contact Us</Button>} />
                        </Stack>
                    </Grid>
                </Grid>
            </FaqStyled>
        </PreAuth>
    );
};

export { Faq };