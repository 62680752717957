import React from 'react';
import { Container, styled } from '@mui/material';

const GiveawaysStyled = styled(({ ...props }) => <Container {...props} />)`
	& .MuiGrid-container {
		min-height: 50vh;
		${(props) => props.theme.breakpoints.up('md')} {
			min-height: calc(100vh - 379px);
		}
		& .MuiList-root {
			margin-left: 24px;
			margin-right: 24px;
			& .MuiListItem-root {
				padding: 4px 0;
			}
		}
	}
`;

export { GiveawaysStyled };
