import React from 'react';
import { MentorBenefitsStyled } from '.';
import Typography from '@mui/material/Typography';
import { Swiper } from 'swiper/react';
import { SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { CardMedia, Stack } from '@mui/material';

import scheduleFlexibilityImage from './../../../../../_theme/images/schedule-flexibility.jpg';
import nameYourPriceImage from './../../../../../_theme/images/name-your-price.jpg';
import tipsAndBenefits from './../../../../../_theme/images/tips-and-benefits.jpg';
import { MENTEES } from '../../../../../_helpers';

let data = [
    {
        image: nameYourPriceImage,
        title: 'Name your Price',
        body: 'You set your own price, and have the power to adjust how much you charge and how many ' + MENTEES + ' you work with'
    },
    {
        image: scheduleFlexibilityImage,
        title: 'Schedule Flexibility',
        body: 'Pave is a remote-based mentorship marketplace that allows you to set your own schedule and mentor from anywhere.'
    },
    {
        image: tipsAndBenefits,
        title: 'Tips and Benefits',
        body: 'Earn tips for extra effort and exceptional mentoring and gain access to exclusive benefits only available to PAVE mentors'
    }
];

const MentorBenefits = () => {
    return (
        <MentorBenefitsStyled>
            <Typography variant="h5" className='heading'>Mentor Perks</Typography>
            <Swiper slidesPerView={3} spaceBetween={30} navigation loop={true} modules={[Autoplay, Navigation]} autoplay={{ delay: 30000, disableOnInteraction: false }} breakpoints={{ 0: { slidesPerView: 1.3, }, 576: { slidesPerView: 1.7, }, 768: { slidesPerView: 2.2, }, 992: { slidesPerView: 3 }, 1200: { slidesPerView: 3 }, 1400: { slidesPerView: 3 } }}>
                {data.map((content, i) =>
                    <SwiperSlide key={i}>
                        <Stack spacing={1} className='main-stack'>
                            <CardMedia component={`img`} src={content.image} />
                            <Typography variant="h6" className='title'>{content.title}</Typography>
                            <Typography variant="body2" className='body'>{content.body}</Typography>
                        </Stack>
                    </SwiperSlide>
                )}
            </Swiper>
        </MentorBenefitsStyled>
    );
};

export { MentorBenefits };