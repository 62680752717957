import { apiServices } from "../../../_helpers";



export const MentorServices = {

    /**
     * `getMentorList` is a function that sends a POST request to the `/mentors/list` endpoint
     * with the `params` object as the request body. It then returns a Promise that resolves with the
     * response from the server. This function is a part of the `getMentorList` object and can be
     * used to retrieve a list of mentors.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    getFeaturedMentorsList: (params) => {
        return apiServices.get(`/mentor/featured/list`, { params })
            .then((response) => { return response; });
    },
    /**
     * `getMentorList` is a function that sends a POST request to the `/mentors/list` endpoint
     * with the `params` object as the request body. It then returns a Promise that resolves with the
     * response from the server. This function is a part of the `getMentorList` object and can be
     * used to retrieve a list of mentors.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 22/05/2023
     */
    generateMentorReferId: (params) => {
        return apiServices.get(`/mentor/details/refer-id`, { params })
            .then((response) => { return response; });
    },
};