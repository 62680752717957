import breakpoints from "./breakpoints";

const { values: { xs, sm, md, lg, xl, xxl }, } = breakpoints;

const XS = `@media (min-width: ${xs}px)`;
const SM = `@media (min-width: ${sm}px)`;
const MD = `@media (min-width: ${md}px)`;
const LG = `@media (min-width: ${lg}px)`;
const XL = `@media (min-width: ${xl}px)`;
const XXL = `@media (min-width: ${xxl}px)`;


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [XS]: {
    ".MuiContainer-root": {
      maxWidth: "100% !important",
    },
  },
  [SM]: {
    ".MuiContainer-root": {
      maxWidth: "540px !important",
    },
  },
  [MD]: {
    ".MuiContainer-root": {
      maxWidth: "720px !important",
    },
  },
  [LG]: {
    ".MuiContainer-root": {
      maxWidth: "960px !important",
    },
  },
  [XL]: {
    ".MuiContainer-root": {
      maxWidth: "1140px !important",
    },
  },
  [XXL]: {
    ".MuiContainer-root": {
      maxWidth: "1140px !important",
    },
  },
};
