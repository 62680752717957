import React from 'react';
import { PreAuth } from '../../../../_layouts/pre-auth';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { Animate, RouteLink } from '../../../../_components';
import { AboutApp, BehindTheNumbers } from '../../_components';
import { preAuthRoutes } from '../../../../routes';
import { MenteeBenefits, Spotlight } from '../_components';
import { MenteeLandingPageStyled } from './MenteeLandingPageStyled';
import { FeaturedMentors } from '../../mentor';

const MenteeLandingPage = () => {

    return (
        <PreAuth isheaderwhite='true' havebanner='true'>
            <MenteeLandingPageStyled>
                <Stack spacing={3}>
                    <Animate animate='right-left'>
                        <Box className='section'>
                            <Box className='section-content'>
                                <Stack spacing={2} className='w-100'>
                                    <Typography variant="h2" className='description'>Learn and Grow. <br />Discover your perfect  <br /> Mentor</Typography>
                                    <Stack spacing={2} display={`flex`} alignItems={`center`} justifyContent={`center`} className='w-100'>
                                        <RouteLink to={preAuthRoutes('mentee_register_auth_details').path} label={<Button variant='contained' color='light' className='for-mentors-btn'>Join For FREE</Button>} />
                                        <RouteLink to={preAuthRoutes('mentee_sign_in').path} label={<React.Fragment>Already have an account? Log in</React.Fragment>} tabIndex={5} />
                                    </Stack>
                                </Stack>
                            </Box>
                        </Box>
                    </Animate>
                    <Container>
                        <Stack spacing={5}>
                            <Animate>
                                <MenteeBenefits />
                            </Animate>
                            <Animate animate='right-left'>
                                <AboutApp />
                            </Animate>
                            <Animate>
                                <BehindTheNumbers />
                            </Animate>
                            <Animate animate='right-left'>
                                <Spotlight />
                            </Animate>
                            <Animate>
                                <Stack spacing={0.5} display={`flex`} alignItems={`center`} justifyContent={`center`} className='w-100'>
                                    <RouteLink to={preAuthRoutes('mentee_register_auth_details').path} label={<Button sx={{ minWidth: `200px`}} variant='contained' color='dark' className='for-mentors-btn'>Join now</Button>} />
                                </Stack>
                            </Animate>
                            <Animate animate='right-left'>
                                <FeaturedMentors />
                            </Animate>
                        </Stack>
                    </Container>
                </Stack>
            </MenteeLandingPageStyled>
        </PreAuth>
    );
};

export { MenteeLandingPage };