import React from "react";
import { Checkbox, FormControlLabel, FormHelperText } from "@mui/material";
import { FormCheckboxStyled } from '.';

const FormCheckbox = ({ name, label, className = '', id, value, onChange, isReadOnly = false, tabIndex, error, info, ...props }) => {

    const handleChange = (e) => { onChange(e); };
    return (
        <FormCheckboxStyled fullWidth error className={className}>
            <FormControlLabel control={<Checkbox checked={value} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} name={name} />} label={label} />
            {(info) && <FormHelperText id={`helper_${name}`} >{info}</FormHelperText>}
            {(error) && <FormHelperText id={`error_${name}`} error>{error}</FormHelperText>}
        </FormCheckboxStyled>
    );
};

export { FormCheckbox };
