import React, { Fragment, useState } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { useDispatch } from "react-redux";
import { MentorAuthAction } from "../../../../../../redux/actions";
import { Alert, Grid, Stack } from "@mui/material";
import { SubmitButton } from "../../../../../../_components";
import { preAuthRoutes } from "../../../../../../routes";
import { useNavigate } from "react-router-dom";

function CheckoutForm({ mentor }) {

    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [alert, setAlert] = useState({ type: 'error', message: '' });
    const [loading, setLoading] = useState(false);
    const [isReady, setIsReady] = useState(false);

    const registerMentorCheckoutPayment = (params) => dispatch(MentorAuthAction.registerMentorCheckoutPayment(params));

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        if (!stripe || !elements)
            return;

        const { setupIntent, error } = await stripe.confirmSetup({
            elements,
            redirect: 'if_required',
            confirmParams: {
                payment_method_data: {
                    billing_details: {
                        name: mentor.name,
                        email: mentor.email,
                        phone: mentor.phone_number
                    }
                }
            }
        });

        if (error) {
            if (error.type !== 'validation_error')
                setAlert({ type: 'error', message: error.message });
            setLoading(false);
        } else {
            let data = {
                setup_intent_id: setupIntent.id,
                mentor_id: mentor._id,
                payment_method: setupIntent.payment_method
            };
            let initialPayment = await registerMentorCheckoutPayment(data);
            if (initialPayment.status_code) {
                if (initialPayment.status === 2 || initialPayment.status === 0) {
                    setLoading(false);
                    setAlert({ type: 'error', message: initialPayment.message });
                } else {
                    setLoading(false);
                    setAlert({ type: 'success', message: initialPayment.message });
                    setTimeout(() => {
                        let redirectPath = `${preAuthRoutes('mentor_register_completed').path}?authToken=${mentor._id}`;
                        navigate(redirectPath);
                    }, 2000);
                }
            }
        }

    };

    const handlePaymentElementReady = () => {
        setIsReady(true);
    };

    return (
        <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`}>
            <Grid item xs={12} md={6}>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                        {alert.message && <Alert severity={alert.type}>{alert.message}</Alert>}
                        {alert.type !== 'success' && stripe && elements &&
                            <Fragment>
                                <PaymentElement options={{ layout: { type: 'accordion', defaultCollapsed: false, radios: false, spacedAccordionItems: false } }} onReady={handlePaymentElementReady} />
                                {isReady && <SubmitButton label={`$${mentor.price} | Pay Now`} fullWidth disabled={!stripe} loading={loading} />}
                            </Fragment>
                        }
                    </Stack>
                </form>
            </Grid>

        </Grid>
    );
}

export { CheckoutForm };