import React from 'react';
import { Container, styled } from '@mui/material';

const MenteeRegisterBasicDetailsStyled = styled(({ ...props }) => <Container {...props} />)`
	min-height: 50vh;
	${(props) => props.theme.breakpoints.up('md')} {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: calc(100vh - 382px);
	}
`;

export { MenteeRegisterBasicDetailsStyled };
