import React from 'react';
import { BehindTheNumbersStyled } from '.';
import Typography from '@mui/material/Typography';
import 'swiper/css';
import 'swiper/css/navigation';
import { CardMedia, Grid, Stack } from '@mui/material';
import { Animate } from '../../../../_components';

import bannerImage from './../../../../_theme/images/banner-behind-numbers-image.png';

let data = [
    {
        title: 'Goal setting',
        body: 'Over 80% of Gen Z want a mentor to help them set goals and stick to them'
    },
    {
        title: '1 to 1 Mentors',
        body: 'Nearly 75% of Gen Z want to be taught in 1 to 1 settings'
    },
    {
        title: 'Learning from experience',
        body: 'Over 80% of Gen Z want to learn skills from experienced mentors in their position'
    },
    {
        title: 'Breeding success',
        body: 'Students with mentors are 130% more likely to hold leadership positions'
    }
];

const BehindTheNumbers = () => {
    return (
        <BehindTheNumbersStyled>
            <Typography variant="h5" className='heading'>Behind the numbers</Typography>
            <Grid container spacing={3} className='main-stack'>
                <Grid item xs={12} md={6} display={`flex`} justifyContent={`left`} alignItems={`center`}>
                    <Stack spacing={3} display={`flex`} alignItems={`flex-start`} justifyContent={`center`}>
                        {data.map((content, i) =>
                            <Stack key={i} spacing={0.5} display={`flex`} alignItems={`flex-start`} justifyContent={`center`} className='main-stack'>
                                <Typography variant="h6">{content.title}</Typography>
                                <Typography variant="body2">{content.body}</Typography>
                            </Stack>
                        )}
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6} display={`flex`} alignItems={`center`} justifyContent={`center`} sx={{ display: `none` }}>
                    <Animate>
                        <CardMedia component={`img`} src={bannerImage} />
                    </Animate>
                </Grid>
            </Grid>
        </BehindTheNumbersStyled>
    );
};

export { BehindTheNumbers };