import React from 'react';
import { Button } from '@mui/material';

function CancelButton({ label = 'Cancel', color ='secondary', className, tabIndex, loading = false, disabled = false, onClick, ...props }) {
    return (
        <Button className={className} type="button" variant="contained" color={color} disabled={loading || disabled} tabIndex={tabIndex} onClick={() => onClick()} {...props} > {label} </Button>
    );
}

export { CancelButton };
