import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated, config } from 'react-spring';

const Animate = ({ animate = 'left-right', children }) => {
    const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 });

    let direction;
    switch (animate) {
        case 'right-left':
            direction = [-3, 0];
            break;
        case 'left-right':
            direction = [3, 0];
            break;
        case 'top-bottom':
            direction = [0, -3];
            break;
        case 'bottom-top':
            direction = [0, 3];
            break;
        default:
            direction = [3, 0];
    }

    const props = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? 'translate(0%, 0%)' : `translate(${direction[0]}%, ${direction[1]}%)`,
        config: config.molasses,
        width: `100%`
    });

    return <animated.div ref={ref} style={props}>{children}</animated.div>;
};

export { Animate };