import dayCalendar from "../../../../_root/components/form/date-and-time-pickers/dayCalendar";
import colors from "../../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    ...dayCalendar,
    styleOverrides: {
        ...dayCalendar.styleOverrides,
        weekDayLabel: {
            ...dayCalendar.styleOverrides.weekDayLabel,
            color: colors.text.main,
        },
        weekContainer: {
            ...dayCalendar.styleOverrides.weekContainer,
            color: colors.text.main,
        },
        weekNumberLabel: {},
        weekNumber: {},
    },
};
