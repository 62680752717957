import pxToRem from "../../functions/pxToRem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    defaultProps: {},
    styleOverrides: {
        root: {
            paddingRight: `${pxToRem(24)} !important`,
            paddingLeft: `${pxToRem(24)} !important`,
            marginRight: "auto !important",
            marginLeft: "auto !important",
            position: "relative",
        }
    },
    variants: []
};
