import React from 'react'
import { styled, Card } from '@mui/material';

const ProfileContactUpdateStyled = styled(({ ...props }) => (<Card {...props} />))`

    & .MuiCardContent-root {
        min-height: ${props => `${props.theme.functions.pxToRem(400)}`};
        display : flex;
        align-items:center;
        justify-content:center;
        & .right-grid {
            align-items:center;
            justify-content:center;
        }
    }

`;

export { ProfileContactUpdateStyled }