import React from 'react';
import { PreAuth } from '../../../_layouts/pre-auth';
import { PaidCollegeInternshipsStyled } from './PaidCollegeInternshipsStyled';
import { Box, Button, Container, Grid, List, ListItem, Stack, Typography } from '@mui/material';
import { Animate, RouteLink } from '../../../_components';
import { preAuthRoutes } from '../../../routes';
import { MENTEE_CAPITALIZE } from '../../../_helpers';

const PaidCollegeInternships = () => {
    return (
        <PreAuth isheaderwhite='true' havebanner='true'>
            <PaidCollegeInternshipsStyled>
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        <Animate animate='right-left'>
                            <Box className='section'>
                                <Box className='section-content'>
                                    <Stack spacing={6} className='w-100'>
                                        <Typography variant="h2" className='description'>Paid College <br /> Internships</Typography>
                                    </Stack>
                                </Box>
                            </Box>
                            <Box className='section-footer'>
                                <RouteLink to={`${preAuthRoutes('paid_college_intern_refer_and_earn').path}`} label={<Button variant='contained' color='dark' >Apply Now</Button>} />
                            </Box>
                        </Animate>
                    </Grid>
                    <Grid item xs={12} >
                        <Container>
                            <Grid container spacing={3}>
                                <Grid item xs={12} >
                                    <Animate>
                                        <Box>
                                            <List sx={{ listStyleType: 'disc' }}>
                                                <ListItem sx={{ display: 'list-item' }}>
                                                    <Typography variant="body2" className='list-item'> Paid College Internships Refer classmates, teammates and friends and earn commissions: </Typography>
                                                    <List sx={{ listStyleType: 'disc' }} className='sub-list'>
                                                        <ListItem sx={{ display: 'list-item' }}><Typography variant="h6" className='list-item'>$25 per Mentor</Typography></ListItem>
                                                        <ListItem sx={{ display: 'list-item' }}><Typography variant="h6" className='list-item'>{`$10 per ${MENTEE_CAPITALIZE}`}</Typography></ListItem>
                                                        <ListItem sx={{ display: 'list-item' }}><Typography variant="h6" className='list-item'>Bonuses for milestones</Typography></ListItem>
                                                    </List>
                                                </ListItem>
                                                <ListItem sx={{ display: 'list-item' }}> <Typography variant="body2" className='list-item'>Gain valuable experience in marketing, sales, and mentorship</Typography> </ListItem>
                                                <ListItem sx={{ display: 'list-item' }}> <Typography variant="body2" className='list-item'>Work on your own time, fully remote from anywhere</Typography> </ListItem>
                                                <ListItem sx={{ display: 'list-item' }}> <Typography variant="body2" className='list-item'>Post on Instagram & TikTok about @Join.Pave</Typography> </ListItem>
                                                <ListItem sx={{ display: 'list-item' }}> <Typography variant="body2" className='list-item'></Typography>Get free personal access to Pave</ListItem>
                                            </List>
                                        </Box>
                                    </Animate>
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                </Grid>
            </PaidCollegeInternshipsStyled>
        </PreAuth>
    );
};

export { PaidCollegeInternships };