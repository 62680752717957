import React from 'react';
import { MenteeBenefitsStyled } from '.';
import Typography from '@mui/material/Typography';
import { Swiper } from 'swiper/react';
import { SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { CardMedia, Stack } from '@mui/material';

import guidanceAndSupport from './../../../../../_theme/images/guidance-and-support.jpg';
import networkIntroductions from './../../../../../_theme/images/network-introductions.jpg';
import personalGrowth from './../../../../../_theme/images/personal-growth.jpg';
import { MENTEE_CAPITALIZE } from '../../../../../_helpers';

let data = [
    {
        image: guidanceAndSupport,
        title: 'Guidance & Support',
        body: `Mentors with accomplishments in the sport/industry you're striving for can offer key experiential advice and share feedback of their experiences.`
    },
    {
        image: networkIntroductions,
        title: 'Network Introductions',
        body: 'Mentors can introduce you to coaches, managers, and other decision makers that can help put you on the right path to reach your goal.'
    },
    {
        image: personalGrowth,
        title: 'Personal Growth',
        body: 'Mentors can help instill traits such as discipline, resilience, and humility that will position you for success at the next level'
    }
];

const MenteeBenefits = () => {
    return (
        <MenteeBenefitsStyled>
            <Typography variant="h5" className='heading'>{`${MENTEE_CAPITALIZE} Benefits`}</Typography>
            <Swiper slidesPerView={3} spaceBetween={30} navigation loop={true} modules={[Autoplay, Navigation]} autoplay={{ delay: 30000, disableOnInteraction: false }} breakpoints={{ 0: { slidesPerView: 1.5, }, 576: { slidesPerView: 1.7, }, 768: { slidesPerView: 2.2, }, 992: { slidesPerView: 3 }, 1200: { slidesPerView: 3 }, 1400: { slidesPerView: 3 } }}>
                {data.map((content, i) =>
                    <SwiperSlide key={i}>
                        <Stack spacing={1} className='main-stack'>
                            <CardMedia component={`img`} src={content.image} />
                            <Typography variant="h6" className='title'>{content.title}</Typography>
                            <Typography variant="body2" className='body'>{content.body}</Typography>
                        </Stack>
                    </SwiperSlide>
                )}
            </Swiper>

        </MenteeBenefitsStyled>
    );
};

export { MenteeBenefits };