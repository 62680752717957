import React from 'react';
import { PreAuth } from '../../../../../_layouts/pre-auth';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { MentorRegisterCompletedStyled } from './';
import { preAuthRoutes } from '../../../../../routes';
import { RouteLink } from '../../../../../_components';
import { IOS_APP_URL } from '../../../../../_helpers';

const MentorRegisterCompleted = () => {
    return (
        <PreAuth>
            <MentorRegisterCompletedStyled>
                <Box className='content'>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} display={`flex`} justifyContent={`center`} alignItems={`center`}>
                            <Stack spacing={6}>
                                <Typography variant="h5" textAlign={`center`} >Profile Created!</Typography>
                                <Stack spacing={4}>
                                    <Typography variant="body2" textAlign={`center`} >You are one step closer to paving the way to achieving your goals!</Typography>
                                    <RouteLink sx={{ width: `100%` }} to={IOS_APP_URL} label={<Button variant='contained' color='primary' className='btn-download' fullWidth>Download the Pave iOS App</Button>} target="_blank" />
                                    <RouteLink sx={{ width: `100%` }} to={preAuthRoutes('become_pave_partner').path} label={<Button variant='contained' color='primary' fullWidth className='become-a-pave-partner'>{preAuthRoutes('become_pave_partner').name}</Button>} />
                                    <Typography variant="body2" textAlign={`center`} >or</Typography>
                                    <RouteLink style={{ width: `100%` }} to={preAuthRoutes('mentor_sign_in').path} label={<Button variant='outlined' fullWidth color='dark'>Sign into your online account</Button>} />
                                </Stack>
                            </Stack>

                        </Grid>
                    </Grid>
                </Box>
            </MentorRegisterCompletedStyled>
        </PreAuth>

    );
};

export { MentorRegisterCompleted };