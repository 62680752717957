import pickersDay from "../../../../_root/components/form/date-and-time-pickers/pickersDay";
import colors from "../../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    ...pickersDay,
    styleOverrides: {
        ...pickersDay.styleOverrides,
        root: {
            ...pickersDay.styleOverrides.root,
            color: colors.text.main
        },
    }
};
