import { failure, request, success } from ".";
import { ReferAndEarnServices } from "../services";

export const REFERENCE_DETAILS_REQUEST = 'REFERENCE_DETAILS_REQUEST';
export const REFERENCE_DETAILS_SUCCESS = 'REFERENCE_DETAILS_SUCCESS';
export const REFERENCE_DETAILS_FAILURE = 'REFERENCE_DETAILS_FAILURE';

export const ReferAndEarnAction = {

    getReferenceDetails: (params) => {
        return dispatch => {
            dispatch(request(REFERENCE_DETAILS_REQUEST, params));
            return ReferAndEarnServices.getReferenceDetails(params)
                .then(
                    response => { return dispatch(success(REFERENCE_DETAILS_SUCCESS, params, response)); },
                    error => { return dispatch(failure(REFERENCE_DETAILS_FAILURE, params, error)); }
                );
        };
    }

};