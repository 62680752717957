import React from 'react'
import { Outlet, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom'
import { Box, Grid } from '@mui/material'
import { MenuList, MenuItem, ListItemIcon, ListItemText, Alert, Button } from '@mui/material'
import { postAuthMenuRoutes } from '../../../../routes'
import { ProfileStyled } from '.';
import { useDispatch } from 'react-redux';
import { GlobalAction, ProfileAction } from '../../../../redux/actions';

function Profile() {

    /** Initialize plugins and variables */
    const location = useLocation();
    const dispatch = useDispatch();
    const menuRoutes = Object.values(postAuthMenuRoutes('profile'));

    /* Destructuring the state from redux store. */
    const updateLayout = (params) => dispatch(ProfileAction.updateLayout(params));

    const toggleLayout = () => {
        dispatch(GlobalAction.setThemeLayout());
        updateLayout();
    }

    return (
        <ProfileStyled>
            <Grid container spacing={3}>
                {/* <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Box>
                        <MenuList >
                            {menuRoutes.map((route, index) => (
                                <MenuItem key={index} component={Link} to={route.path} className={(route.path === location.pathname) ? `active` : ``}>
                                    <ListItemIcon>
                                        <route.menuIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>{route.name}</ListItemText>
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Box>
                </Grid> */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Grid container spacing={3}>
                        {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Alert>Do you want to toggle the UI layout? <Button variant='text' onClick={toggleLayout} >Click Here! </Button></Alert>
                        </Grid> */}
                        <Grid item xs={12}profile_update_contact_info sm={12} md={12} lg={12} xl={12}>
                            <Outlet />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ProfileStyled>
    )
}


export { Profile };
