
import React, { useEffect, useState } from 'react';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { FormPhoneInputStyled } from './FormPhoneInputStyled';
import { FormHelperText } from '@mui/material';


function FormPhoneInput({ label, name, value, placeholder, className, onChange, tabIndex, id, error, formOpen, info, ...props }) {


    const [telValue, setTelValue] = useState(value ?? '');

    useEffect(() => {
        const isValid = matchIsValidTel(value);
        if (isValid)
            setTelValue(value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);


    const [isFocused, setIsFocused] = React.useState(false);

    const handleChange = (newValue) => {
        const isValid = matchIsValidTel(newValue);
        setTelValue(newValue);
        if (!isValid) {
            newValue = '';
        }
        onChange({ target: { type: 'tel', name: name, value: newValue } });
    };


    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };



    return (
        <FormPhoneInputStyled fullWidth>
            <MuiTelInput
                value={telValue}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                label={label}
                InputLabelProps={{ shrink: isFocused || Boolean(telValue) }}
                defaultCountry='US'
                forceCallingCode
                inputMode="tel"
                MenuProps={{
                    PaperProps: {
                        sx: {
                            height: `300px`,
                            marginTop: `8px`,
                            padding: 0,
                            '& .MuiMenuItem-root': {
                                width: `100%`,
                                '& .MuiTypography-root': {
                                    color: `#000`
                                }
                            },
                        },
                    },
                }}
            />

            {(info) && <FormHelperText id={`helper_${name}`}>{info}</FormHelperText>}
            {(error) && <FormHelperText id={`error_${name}`} error>{error}</FormHelperText>}
        </FormPhoneInputStyled>
    );
}

export { FormPhoneInput };
