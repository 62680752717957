import colors from "../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    defaultProps: {
        size: "small",
        underline: "none",
    },
    styleOverrides: {

        colorPrimary: {
            color: colors.common.white
        },
        colorSecondary: {
            color: colors.common.white
        },
        colorSuccess: {
            color: colors.common.white
        },
        colorWarning: {
            color: colors.common.white
        },
        colorInfo: {
            color: colors.common.white
        },
        colorError: {
            color: colors.common.white
        },

    },
    variants: []
};
