import { legacy_createStore as createStore, applyMiddleware, combineReducers } from 'redux';
import { thunk } from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { GlobalReducer, ProfileReducer, EmailLogReducer, MentorAuthReducer, MentorReducer, MenteeAuthReducer, ReferAndEarnReducer } from './reducers';

/* Creating a logger middleware that will only log if the environment is not production. */
const loggerMiddleware = createLogger({ predicate: () => process.env.NODE_ENV !== 'production' });

/* Combining all the reducers into one reducer. */
const rootReducer = combineReducers({ GlobalReducer, ProfileReducer, EmailLogReducer, MentorAuthReducer, MentorReducer, MenteeAuthReducer, ReferAndEarnReducer });

/* Creating a store that will hold the state of the application. */
export const store = createStore(rootReducer, applyMiddleware(thunk, loggerMiddleware));