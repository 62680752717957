import React from 'react';
import { Container, styled } from '@mui/material';

const MenteeRegisterCheckoutStyled = styled(({ ...props }) => <Container {...props} />)`
	min-height: 50vh;
	${(props) => props.theme.breakpoints.up('md')} {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: calc(100vh - 382px);
	}
	& .MuiList-root {
		margin: 0 0 0 24px;
		& .MuiListItem-root {
			padding: 0;
		}
	}

	& .section-2-content {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: ${(props) => `${props.theme.spacing(30 / 8)} ${props.theme.spacing(20 / 8)}`};
		& .per-month-price-caption {
			font-size: 18px;
			color: ${(props) => props.theme.palette.text.main};
		}
	}

	& .MuiSlider-root {
		padding: 5px 0;
	}
	& .MuiNumberInput-input {
		border: none;
		width: 2rem !important;
		padding: 0;
	}
`;

export { MenteeRegisterCheckoutStyled };
