import React from 'react';
import { PreAuth } from '../../../_layouts/pre-auth';
import { AboutUsStyled } from '.';
import Typography from '@mui/material/Typography';
import { CardMedia, Grid, Stack } from '@mui/material';
import { Animate, PageHeader } from '../../../_components';
import { MenteeBenefits } from '../mentee';
import { MentorBenefits } from '../mentor';

import bannerImage from './../../../_theme/images/bg-04.png';
import { MENTEE, MENTEES } from '../../../_helpers';


const AboutUs = () => {
    return (
        <PreAuth>
            <AboutUsStyled>
                <PageHeader title={"About Us | Why Pave?"} />
                <Grid container spacing={3}>
                    <Grid item xs={12} display={`flex`} justifyContent={`center`} sx={{ alignItems: { xs: `flex-start`, md: `center` } }}>
                        <Stack spacing={0.5} >
                            <Animate animate='right-left'>
                                <Typography variant="body2">All people, whether they are teenagers or young adults need mentors who have experienced and accomplished the exact goals that they too are striving to reach</Typography>
                            </Animate>
                            <Animate>
                                <Typography variant="body2">There are millions people who have accomplished their goals that possess extensive experiential advice that is extremely valuable.</Typography>
                            </Animate>
                            <Animate animate='right-left'>
                                <Typography variant="body2">{`This relationship allows the ${MENTEE} to relate to, trust, and connect with the mentors on a deeper, more relatable level.`}</Typography>
                            </Animate>
                            <Animate>
                                <Typography variant="body2">{`Pave cultivates these relationships by connecting ${MENTEES} and mentors with shared interests who can trade payment for experiential advice resulting in a win-win for both sides.`}</Typography>
                            </Animate>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} display={`flex`} justifyContent={`center`} sx={`center`}>
                        <MentorBenefits />
                    </Grid>
                    <Grid item xs={12} display={`flex`} justifyContent={`center`} sx={`center`}>
                        <MenteeBenefits />
                    </Grid>
                    <Grid item xs={12} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                        <Animate>
                            <CardMedia component={`img`} src={bannerImage} />
                        </Animate>
                    </Grid>
                </Grid>
            </AboutUsStyled>
        </PreAuth>
    );
};

export { AboutUs };