import React from 'react';
import { FormQuantityInputStyled } from '.';
import { Add, Remove } from '@mui/icons-material';
import { Unstable_NumberInput as BaseNumberInput } from '@mui/base/Unstable_NumberInput';

function FormQuantityInput({ name, label, placeholder, className, id, value, onChange, isReadOnly = false, tabIndex, error, info, max = 10, ...props }) {
	const handleChange = (e, val) => {
		e.preventDefault();
		const data = [];
		data.type = 'quantity';
		data.name = name;
		data.value = val;
		onChange({ target: data });
	};

	return (
		<FormQuantityInputStyled>
			<BaseNumberInput
				slotProps={{
					incrementButton: {
						children: <Add fontSize="small" />,
						className: 'increment'
					},
					decrementButton: {
						children: <Remove fontSize="small" />,
						className: 'decrement'
					},
					root: {
						className: 'MuiNumberInput-root'
					},
					input: {
						className: 'MuiNumberInput-input'
					}
				}}
				name={name}
				value={value}
				onChange={handleChange}
				error={error ? true : false}
				min={1}
				max={max}
				readOnly={isReadOnly}
				{...props}
			/>
		</FormQuantityInputStyled>
	);
}

export { FormQuantityInput };
