import pickersMonth from "../../../../_root/components/form/date-and-time-pickers/pickersMonth";
import colors from "../../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    ...pickersMonth,
    styleOverrides: {
        ...pickersMonth.styleOverrides,
        root: {
            ...pickersMonth.styleOverrides.root,
            color: `${colors.light.main} !important`,
            backgroundColor: `${colors.dark.main} !important`,
        }
    },
};
