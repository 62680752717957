export const APP_NAME = process.env.REACT_APP_APP_NAME;

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const API_REQUEST_TIMEOUT = 10000;
export const APP_ENDPOINT = process.env.REACT_APP_APP_ENDPOINT;

export const URL_PRIVACY_POLICY = 'https://confyans.com';
export const URL_TERMS_OF_SERVICE = 'https://confyans.com';

export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;


export const DRAWER_WIDTH = 260;


export const IOS_APP_URL = "https://apps.apple.com/in/app/pave/id6476833385";

export const MENTEE = "athlete";
export const MENTEE_CAPITALIZE = "Athlete";
export const MENTEES = "athletes";
export const MENTEES_CAPITALIZE = "Athletes";