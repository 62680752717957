import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from 'react-redux';

import { APP_NAME, handleInputChange, validateForm } from '../../../../_helpers';
import { PreAuth } from '../../../../_layouts/pre-auth';
import { preAuthRoutes } from '../../../../routes';

import { FormPassword } from '../../../../_components/form';
import { SubmitButton } from '../../../../_components/controls';
import { RouteLink } from '../../../../_components/controls/route-link';
import { Grid, Typography } from '@mui/material';

import { validate } from '.'
import { MentorAuthAction } from '../../../../redux/actions';
import { SweetAlert } from '../../../../_components';

// Initialize form input values to null
const inputs = { password: '', confirm_password: '', email: '', forgot_password_token: '' };

function ResetPassword({ ...props }) {

    /** Initialize plugins and variables */
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const email = params.get('email'); // email from url
    const forgotPasswordToken = params.get('token'); // token from url
    const navigate = useNavigate();
    const dispatch = useDispatch();

    /* Destructuring the state from redux store. */
    const { reset_mentor_password_loading: isLoading, reset_mentor_password_errors: resetPasswordErrors } = useSelector((state) => state.MentorAuthReducer);
    const resetPassword = (params) => dispatch(MentorAuthAction.resetPassword(params));

    /* Initializing the state with the default values. */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        setData({ ...data, 'forgot_password_token': forgotPasswordToken, 'email': email });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, forgotPasswordToken])

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        setErrors({ ...resetPasswordErrors });
    }, [resetPasswordErrors])

    /**
     * It takes the name and value of the input, validates it, and sets the errors and data state
     * @param {Object} e form object
     * @param {String} e.name input name
     * @param {String} e.value input value
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        /* Validating the input and setting the errors state. */
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * The function handles the submit event of the form. It prevents the default action of the form,
     * sets the isSubmitted state to true, and then validates the form. If the form is valid, it
     * dispatches the corresponding action
     * @param {Object} e the event object
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    function handleSubmit(e) {

        e.preventDefault();
        setAction({ isSubmitted: true });

        /* Iterating through the data object and validating each key value pair. */
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value);
            formErrors[name] = inputError[name];
        }

        /* Checking if the form is valid or not. If the form is not valid, it sets the errors state and returns. */
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchResetPasswordAction();
    }

    /**
     * function to dispatch send password reset link
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    async function dispatchResetPasswordAction() {
        const isPasswordChanged = await resetPassword(data);
        if (isPasswordChanged.status === 1) {
            SweetAlert.fire({
                title: 'Password Changed',
                text: "Password changed successfully",
                icon: 'success',
                confirmButtonText: 'Ok!',
                reverseButtons: true,
                showCancelButton: false,
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    let redirectPath = preAuthRoutes('landing_page').path;
                    navigate(redirectPath);
                }
            })
        }
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${preAuthRoutes('reset_mentor_password').name}`}</title>
            </Helmet>
            <PreAuth>
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" alignItems="center" justifyContent="center">
                            <Typography variant="h5">{`${preAuthRoutes('reset_mentor_password').name}`}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormPassword
                                tabIndex={1}
                                label='Password'
                                name='password'
                                value={data.password}
                                error={action.isSubmitted && errors.password ? errors.password : ''}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormPassword
                                tabIndex={2}
                                label='Re-enter password'
                                name='confirm_password'
                                value={data.confirm_password}
                                error={action.isSubmitted && errors.confirm_password ? errors.confirm_password : ''}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" alignItems="center" justifyContent="space-between">
                            <RouteLink to={preAuthRoutes('landing_page').path} label={preAuthRoutes('landing_page').name} />
                            <SubmitButton label={'Change Password'} loading={isLoading} />
                        </Grid>
                    </Grid>
                </form>
            </PreAuth>
        </React.Fragment>
    );
}

export { ResetPassword };
