import React from 'react'
import { Container, styled } from '@mui/material'

const MentorRegisterCompletedStyled = styled(({ ...props }) => <Container {...props} />)`
	/* margin-top: 56px;
	padding: 30px;
	min-height: 90vh;
	display: flex;
	align-items: center;
	justify-content: center;

	${(props) => props.theme.breakpoints.up('md')} {
		min-height: 90vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	& .btn-download {
		background-color: #48B1FF;
	} */

	min-height: 70vh;
	display: flex;
	align-items: center;
	justify-content: center;
	${(props) => props.theme.breakpoints.up('md')} {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: calc(100vh - 382px);
	}
	& .btn-download {
		background-color: #48b1ff;
		&:hover{
			background-color: #48b1ff;
		}
	}
`

export { MentorRegisterCompletedStyled }
