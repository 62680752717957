import React from 'react';
import { PreAuth } from '../../../_layouts/pre-auth';
import { Container, Grid, List, ListItemButton, ListItemText, Stack, Typography, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper, ListItem } from '@mui/material';
import { PrivacyAndPolicyStyled } from './PrivacyAndPolicyStyled';

const PrivacyAndPolicy = () => {
    return (
        <PreAuth>
            <Container>
                <PrivacyAndPolicyStyled>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Stack spacing={0.5}>
                                <Typography variant="h4">PRIVACY POLICY</Typography>
                                <Typography variant="caption">Last updated February 06, 2024</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1}>
                                <Typography variant="body2">This privacy notice for Pave Mentors LLC (<b>"we," "us,"</b> or <b>"our"</b>), describes how and why we might collect, store, use, and/or share (<b>"process"</b>) your information when you use our services (<b>"Services"</b>), such as when you:</Typography>
                                <List sx={{ listStyle: 'disc', pl: 4 }}>
                                    <ListItem sx={{ display: 'list-item', pt: 0, pb: 0 }}><ListItemText primary="Visit our website at https://app.joinpave.io, or any website of ours that links to this privacy notice" /></ListItem>
                                    <ListItem sx={{ display: 'list-item', pt: 0, pb: 0 }}><ListItemText primary="Download and use our mobile application (Pave), or any other application of ours that links to this privacy notice" /></ListItem>
                                    <ListItem sx={{ display: 'list-item', pt: 0, pb: 0 }}><ListItemText primary="Engage with us in other related ways, including any sales, marketing, or events" /></ListItem>
                                </List>
                                <Typography variant="body2"><b>Questions or concerns?</b> Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at info@joinpave.io.</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1}>
                                <Typography variant="h6">SUMMARY OF KEY POINTS</Typography>
                                <Typography variant="body2"><b>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for.</b></Typography>
                                <Typography variant="body2"><b>What personal information do we process?</b> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about personal information you disclose to us.</Typography>
                                <Typography variant="body2"><b>Do we process any sensitive personal information?</b> We do not process sensitive personal information.</Typography>
                                <Typography variant="body2"><b>Do we receive any information from third parties?</b> We do not receive any information from third parties.</Typography>
                                <Typography variant="body2"><b>How do we process your information?</b> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about how we process your information.</Typography>
                                <Typography variant="body2"><b>In what situations and with which parties do we share personal information?</b> We may share information in specific situations and with specific third parties. Learn more about when and with whom we share your personal information.</Typography>
                                <Typography variant="body2"><b>How do we keep your information safe?</b> We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about how we keep your information safe.</Typography>
                                <Typography variant="body2"><b>What are your rights?</b> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about your privacy rights.</Typography>
                                <Typography variant="body2"><b>How do you exercise your rights?</b> The easiest way to exercise your rights is by visiting https://app.joinpave.io/contact-us, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws</Typography>
                                <Typography variant="body2">Want to learn more about what we do with any information we collect? Review the privacy notice in full.</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1}>
                                <Typography variant="h6">TABLE OF CONTENTS</Typography>
                                <List sx={{ listStyle: 'decimal', pl: 4 }}>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} href="#content1" to="/design"> <ListItemText primary="WHAT INFORMATION DO WE COLLECT?" /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} href="#content2" to="/design"> <ListItemText primary="HOW DO WE PROCESS YOUR INFORMATION?" /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} href="#content3" to="/design"> <ListItemText primary="WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?" /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} href="#content4" to="/design"> <ListItemText primary="HOW LONG DO WE KEEP YOUR INFORMATION?" /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} href="#content5" to="/design"> <ListItemText primary="HOW DO WE KEEP YOUR INFORMATION SAFE?" /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} href="#content6" to="/design"> <ListItemText primary="WHAT ARE YOUR PRIVACY RIGHTS?" /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} href="#content7" to="/design"> <ListItemText primary="CONTROLS FOR DO-NOT-TRACK FEATURES" /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} href="#content8" to="/design"> <ListItemText primary="DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?" /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} href="#content9" to="/design"> <ListItemText primary="DO WE MAKE UPDATES TO THIS NOTICE?" /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} href="#content10" to="/design"> <ListItemText primary="HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} href="#content11" to="/design"> <ListItemText primary="HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?" /> </ListItemButton>
                                </List>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} id="content1">
                            <Stack spacing={1}>
                                <Typography variant="h6">1. WHAT INFORMATION DO WE COLLECT?</Typography>
                                <Typography variant="body2"><b>Personal information you disclose to us</b></Typography>
                                <Typography variant="body2"><b>In Short:</b> We collect personal information that you provide to us.</Typography>
                                <Typography variant="body2">We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</Typography>
                                <Typography variant="body2"><b>Personal Information Provided by You.</b> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</Typography>
                                <Stack spacing={1}>
                                    <List sx={{ listStyle: 'disc', pl: 4 }}>
                                        <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`names`} /> </ListItemButton>
                                        <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`phone numbers`} /> </ListItemButton>
                                        <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`email addresses`} /> </ListItemButton>
                                        <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`job titles`} /> </ListItemButton>
                                        <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`usernames`} /> </ListItemButton>
                                        <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`passwords`} /> </ListItemButton>
                                        <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`contact preferences`} /> </ListItemButton>
                                        <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`contact or authentication data`} /> </ListItemButton>
                                        <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`billing addresses`} /> </ListItemButton>
                                        <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`debit/credit card numbers`} /> </ListItemButton>
                                    </List>
                                </Stack>
                                <Typography variant="body2"><b>Sensitive Information.</b> We do not process sensitive information.</Typography>
                                <Typography variant="body2"><b>Application Data.</b> If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:</Typography>
                                <Stack spacing={1}>
                                    <List sx={{ listStyle: 'disc', pl: 4 }}>
                                        <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Mobile Device Access. We may request access or permission to certain features from your mobile device, including your mobile device's camera, microphone, and other features. If you wish to change our access or permissions, you may do so in your device's settings.`} /> </ListItemButton>
                                        <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Push Notifications. We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings.`} /> </ListItemButton>
                                    </List>
                                </Stack>
                                <Typography variant="body2">This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.</Typography>
                                <Typography variant="body2">All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} id="content2">
                            <Stack spacing={1}>
                                <Typography variant="h6">2. HOW DO WE PROCESS YOUR INFORMATION?</Typography>
                                <Typography variant="body2"><b>In Short:</b> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</Typography>
                                <Typography variant="body2"><b>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</b></Typography>

                                <Stack spacing={1}>
                                    <List sx={{ listStyle: 'disc', pl: 4 }}>
                                        <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={<><b>To facilitate account creation and authentication and otherwise manage user accounts.</b> We may process your information so you can create and log in to your account, as well as keep your account in working order.</>} /> </ListItemButton>
                                        <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={<><b>To deliver and facilitate delivery of services to the user.</b> We may process your information to provide you with the requested service.</>} /> </ListItemButton>
                                        <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={<><b>To respond to user inquiries/offer support to users.</b> We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</>} /> </ListItemButton>
                                        <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={<><b>To enable user-to-user communications.</b> We may process your information if you choose to use any of our offerings that allow for communication with another user.</>} /> </ListItemButton>
                                        <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={<><b>To request feedback.</b> We may process your information when necessary to request feedback and to contact you about your use of our Services.</>} /> </ListItemButton>
                                        <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={<><b>To send you marketing and promotional communications.</b> We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see "WHAT ARE YOUR PRIVACY RIGHTS?" below.</>} /> </ListItemButton>
                                        <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={<><b>To post testimonials.</b> We post testimonials on our Services that may contain personal information.</>} /> </ListItemButton>
                                        <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={<><b>To protect our Services.</b> We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.</>} /> </ListItemButton>
                                        <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={<><b>To administer prize draws and competitions.</b> We may process your information to administer prize draws and competitions.</>} /> </ListItemButton>
                                        <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={<><b>To evaluate and improve our Services, products, marketing, and your experience.</b> We may process your information when we believe it is necessary to identify usage trends, determine the effectiveness of our promotional campaigns, and to evaluate and improve our Services, products, marketing, and your experience.</>} /> </ListItemButton>
                                        <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={<><b>To identify usage trends.</b> We may process information about how you use our Services to better understand how they are being used so we can improve them.</>} /> </ListItemButton>
                                        <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={<><b>To comply with our legal obligations.</b> We may process your information to comply with our legal obligations, respond to legal requests, and exercise, establish, or defend our legal rights.</>} /> </ListItemButton>
                                    </List>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} id="content3">
                            <Stack spacing={1}>
                                <Typography variant="h6">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</Typography>
                                <Typography variant="body2"><b>In Short:</b> We may share information in specific situations described in this section and/or with the following third parties.</Typography>
                                <Typography variant="body2">We may need to share your personal information in the following situations:</Typography>
                                <Stack spacing={1}>
                                    <List sx={{ listStyle: 'disc', pl: 4 }}>
                                        <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={<><b>Business Transfers.</b> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</>} /> </ListItemButton>
                                    </List>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} id="content4">
                            <Stack spacing={1}>
                                <Typography variant="h6">4. HOW LONG DO WE KEEP YOUR INFORMATION?</Typography>
                                <Typography variant="body2"><b>In Short:</b> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</Typography>
                                <Typography variant="body2">We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.</Typography>
                                <Typography variant="body2">When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} id="content5">
                            <Stack spacing={1}>
                                <Typography variant="h6">5. HOW DO WE KEEP YOUR INFORMATION SAFE?</Typography>
                                <Typography variant="body2"><b>In Short:</b> We aim to protect your personal information through a system of organizational and technical security measures.</Typography>
                                <Typography variant="body2">We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} id="content6">
                            <Stack spacing={1}>
                                <Typography variant="h6">6. WHAT ARE YOUR PRIVACY RIGHTS?</Typography>
                                <Typography variant="body2"><b>In Short:</b> You may review, change, or terminate your account at any time.</Typography>
                                <Typography variant="body2"><b>Withdrawing your consent:</b> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.</Typography>
                                <Typography variant="body2">However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</Typography>
                                <Typography variant="body2"><b>Opting out of marketing and promotional communications:</b> You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, replying "STOP" or "UNSUBSCRIBE" to the SMS messages that we send, or by contacting us using the details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</Typography>
                                <Typography variant="h6">Account Information</Typography>
                                <Typography variant="body2">If you would at any time like to review or change the information in your account or terminate your account, you can:</Typography>
                                <Stack spacing={1}>
                                    <List sx={{ listStyle: 'disc', pl: 4 }}>
                                        <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Contact us using the contact information provided.`} /> </ListItemButton>
                                        <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Log in to your account settings and update your user account.`} /> </ListItemButton>
                                    </List>
                                </Stack>
                                <Typography variant="body2">Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud,troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</Typography>
                                <Typography variant="body2">If you have questions or comments about your privacy rights, you may email us at info@joinpave.io.</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} id="content7">
                            <Stack spacing={1}>
                                <Typography variant="h6">7. CONTROLS FOR DO-NOT-TRACK FEATURES</Typography>
                                <Typography variant="body2">Most web browsers and some mobile operating systems and mobile applications include a Do-Not- Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} id="content8">
                            <Stack spacing={1}>
                                <Typography variant="h6">8. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</Typography>
                                <Typography variant="body2"><b>In Short:</b> If you are a resident of California, Colorado, Connecticut, Utah or Virginia, you are granted specific rights regarding access to your personal information.</Typography>
                                <Typography variant="body2"><b>What categories of personal information do we collect?</b></Typography>
                                <Typography variant="body2">We have collected the following categories of personal information in the past twelve (12) months:</Typography>

                                <TableContainer component={Paper}>
                                    <Table sx={{ '& th, & td': { color: 'black' } }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Category</TableCell>
                                                <TableCell>Examples</TableCell>
                                                <TableCell>Collected</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key={1}>
                                                <TableCell>A. Identifiers</TableCell>
                                                <TableCell>Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</TableCell>
                                                <TableCell>NO</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>B. Personal information as defined in the California Customer Records statute</TableCell>
                                                <TableCell>Name, contact information, education, employment, employment history, and financial information</TableCell>
                                                <TableCell>NO</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>C. Protected classification characteristics under state or federal law</TableCell>
                                                <TableCell>Gender and date of birth</TableCell>
                                                <TableCell>NO</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>D. Commercial information</TableCell>
                                                <TableCell>Transaction information, purchase history, financial details, and payment information</TableCell>
                                                <TableCell>NO</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>E. Biometric information</TableCell>
                                                <TableCell>Fingerprints and voiceprints</TableCell>
                                                <TableCell>NO</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>F. Internet or other similar network activity</TableCell>
                                                <TableCell>Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements</TableCell>
                                                <TableCell>NO</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>G. Geolocation data</TableCell>
                                                <TableCell>Device location</TableCell>
                                                <TableCell>NO</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>H. Audio, electronic, visual, thermal, olfactory, or similar information</TableCell>
                                                <TableCell>Images and audio, video or call recordings created in connection with our business activities</TableCell>
                                                <TableCell>NO</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>I. Professional or employmentrelated information</TableCell>
                                                <TableCell>Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</TableCell>
                                                <TableCell>NO</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>J. Education Information</TableCell>
                                                <TableCell>Student records and directory information</TableCell>
                                                <TableCell>NO</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>K. Inferences drawn from collected personal information</TableCell>
                                                <TableCell>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics</TableCell>
                                                <TableCell>YES</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>L. Sensitive personal Information</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>NO</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <Typography variant="body2">We will use and retain the collected personal information as needed to provide the Services or for:</Typography>
                                <List sx={{ listStyle: 'disc', pl: 4 }}>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Category B - As long as the user has an account with us`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Category K - As long as the user has an account with us`} /> </ListItemButton>
                                </List>
                            </Stack>
                            <Stack spacing={1}>
                                <Typography variant="body2">We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:</Typography>
                                <List sx={{ listStyle: 'disc', pl: 4 }}>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Receiving help through our customer support channels;`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Participation in customer surveys or contests; and`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Facilitation in the delivery of our Services and to respond to your inquiries.`} /> </ListItemButton>
                                </List>
                                <Typography variant="body2"><b>How do we use and share your personal information?</b></Typography>
                                <Typography variant="body2">Learn about how we use your personal information in the section, "HOW DO WE PROCESS YOUR INFORMATION?"</Typography>
                                <Typography variant="body2"><b>Will your information be shared with anyone else?</b></Typography>
                                <Typography variant="body2">We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Learn more about how we disclose personal information to in the section, "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"</Typography>
                                <Typography variant="body2">We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal information.</Typography>
                                <Typography variant="body2">We have not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. We will not sell or share personal information in the future belonging to website visitors, users, and other consumers.</Typography>
                                <Typography variant="h6">California Residents</Typography>
                                <Typography variant="body2">California Civil Code Section 1798.83, also known as the "Shine The Light" law permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</Typography>
                                <Typography variant="body2">If you are under 18 years of age, reside in California, and have a registered account with the Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).</Typography>
                                <Typography variant="body2"><b>CCPA Privacy Notice</b></Typography>
                                <Typography variant="body2">This section applies only to California residents. Under the California Consumer Privacy Act (CCPA), you have the rights listed below.</Typography>
                                <Typography variant="body2">The California Code of Regulations defines a "residents" as:</Typography>
                                <List sx={{ listStyle: 'decimal', pl: 4 }}>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`every individual who is in the State of California for other than a temporary or transitory purpose and`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose`} /> </ListItemButton>
                                </List>
                                <Typography variant="body2">All other individuals are defined as "non-residents."</Typography>
                                <Typography variant="body2">If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your personal information.</Typography>
                                <Typography variant="body2"><b>Your rights with respect to your personal data</b></Typography>
                                <Typography variant="body2"><b>Right to request deletion of the data — Request to delete</b></Typography>
                                <Typography variant="body2">You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.</Typography>
                                <Typography variant="body2"><b>Right to be informed — Request to know</b></Typography>
                                <Typography variant="body2">Depending on the circumstances, you have a right to know:</Typography>
                                <List sx={{ listStyle: 'disc', pl: 4 }}>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`whether we collect and use your personal information;`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`the categories of personal information that we collect;`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`the purposes for which the collected personal information is used;`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`whether we sell or share personal information to third parties;`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`the categories of personal information that we sold, shared, or disclosed for a business purpose;`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`the categories of third parties to whom the personal information was sold, shared, or disclosed for a business purpose;`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`the business or commercial purpose for collecting, selling, or sharing personal information; and`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`the specific pieces of personal information we collected about you.`} /> </ListItemButton>
                                </List>
                                <Typography variant="body2">In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.</Typography>
                                <Typography variant="subtitle1"><b>Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights</b></Typography>
                                <Typography variant="body2">We will not discriminate against you if you exercise your privacy rights.</Typography>
                                <Typography variant="subtitle1"><b>Right to Limit Use and Disclosure of Sensitive Personal Information</b></Typography>
                                <Typography variant="body2">We do not process consumer's sensitive personal information.</Typography>
                                <Typography variant="subtitle1"><b>Verification process</b></Typography>
                                <Typography variant="body2">Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g., phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.</Typography>
                                <Typography variant="body2">We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.</Typography>
                                <Typography variant="subtitle1"><b>Other privacy rights</b></Typography>
                                <List sx={{ listStyle: 'disc', pl: 4 }}>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`You may object to the processing of your personal information.`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`You can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`You may request to opt out from future selling or sharing of your personal information to third parties. Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no later than fifteen (15) days from the date of the request submission.`} /> </ListItemButton>
                                </List>
                                <Typography variant="body2">To exercise these rights, you can contact us by visiting https://app.joinpave.io/contact-us, by email at info@joinpave.io, by visiting https://app.joinpave.io/contact-us, or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.</Typography>
                                <Typography variant="h6">Colorado Residents</Typography>
                                <Typography variant="body2">This section applies only to Colorado residents. Under the Colorado Privacy Act (CPA), you have the rights listed below. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law.</Typography>
                                <List sx={{ listStyle: 'disc', pl: 4 }}>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Right to be informed whether or not we are processing your personal data`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Right to access your personal data`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Right to correct inaccuracies in your personal data`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Right to request deletion of your personal data`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Right to obtain a copy of the personal data you previously shared with us`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")`} /> </ListItemButton>
                                </List>
                                <Typography variant="body2">To submit a request to exercise these rights described above, please email info@joinpave.io or visit https://app.joinpave.io/contact-us.</Typography>
                                <Typography variant="body2">If we decline to take action regarding your request and you wish to appeal our decision, please email us at info@joinpave.io. Within forty-five (45) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions.</Typography>
                                <Typography variant="h6">Connecticut Residents</Typography>
                                <Typography variant="body2">This section applies only to Connecticut residents. Under the Connecticut Data Privacy Act (CTDPA), you have the rights listed below. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law.</Typography>
                                <List sx={{ listStyle: 'disc', pl: 4 }}>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Right to be informed whether or not we are processing your personal data`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Right to access your personal data`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Right to correct inaccuracies in your personal data`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Right to request deletion of your personal data`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Right to obtain a copy of the personal data you previously shared with us`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")`} /> </ListItemButton>
                                </List>
                                <Typography variant="body2">To submit a request to exercise these rights described above, please email info@joinpave.io or visit https://app.joinpave.io/contact-us.</Typography>
                                <Typography variant="body2">If we decline to take action regarding your request and you wish to appeal our decision, please email us at info@joinpave.io. Within sixty (60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions.</Typography>
                                <Typography variant="h6">Utah Residents</Typography>
                                <Typography variant="body2">This section applies only to Utah residents. Under the Utah Consumer Privacy Act (UCPA), you have the rights listed below. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law.</Typography>
                                <List sx={{ listStyle: 'disc', pl: 4 }}>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Right to be informed whether or not we are processing your personal data`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Right to access your personal data`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Right to request deletion of your personal data`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Right to obtain a copy of the personal data you previously shared with us`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Right to opt out of the processing of your personal data if it is used for targeted advertising or the sale of personal data`} /> </ListItemButton>
                                </List>
                                <Typography variant="body2">To submit a request to exercise these rights described above, please email info@joinpave.io or visit https://app.joinpave.io/contact-us.</Typography>
                                <Typography variant="h6">Virginia Residents</Typography>
                                <Typography variant="body2">Under the Virginia Consumer Data Protection Act (VCDPA):</Typography>
                                <Typography variant="body2">"Consumer" means a natural person who is a resident of the Commonwealth acting only in an individual or household context. It does not include a natural person acting in a commercial or employment context.</Typography>
                                <Typography variant="body2">"Personal data" means any information that is linked or reasonably linkable to an identified or identifiable natural person. "Personal data" does not include de-identified data or publicly available information.</Typography>
                                <Typography variant="body2">"Sale of personal data" means the exchange of personal data for monetary consideration.</Typography>
                                <Typography variant="body2">If this definition of "consumer" applies to you, we must adhere to certain rights and obligations regarding your personal data.</Typography>
                                <Typography variant="subtitle1"><b>Your rights with respect to your personal data</b></Typography>
                                <List sx={{ listStyle: 'disc', pl: 4 }}>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Right to be informed whether or not we are processing your personal data`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Right to access your personal data`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Right to correct inaccuracies in your personal data`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Right to request deletion of your personal data`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Right to obtain a copy of the personal data you previously shared with us`} /> </ListItemButton>
                                    <ListItemButton sx={{ display: 'list-item' }} component={`a`} to="/design"> <ListItemText primary={`Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")`} /> </ListItemButton>
                                </List>
                                <Typography variant="subtitle1"><b>Exercise your rights provided under the Virginia VCDPA</b></Typography>
                                <Typography variant="body2">You may contact us by email at info@joinpave.io or visit https://app.joinpave.io/contact-us.</Typography>
                                <Typography variant="body2">If you are using an authorized agent to exercise your rights, we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.</Typography>
                                <Typography variant="subtitle1"><b>Verification process</b></Typography>
                                <Typography variant="body2">We may request that you provide additional information reasonably necessary to verify you and your consumer's request. If you submit the request through an authorized agent, we may need to collect additional information to verify your identity before processing your request.</Typography>
                                <Typography variant="body2">Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five (45) days of receipt. The response period may be extended once by forty-five (45) additional days when reasonably necessary. We will inform you of any such extension within the initial 45-day response period, together with the reason for the extension.</Typography>
                                <Typography variant="subtitle1"><b>Right to appeal</b></Typography>
                                <Typography variant="body2">If we decline to take action regarding your request, we will inform you of our decision and reasoning behind it. If you wish to appeal our decision, please email us at info@joinpave.io. Within sixty (60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is denied, you may contact the Attorney General to submit a complaint.</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} id="content9">
                            <Stack spacing={1}>
                                <Typography variant="h6">9. DO WE MAKE UPDATES TO THIS NOTICE?</Typography>
                                <Typography variant="body2"><b>In Short:</b> Yes, we will update this notice as necessary to stay compliant with relevant laws.</Typography>
                                <Typography variant="body2">We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} id="content10">
                            <Stack spacing={1}>
                                <Typography variant="h6">10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</Typography>
                                <Typography variant="body2">If you have questions or comments about this notice, you may email us at info@joinpave.io or contact us by post at:</Typography>
                                <Typography variant="body2">Pave Mentors LLC 2810 N Church St #251201 Wilmington, DE 19802 United States</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} id="content11">
                            <Stack spacing={1}>
                                <Typography variant="h6">11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</Typography>
                                <Typography variant="body2">You have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please visit: https://app.joinpave.io/contact-us.</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </PrivacyAndPolicyStyled>
            </Container>
        </PreAuth>
    );
};

export { PrivacyAndPolicy };
