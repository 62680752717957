import React, { useEffect, useState } from 'react';
import { PreAuth } from '../../../../../_layouts/pre-auth';
import { Box, CardMedia, Grid, List, ListItem, ListItemText, Slider, Stack, Typography } from '@mui/material';
import { MentorRegisterCheckoutStyled, validate } from '.';
import { Animate, CancelButton, FormCheckbox, FormQuantityInput, PageHeader, RouteLink, SubmitButton } from '../../../../../_components';
import { useLocation, useNavigate } from 'react-router-dom';
import { preAuthRoutes } from '../../../../../routes';
import { handleInputChange, MENTEES, MENTEES_CAPITALIZE, validateForm } from '../../../../../_helpers';
import { useDispatch, useSelector } from 'react-redux';
import { MentorAuthAction } from '../../../../../redux/actions';

import bannerImage from './../../../../../_theme/images/bg-02.png';

const inputs = { number_of_mentees: 1, per_month_price: 200, agree_to_terms_and_conditions: false };

const MentorRegisterCheckout = () => {


    /** Initialize plugins and variables */
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { search } = useLocation();
    const params = new URLSearchParams(search);

    /* Destructuring the state from redux store. */
    const { register_mentor_checkout_loading, register_mentor_checkout_errors } = useSelector((state) => state.MentorAuthReducer);
    const registerMentorCheckout = (params) => dispatch(MentorAuthAction.registerMentorCheckout(params));

    /* Initializing the state with the default values. */
    const [data, setData] = useState({ ...inputs, _id: params.get('authToken') });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });

    const [price, setPrice] = useState(inputs.number_of_mentees * inputs.per_month_price);


    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        setErrors({ ...register_mentor_checkout_errors });
    }, [register_mentor_checkout_errors]);


    const handleChange = (e) => {
        const { name, value } = handleInputChange(e);

        /* Validating the input and setting the errors state. */
        setErrors({ ...errors, ...(validate(name, value)) });
        data[name] = value;
        setData({ ...data });
        setTotalPrice();
    };

    const setTotalPrice = () => {
        setPrice(data.number_of_mentees * data.per_month_price);
    };


    /**
      * The function handles the submit event of the form. It prevents the default action of the form,
      * sets the isSubmitted state to true, and then validates the form. If the form is valid, it
      * dispatches the corresponding action
      * @param {Object} e the event object
      * @return {null}
      * @author Akshay N
      * @created_at 20/05/2023
      */
    function handleSubmit(e) {

        e.preventDefault();
        setAction({ isSubmitted: true });

        /* Iterating through the data object and validating each key value pair. */
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value);
            formErrors[name] = inputError[name];
        }

        /* Checking if the form is valid or not. If the form is not valid, it sets the errors state and returns. */
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchRegisterAction();
    }

    /**
     * function to dispatch registration api
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    async function dispatchRegisterAction() {
        const registered = await registerMentorCheckout(data);
        if (registered && registered.status === 1) {
            /**
            * Checking if the user is redirected from a page, and
            * if so, redirects to that page, else redirects to the sign in page.
            */
            let redirectPath = `${preAuthRoutes('mentor_checkout_payment').path}?authToken=${registered.data._id}`;
            navigate(redirectPath);
        }
    }

    const handleBackBtn = () => {
        navigate(preAuthRoutes('mentor_register_basic_details').path);
    };

    return (
        <PreAuth>
            <MentorRegisterCheckoutStyled>
                <Box className='content'>
                    <PageHeader title={"Checkout"} style={{ textDecoration: `underline` }} />
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} display={`flex`} justifyContent={`center`} sx={{ alignItems: { xs: `flex-start`, md: `center` } }}>
                            <form onSubmit={handleSubmit} noValidate>
                                <Stack spacing={2}>
                                    <Stack spacing={1}>
                                        <Typography variant="h6"><span style={{ textDecorationLine: `line-through` }} >$249.99 Profile Creation Fee</span> <span style={{ color: `#56D19A`, fontWeight: `600` }}>FREE</span> <span style={{ fontSize: `12px`, fontWeight: `500` }} >Then $50/yr </span></Typography>
                                        <Typography variant="h6"><span style={{ textDecorationLine: `line-through` }} >7.5% Transaction Fee</span> <span style={{ color: `#56D19A`, fontWeight: `600` }}>FREE</span> <span style={{ fontSize: `12px`, fontWeight: `500` }} > For first 3 months</span></Typography>
                                        <Typography variant="body2" style={{ fontSize: `20px`, fontStyle: `italic` }}>NO MONTHLY FEES!</Typography>
                                    </Stack>
                                    <Stack>
                                        <Typography variant="body2">What You Get:</Typography>
                                        <List sx={{ listStyleType: 'disc' }}>
                                            <ListItem sx={{ display: 'list-item' }}><ListItemText primary={`Private access and exposure to ${MENTEES} on the platform`} /> </ListItem>
                                            <ListItem sx={{ display: 'list-item' }}><ListItemText primary="Auto-matching algorithm for high-converting mentorship fits" /> </ListItem>
                                            <ListItem sx={{ display: 'list-item' }}><ListItemText primary="Mentorship tools with continuous innovation" /> </ListItem>
                                            <ListItem sx={{ display: 'list-item' }}><ListItemText primary="Safe, secure platform " /> </ListItem>
                                        </List>
                                    </Stack>
                                    <Box className='section-2-content'>
                                        <Stack spacing={2}>
                                            <Box>
                                                <Typography color={`primary`} variant="h3" textAlign={`center`} sx={{ fontWeight: 600 }}>Pave their way.</Typography>
                                                <Typography variant="h3" textAlign={`center`} sx={{ fontWeight: 600 }}>You could earn</Typography>
                                            </Box>
                                            <Typography variant="h1" color={`primary`} textAlign={`center`}><span style={{ fontWeight: 700 }}>${price} </span> <span className='per-month-price-caption'>Per Month</span></Typography>
                                            <Stack spacing={1} direction={`row`} display={`flex`} alignItems={`center`} justifyContent={`space-between`}>
                                                <Typography variant="h6" textAlign={`center`} sx={{ fontSize: `12px` }}>{`# of ${MENTEES_CAPITALIZE} you work with`}</Typography>
                                                <FormQuantityInput name="number_of_mentees" value={data.number_of_mentees} onChange={handleChange} isReadOnly={true} />
                                            </Stack>
                                            <Stack spacing={1}>
                                                <Typography variant="body2" textAlign={`center`} >Monthly Mentorship Price: ${data.per_month_price}</Typography>
                                                <Slider name="per_month_price" value={data.per_month_price} step={10} min={10} max={10 * 200} onChange={handleChange} />
                                            </Stack>
                                        </Stack>
                                    </Box>
                                    <Stack spacing={1} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                                        <FormCheckbox
                                            name={'agree_to_terms_and_conditions'}
                                            onChange={handleChange}
                                            value={data.agree_to_terms_and_conditions}
                                            error={action.isSubmitted && errors.agree_to_terms_and_conditions ? errors.agree_to_terms_and_conditions : ''}
                                            label={<Typography variant="caption" sx={{ display: `flex` }}>By checking this box, you agree to our &nbsp;<RouteLink to={preAuthRoutes('terms_and_conditions').path} label={`Terms and Conditions`} style={{ fontSize: `9px`, color: `#5271ff` }} target="_blank" /></Typography>}
                                            className={`checkBox`}
                                        />
                                    </Stack>
                                    <Stack spacing={1} direction={'row'}>
                                        <CancelButton fullWidth label={'Back'} color={`light`} loading={register_mentor_checkout_loading} onClick={handleBackBtn} />
                                        <SubmitButton fullWidth label={'Checkout'} loading={register_mentor_checkout_loading} />
                                    </Stack>
                                </Stack>
                            </form>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={`center`} justifyContent={`center`}>
                            <Animate>
                                <CardMedia component={`img`} src={bannerImage} />
                            </Animate>
                        </Grid>
                    </Grid>
                </Box>
            </MentorRegisterCheckoutStyled>
        </PreAuth>

    );
};

export { MentorRegisterCheckout };