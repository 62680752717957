import React from 'react';
import { FooterStyled } from '.';
import { Animate, Logo, RouteLink } from '../../../_components';
import { Box, Button, CardMedia, Container, Grid, Stack, Typography } from '@mui/material';
import { preAuthRoutes } from '../../../routes';

import instagramIcon from '../../../_theme/images/social-media-icons/instagram-icon.png';
import facebookIcon from '../../../_theme/images/social-media-icons/facebook-icon.png';
import xIcon from '../../../_theme/images/social-media-icons/x-icon.png';
import youtubeIcon from '../../../_theme/images/social-media-icons/youtube-icon.png';
import tikTokIcon from '../../../_theme/images/social-media-icons/tik-tok-icon.png';

const Footer = () => {
    return (
        <FooterStyled>
            <Container>
                <Box className='content'>
                    <Grid container spacing={3} >
                        <Grid item xs={12} md={4}>
                            <Animate>
                                <Stack spacing={3} display={'flex'} alignItems={'flex-start'}>
                                    <RouteLink to={preAuthRoutes('landing_page').path} label={<Logo className='logo' fill={`#fff`} />} />
                                    <RouteLink to={preAuthRoutes('mentor_pre_register').path} label={<Button variant='outlined' color='light'>Join Pave</Button>} />
                                    <Stack spacing={0.5} display={'flex'} alignItems={'flex-start'}>
                                        <Typography variant="h6" >Our Promise</Typography>
                                        <Typography variant="body2" >Providing the platform and tools for young athletes  to collaborate with targeted college athletes in a remote, secure,  and effective manner while constantly providing financial aid and exclusive benefits to members of the platform.</Typography>
                                    </Stack>
                                </Stack>
                            </Animate>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Animate animate='right-left'>
                                <Stack spacing={3} display={'flex'} alignItems={'flex-start'}>
                                    <Stack spacing={1} display={'flex'} alignItems={'flex-start'}>
                                        <Typography variant="h6" >Follow us</Typography>
                                        <Stack direction={'row'} spacing={1.5} display={'flex'} alignItems={'flex-start'} className='social-media-icons'>
                                            <RouteLink to={'https://instagram.com/join.pave/'} label={<CardMedia component={`img`} src={instagramIcon} />} target="_blank" />
                                            <RouteLink to={'https://instagram.com/join.pave/'} label={<CardMedia component={`img`} src={facebookIcon} />} target="_blank" />
                                            <RouteLink to={'https://twitter.com/joinpave'} label={<CardMedia component={`img`} src={xIcon} />} target="_blank" />
                                            <RouteLink to={'https://instagram.com/join.pave/'} label={<CardMedia component={`img`} src={youtubeIcon} />} target="_blank" />
                                            <RouteLink to={'https://tiktok.com/@join.pave?_t=8jUrtpTTD0o&_r=1'} label={<CardMedia component={`img`} src={tikTokIcon} />} target="_blank" />
                                        </Stack>
                                    </Stack>
                                    <Stack spacing={1} display={'flex'} alignItems={'flex-start'}>
                                        <Typography variant="h6" >Company</Typography>
                                        <Stack direction={'row'} spacing={0.5} className='company-stack' flexWrap="wrap" useFlexGap>
                                            <RouteLink to={preAuthRoutes('become_pave_partner').path} label={preAuthRoutes('become_pave_partner').name} />
                                            <RouteLink to={preAuthRoutes('paid_college_internships').path} label={preAuthRoutes('paid_college_internships').name} />
                                            {/* <RouteLink to={preAuthRoutes('giveaways').path} label={`Pave Giveaways`} /> */}
                                            <RouteLink to={preAuthRoutes('faq').path} label={`FAQ`} />
                                            <RouteLink to={preAuthRoutes('about_us').path} label={`About Pave`} />
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Animate>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Animate>
                                <Stack spacing={3} display={'flex'} alignItems={'flex-start'}>
                                    <Stack spacing={0.5} display={'flex'} alignItems={'flex-start'}>
                                        <Typography variant="body2" >Contact us: Info@JoinPave.io</Typography>
                                    </Stack>
                                    <Stack direction={'row'} spacing={0.5} className='privacy-terms-stack' flexWrap="wrap" useFlexGap>
                                        <Typography variant="body2" >© PAVE MENTORS 2024. ALL RIGHTS RESERVED</Typography>
                                        <RouteLink to={preAuthRoutes('privacy_and_policy').path} label={`POLICY`} />
                                        <RouteLink to={preAuthRoutes('terms_and_conditions').path} label={`TERMS`} />
                                    </Stack>
                                </Stack>
                            </Animate>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </FooterStyled>
    );
};

export { Footer };