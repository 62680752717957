import React from 'react';
import { WhyYouShouldBeMentorStyled } from './WhyYouShouldBeMentorStyled';
import { Grid, Stack, Typography } from '@mui/material';
import { MENTEE, MENTEES } from '../../../../../_helpers';

let data = [
    {
        title: 'Build leadership skills & become self-accountable',
        body: 'Mentoring enables leaders to walk the walk and show their ' + MENTEES + ' how to be a leader and make difficult decisions. '
    },
    {
        title: 'Gain the satisfaction from helping others',
        body: 'Mentoring is a simple and hands-on way to make your mark on the world. Watching a individual you are helping develop into the person they are meant to be is deeply fulfilling. '
    },
    {
        title: 'Improve your emotional IQ',
        body: 'Mentoring requires you to be aware of yourself and also to be tuned into the feelings of your ' + MENTEE + '. Through this experience self awareness, empathy and humility grow. '
    },
    {
        title: 'Deepen your own personal skills',
        body: 'Lessons you have learned over your life are understood best by teaching them to someone else. The more you recall the important experience, and share that wisdom with another, the more it deepens your craft. '
    },
];


const WhyYouShouldBeMentor = () => {
    return (
        <WhyYouShouldBeMentorStyled>
            <Typography variant="h5" className='heading'>Why you should be a Mentor</Typography>
            <Grid container spacing={3} className='main-stack'>
                <Grid item xs={12} md={12} display={`flex`} justifyContent={`left`} alignItems={`center`}>
                    <Stack spacing={3} display={`flex`} alignItems={`flex-start`} justifyContent={`center`}>
                        {data.map((content, i) =>
                            <Stack key={i} spacing={0.5} display={`flex`} alignItems={`flex-start`} justifyContent={`center`} className='main-stack'>
                                <Typography variant="h6">{content.title}</Typography>
                                <Typography variant="body2">{content.body}</Typography>
                            </Stack>
                        )}
                    </Stack>
                </Grid>
            </Grid>
        </WhyYouShouldBeMentorStyled>
    );
};

export { WhyYouShouldBeMentor };