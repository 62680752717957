/**
 * The function exports a request object with a specified type, request, and optional additional properties.
 * @param {string} type - The type parameter is a string that represents the type of request being made.
 * It can be used to differentiate between different types of requests in the application.
 * @param {object} request - The `request` parameter is the actual request object that will be sent to the server.
 * It can contain information such as the HTTP method (GET, POST, etc.), the URL, headers, and body data.
 * @param {object} props - Props is an optional parameter that allows you to pass additional properties to the request object.
 * These properties can be used to provide more information or customize the request as  needed.
 * @returns an object with the following properties: "type", "request", and any additional properties
 * passed in the "props" parameter.
 * @author Akshay
 * @created_at 04/11/2023
 */
export const request = (type, request, props = {}) => {
    return { type: type, request, ...props };
};

/**
 * The function "success" returns an object with properties including type, request, message, data, status,
 * status_code, and any additional properties passed in as props.
 * @param {string} type - The "type" parameter is used to specify the type of success action being dispatched.
 * It can be a string or a constant that represents the action type.
 * @param {object} request - The `request` parameter is the request object that was sent to the server. It
 * typically contains information such as the HTTP method, headers, and body of the request.
 * @param {object} response - The `response` parameter is an object that contains information about the response
 * from a server. It typically includes properties such as `message`, `data`, `status`, and
 * `status_code`. These properties provide details about the success of the request and the data
 * returned by the server.
 * @param {object} [props] - The `props` parameter is an optional object that allows you to include additional
 * properties in the returned object. These properties can be used to provide any extra information or
 * customization that you need in your success response.
 * @returns an object with the following properties: type, request, message, data, status, status_code,
 * and any additional properties passed in the props object.
 * @author Akshay
 * @created_at 04/11/2023
 */
export const success = (type, request, response, props = {}) => {
    return { type: type, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code, ...props };
};

/**
 * The function "failure" returns an object with various properties including the type, request, error
 * data, errors, message, status, status_code, and any additional properties passed in as props.
 * @param {string}  type - The `type` parameter is a string that represents the type of failure that occurred. It
 * can be used to identify the specific type of failure in your application.
 * @param {object}  request - The `request` parameter is used to store information about the request that
 * resulted in the failure. It could include details such as the URL, method, headers, and any request data.
 * @param {object}  error - The `error` parameter is an object that contains information about the error that
 * occurred. It typically includes properties such as `data` (the data associated with the error),
 * `errors` (an array of specific error messages), `message` (a general error message), `status`
 * @param {object}  [props] - The `props` parameter is an optional object that allows you to include additional
 * properties in the returned object. These properties can be used to provide any extra information or customization
 * you need in the failure object.
 * @returns an object with the following properties: type, request, data, errors, message, status,
 * status_code, and any additional properties passed in the props object.
 * @author Akshay
 * @created_at 04/11/2023
 */
export const failure = (type, request, error, props = {}) => {
    return { type: type, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code, ...props };
};

export * from './GlobalAction';
export * from './ProfileAction';
export * from './EmailLogAction';

export * from './mentor';
export * from './mentee';

export * from './ReferAndEarnAction';
