import React, { Fragment, useState } from 'react';
import { AppBar, Box, Button, Collapse, Container, Divider, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar } from '@mui/material';
import { postAuthMenuBarRoutes } from '../../../routes';
import { MenuBarStyled } from '.';
import { Home, Menu as MenuIcon, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Logo } from '../../../_components';
import { GlobalAction } from '../../../redux/actions';

function MenuBar() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const location = useLocation();
    const menuRoutes = Object.values(postAuthMenuBarRoutes());

    /* Destructuring the state from redux store. */
    const setDrawer = (params) => dispatch(GlobalAction.setDrawer(params));
    const { isDrawerOpen, themeLayout } = useSelector((state) => state.GlobalReducer);

    /* Initializing the state with the default values. */
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const [opens, setOpens] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    /**
     * This function sets the anchor element and toggles the state of a boolean variable.
     * @param {Object} event - The event parameter is an object that contains information about the event that
     * triggered the handleClick function. This could be a mouse click, a keyboard press, or any other
     * type of user interaction with the application. The event object contains properties such as the
     * target element, the type of event, and any additional data
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpens(!opens);
    };

    /**
     * The function handleClose sets the value of AnchorEl to null.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const handleClose = () => { setAnchorEl(null); };

    /**
     * The function toggles the state of a drawer and sets the hovered state to false.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const toggleDrawer = () => {
        setDrawer(!isDrawerOpen);
        setIsHovered(false);
    };

    /**
     * This is a function that sets a state variable to true when the mouse enters a certain area.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const onMouseEnter = () => { setIsHovered(true); };

    /**
     * The function sets the state of "isHovered" to false when the mouse leaves an element.
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 21/05/2023
     */
    const onMouseLeave = () => { setIsHovered(false); };

    return (
        <Box sx={{ display: { xs: 'none', md: 'block' } }} >
            {themeLayout === 'vertical' ? (
                <MenuBarStyled>
                    <Drawer variant="permanent" open={isDrawerOpen} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className="menu-vertical">
                        <Box className={`${isDrawerOpen ? `drawer-open` : ``} ${isHovered ? `is-hovered` : ``}`} >
                            <Box className='menu-bar-action'>
                                <Logo height="20" className="logo" />
                                <IconButton edge="start" color='warning' onClick={toggleDrawer} >
                                    <MenuIcon />
                                </IconButton>
                            </Box>
                            <Box className='menu-list'>
                                <List>
                                    {menuRoutes.map((route, index) => {
                                        var splitPathName = location.pathname.split('/');
                                        var parentPathName = splitPathName.slice(0, 2).join('/');

                                        if (route.hasOwnProperty('children')) {

                                            const isParentActive = route.children.some((routeChild, index) => {
                                                return ((routeChild.path === location.pathname || parentPathName === routeChild.path || route.path === parentPathName) ? `active` : '');
                                            });

                                            return (
                                                <Fragment key={index}>
                                                    <ListItemButton onClick={handleClick} className={isParentActive ? 'active' : ''} >
                                                        <ListItemIcon>   <route.menuIcon />  </ListItemIcon>
                                                        <ListItemText className={`menu-name ${isDrawerOpen ? `show-menu-name` : `hide-menu-name`}`} >{route.name}</ListItemText>
                                                        {((isDrawerOpen || isHovered)) && <ListItemIcon className='list-item-action'>{opens ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>}
                                                    </ListItemButton>
                                                    <Collapse in={((isDrawerOpen || isHovered)) && opens} unmountOnExit>
                                                        <List component="div" disablePadding>
                                                            {route.children.map((routeChild, index) => {
                                                                return (
                                                                    <ListItemButton key={index} component={Link} to={routeChild.path} className={(routeChild.path === location.pathname) ? `sub-menu-active` : ((parentPathName === routeChild.path) ? 'active' : '')}>
                                                                        <ListItemText className={`menu-name ${isDrawerOpen ? `show-menu-name` : `hide-menu-name`}`} >{routeChild.name}</ListItemText>
                                                                    </ListItemButton>
                                                                );
                                                            })}
                                                        </List>
                                                    </Collapse>
                                                </Fragment>
                                            );
                                        } else {
                                            return (
                                                <Fragment key={index}>
                                                    {route.hasSeparation && <Divider sx={{ margin: `5px 0` }} />}
                                                    <ListItemButton component={Link} to={route.path} className={(route.path === location.pathname) ? `active` : ((parentPathName === route.path) ? 'active' : '')}>
                                                        <ListItemIcon>
                                                            <route.menuIcon />
                                                        </ListItemIcon>
                                                        <ListItemText className={`menu-name ${isDrawerOpen ? `show-menu-name` : `hide-menu-name`}`} >{route.name}</ListItemText>
                                                    </ListItemButton>
                                                </Fragment>
                                            );
                                        }
                                    })}

                                </List>
                            </Box>
                        </Box>
                    </Drawer>
                </MenuBarStyled>
            ) : (
                <MenuBarStyled >
                    <AppBar position='sticky' elevation={0} >
                        <Container>
                            <Toolbar disableGutters>
                                <Box>
                                    {menuRoutes.map((route, index) => {
                                        var splitPathName = location.pathname.split('/');
                                        var parentPathName = splitPathName.slice(0, 2).join('/');
                                        if (route.hasOwnProperty('children')) {
                                            const isParentActive = route.children.some((routeChild, index) => {
                                                return ((routeChild.path === location.pathname || parentPathName === routeChild.path || route.path === parentPathName) ? `active` : '');
                                            });
                                            return (
                                                <Fragment key={index}>
                                                    <Button onClick={handleClick} startIcon={<Home />} className={isParentActive ? 'active' : ''}>{route.name} </Button>
                                                    <Menu anchorEl={anchorEl} open={openMenu} onClose={handleClose}   >
                                                        {route.children.map((routeChild, index) => {
                                                            return (
                                                                <MenuItem key={index} component={Link} to={routeChild.path} className={(routeChild.path === location.pathname) ? `active` : ((parentPathName === routeChild.path) ? 'active' : '')}>
                                                                    {routeChild.name}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Menu>
                                                </Fragment>
                                            );
                                        } else {
                                            return (
                                                <Fragment key={index}>
                                                    {route.hasSeparation && <Divider sx={{ margin: `5px 0` }} />}
                                                    <Button component={Link} startIcon={<route.menuIcon />} to={route.path} className={(route.path === location.pathname) ? `active` : ((parentPathName === route.path) ? 'active' : '')} >
                                                        {route.name}
                                                    </Button>
                                                </Fragment>
                                            );
                                        }
                                    })}
                                </Box>
                            </Toolbar>
                        </Container>
                    </AppBar>
                </MenuBarStyled>

            )}
        </Box>
    );
}

export { MenuBar };
