import React, { useState } from 'react';
import { PreAuth } from '../../../../../_layouts/pre-auth';
import { Box, CardMedia, Grid, List, ListItem, ListItemText, Slider, Stack, Typography } from '@mui/material';
import { Animate, CancelButton, FormQuantityInput, PageHeader, SubmitButton } from '../../../../../_components';
import { useLocation, useNavigate } from 'react-router-dom';
import { preAuthRoutes } from '../../../../../routes';
import { useDispatch, useSelector } from 'react-redux';
import { MenteeAuthAction } from '../../../../../redux/actions';
import { MenteeRegisterCheckoutStyled } from './MenteeRegisterCheckoutStyled';

import bannerImage from './../../../../../_theme/images/bg-02.png';
import { handleInputChange } from '../../../../../_helpers';


const MenteeRegisterCheckout = () => {



    /** Initialize plugins and variables */
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { search } = useLocation();
    const params = new URLSearchParams(search);


    /* Destructuring the state from redux store. */
    const { register_mentee_checkout_loading } = useSelector((state) => state.MenteeAuthReducer);
    const registerMenteeCheckout = (params) => dispatch(MenteeAuthAction.registerMenteeCheckout(params));

    /* Initializing the state with the default values. */
    const [data, setData] = useState({ _id: params.get('authToken') });

    /**
      * The function handles the submit event of the form. It prevents the default action of the form,
      * sets the isSubmitted state to true, and then validates the form. If the form is valid, it
      * dispatches the corresponding action
      * @param {Object} e the event object
      * @return {null}
      * @author Akshay N
      * @created_at 20/05/2023
      */
    function handleSubmit(e) {

        e.preventDefault();
        dispatchRegisterAction();
    }

    /**
     * function to dispatch registration api
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    async function dispatchRegisterAction() {
        const registered = await registerMenteeCheckout(data);
        if (registered && registered.status === 1) {
            /**
            * Checking if the user is redirected from a page, and
            * if so, redirects to that page, else redirects to the sign in page.
            */
            let redirectPath = `${preAuthRoutes('mentee_checkout_payment').path}?authToken=${registered.data._id}`;
            navigate(redirectPath);
        }
    }

    const handleBackBtn = () => {
        navigate(preAuthRoutes('mentee_register_basic_details').path);
    };


    return (
        <PreAuth>
            <MenteeRegisterCheckoutStyled>
                <Box className='content'>
                    <PageHeader title={"Checkout"} style={{ textDecoration: `underline` }} />
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} display={`flex`} justifyContent={`center`} sx={{ alignItems: { xs: `flex-start`, md: `center` } }}>
                            <form onSubmit={handleSubmit} noValidate>
                                <Stack spacing={2}>
                                    <Stack spacing={1}>
                                        <Typography variant="h6"><span style={{ textDecorationLine: `line-through` }} >$30 Yearly Membership</span> <span style={{ color: `#56D19A`, fontWeight: `600` }}>FREE</span> For first 6 months</Typography>
                                        <Typography variant="body2" style={{ fontSize: `12px`, fontStyle: `italic` }}>Just $2.50 per month!</Typography>
                                        <Typography variant="h6"><span style={{ textDecorationLine: `line-through` }} >2.5% Service Fee</span> <span style={{ color: `#56D19A`, fontWeight: `600` }}>FREE</span> <span style={{ fontSize: `12px`, fontWeight: `500` }} > through september</span></Typography>
                                        <Typography variant="body2" style={{ fontSize: `20px`, fontStyle: `italic` }}>NO MONTHLY FEES!</Typography>
                                    </Stack>
                                    <Stack>
                                        <Typography variant="body2">What You Get:</Typography>
                                        <List sx={{ listStyleType: 'disc' }}>
                                            <ListItem sx={{ display: 'list-item' }}><ListItemText primary="Private access and exposure to verified Pave Mentors on the platform" /> </ListItem>
                                            <ListItem sx={{ display: 'list-item' }}><ListItemText primary="Auto-matching algorithm for perfect-fit mentorship suggestions" /> </ListItem>
                                            <ListItem sx={{ display: 'list-item' }}><ListItemText primary="Mentorship tools with  continuous innovation" /> </ListItem>
                                            <ListItem sx={{ display: 'list-item' }}><ListItemText primary="Safe, secure platform " /> </ListItem>
                                        </List>
                                    </Stack>
                                    <Stack spacing={1} direction={'row'}>
                                        <CancelButton fullWidth label={'Back'} color={`light`} loading={register_mentee_checkout_loading} onClick={handleBackBtn} />
                                        <SubmitButton fullWidth label={'Checkout'} loading={register_mentee_checkout_loading} />
                                    </Stack>
                                </Stack>
                            </form>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={`center`} justifyContent={`center`}>
                            <Animate>
                                <CardMedia component={`img`} src={bannerImage} />
                            </Animate>
                        </Grid>
                    </Grid>
                </Box>
            </MenteeRegisterCheckoutStyled>
        </PreAuth>

    );
};

export { MenteeRegisterCheckout };