import React from 'react'
import { Container, styled } from '@mui/material'

const ContactUsStyled = styled(({ ...props }) => <Container {...props} />)`
	& .content {
		min-height: 65vh;
	}
`

export { ContactUsStyled }
