import React from 'react';
import { Box, styled } from '@mui/material';

const FeaturedMentorsStyled = styled(({ ...props }) => <Box {...props} />)`
	& .MuiPaper-root {
		background: none;
		border-radius: ${(props) => props.theme.borders.borderRadius.section};
		& .MuiCardMedia-root {
			width: 100%;
			margin: 0;
			border-radius: ${(props) => props.theme.borders.borderRadius.section};
		}
		& .MuiStack-root {
			width: 100%;
		}
		& .main-stack {
			& .rating {
				display: flex;
				align-items: center;
				justify-content: center;
				& .MuiSvgIcon-root {
					padding-right: 8px;
				}
			}
		}
	}
	& .swiper-button-next {
		display: none;
	}
	& .swiper-button-prev {
		display: none;
	}
	& .SwiperButtons {
		position: absolute;
		top: 0;
		right: 0;
	}

	& .profile-media {
		width: 100%;
		overflow: hidden;
		border-radius:  ${(props) => props.theme.borders.borderRadius.section};
		& .MuiBox-root {
			padding-top: 100%;
			object-fit: contain;
			background-size: cover !important;
			background-repeat: no-repeat !important;
		}
	}
`;

export { FeaturedMentorsStyled };
