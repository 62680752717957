import React, { useEffect, useState } from 'react';
import { PreAuth } from '../../../_layouts/pre-auth';
import { CardMedia, Grid } from '@mui/material';
import { ContactUsStyled } from './ContactUsStyled';
import { Animate, FormInput, PageHeader, SubmitButton } from '../../../_components';
import { useDispatch, useSelector } from 'react-redux';
import { handleInputChange, validateForm } from '../../../_helpers';
import { validate } from '.';
import { MentorAuthAction } from '../../../redux/actions';
import { preAuthRoutes } from '../../../routes';
import { ArrowForward } from '@mui/icons-material';

import bannerImage from './../../../_theme/images/bg-03.png';


/* Initializing the input with the default values. */
const inputs = { name: '', email: '', question: '' };

const ContactUs = () => {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /* Destructuring the state from redux store. */
    const { contact_us_loading, contact_us_errors } = useSelector((state) => state.MentorAuthReducer);
    const contactUsAction = (params) => dispatch(MentorAuthAction.contactUs(params));

    /* Initializing the state with the default values. */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, remember_me: '' });
    const [action, setAction] = useState({ isSubmitted: false });

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        setErrors({ ...contact_us_errors });
    }, [contact_us_errors]);

    /**
     * It takes the name and value of the input, validates it, and sets the errors and data state
     * @param {Object} e form object
     * @param {String} e.name input name
     * @param {String} e.value input value
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        /* Validating the input and setting the errors state. */
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * The function handles the submit event of the form. It prevents the default action of the form,
     * sets the isSubmitted state to true, and then validates the form. If the form is valid, it
     * dispatches the corresponding action
     * @param {Object} e the event object
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    function handleSubmit(e) {

        e.preventDefault();
        setAction({ isSubmitted: true });

        /* Iterating through the data object and validating each key value pair. */
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value);
            formErrors[name] = inputError[name];
        }

        /* Checking if the form is valid or not. If the form is not valid, it sets the errors state and returns. */
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchContactUsAction();
    }

    /**
     * It checks if the user is signed in, and if so, redirects to the home page
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    async function dispatchContactUsAction() {
        /* Dispatching the contact us action and waiting for the response. */
        const isContactUsAdded = await contactUsAction(data);
        if (isContactUsAdded.status === 1) {

            setData({ ...inputs });
            /**
             * Checking if the user is redirected from a page, and
             * if so, redirects to that page, else redirects to the home page.
             */
            // let redirectPath = ((location.state && location.state.from.pathname) ? (location.state.from.pathname) : postAuthRoutes('dashboard').path);
            // navigate(`${redirectPath}`);
        }
    }

    return (
        <PreAuth>
            <ContactUsStyled>
                <Grid container spacing={3} className='content'>
                    <Grid item xs={12} md={6} display={`flex`} justifyContent={`center`} alignItems={{ xs: `flex-start`, md: `center` }}>
                        <Animate animate='right-left'>
                            <PageHeader title={"Contact Us"} subheader="We will get back to you within a few hours" subheaderTypographyProps={{ variant: 'body2', color: 'text' }} />
                            <form onSubmit={handleSubmit} noValidate>
                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormInput
                                            tabIndex={1}
                                            label='Name'
                                            name='name'
                                            value={data.name}
                                            error={action.isSubmitted && errors.name ? errors.name : ''}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormInput
                                            tabIndex={1}
                                            label='Email'
                                            name='email'
                                            value={data.email}
                                            error={action.isSubmitted && errors.email ? errors.email : ''}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormInput
                                            tabIndex={1}
                                            label='Question'
                                            name='question'
                                            value={data.question}
                                            error={action.isSubmitted && errors.question ? errors.question : ''}
                                            onChange={handleChange}
                                            multiline
                                            rows={4}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" alignItems="center" justifyContent="space-between" >
                                        <SubmitButton label={`${preAuthRoutes('contact_us').name}`} loading={contact_us_loading} tabIndex={3} endIcon={<ArrowForward />} />
                                    </Grid>
                                </Grid>
                            </form>
                        </Animate>
                    </Grid>
                    <Grid item xs={12} md={6} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                        <Animate>
                            <CardMedia component={`img`} src={bannerImage} />
                        </Animate>
                    </Grid>
                </Grid>
            </ContactUsStyled>
        </PreAuth>
    );
};

export { ContactUs };