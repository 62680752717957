

import React, { useEffect, useState } from 'react';
import { Snackbar as MuiSnackbar } from '@mui/material';
import Slide from '@mui/material/Slide';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalAction } from '../../redux/actions';

function Snackbar() {

    const dispatch = useDispatch();
    const { open, message } = useSelector((state) => state.GlobalReducer);

    const handleClose = () => {
        dispatch(GlobalAction.showToastMessage(false, ''));
    };
    return (
        <MuiSnackbar autoHideDuration={6000} open={open} onClose={handleClose} TransitionComponent={Slide} message={message} key={Slide.name} />
    );
}


export { Snackbar };
