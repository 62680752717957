// eslint-disable-next-line import/no-anonymous-default-export
export default {
    defaultProps: {},
    styleOverrides: {
        root: {},
        label: {},
        labelContainer: {},
        switchViewButton: {},
        switchViewIcon: {}
    },
    variants: []
};
