

import React from 'react'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight';
import theme from 'react-syntax-highlighter/dist/esm/styles/hljs/atom-one-dark';
import { Box, Typography } from '@mui/material'

function ErrorElement() {

    let error = useRouteError();
    let message = isRouteErrorResponse(error) ? `${error.status} ${error.statusText}` : (error instanceof Error ? error.message : JSON.stringify(error));
    let stack = error instanceof Error ? error.stack : null;

    return (
        <Box>
            <Typography variant="h2" >Unexpected Application Error!</Typography>
            <Typography variant="h3" style={{ fontStyle: "italic" }} >{message}</Typography>
            <SyntaxHighlighter wrapLines={true} showLineNumbers={true} style={theme}>{stack}</SyntaxHighlighter>
        </Box>
    );
}

export { ErrorElement }