import { Box, styled } from '@mui/material';

import backgroundImage from './../../../../_theme/images/bg-05.png';
import backgroundImageWeb from './../../../../_theme/images/bg-05-web.png';

const MenteeLandingPageStyled = styled(({ ...props }) => <Box {...props} />)`
	& .section {
		& .section-content {
			background-image: linear-gradient(rgba(0, 0, 0, 0.3) 80%, rgba(255, 255, 255, 0.3) 90%, rgba(255, 255, 255, 1) 100%), url(${backgroundImage});
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			display: flex;
			align-items: end;
			justify-content: center;
			overflow: hidden;
			padding-bottom: ${(props) => props.theme.spacing(3)};
			min-height: ${(props) => `${props.theme.functions.pxToRem(380)}`};
			${(props) => props.theme.breakpoints.up('sm')} {
				background-image: linear-gradient(rgba(0, 0, 0, 0.3) 80%, rgba(255, 255, 255, 0.3) 90%, rgba(255, 255, 255, 1) 100%), url(${backgroundImageWeb});
				min-height: ${(props) => `${props.theme.functions.pxToRem(580)}`};
			}
			& .description {
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				font-weight: 500;
				color: ${(props) => props.theme.palette.light.main};
				${(props) => props.theme.breakpoints.up('md')} {
					font-size: ${(props) => props.theme.typography.h1.fontSize};
				}
				& a {
					margin-left: ${(props) => props.theme.spacing(1)};
					font-size: ${(props) => props.theme.typography.h5.fontSize};
				}
			}

			& .MuiStack-root {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			& a {
				color: ${(props) => props.theme.palette.light.main};
				text-decoration: underline;
			}
		}
	}
`;

export { MenteeLandingPageStyled };
