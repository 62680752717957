import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { BannerStyled } from '.';
import { preAuthRoutes } from '../../../../routes';
import { useNavigate } from 'react-router-dom';

function Banner() {

    /** Initialize plugins and variables */
    let navigate = useNavigate();

    const handleBtnClick = () => {
        navigate(preAuthRoutes('mentor_pre_register').path);
    };

    return (
        <BannerStyled>
            <Box className='section'>
                <Box className='section-content'>
                    <Stack className='w-100'>
                        <Typography variant="h2" className='description'>Paid Mentorship,<br />from anywhere.</Typography>
                    </Stack>
                    <Button variant='contained' color='light' className='for-mentors-btn' onClick={handleBtnClick}>Become a Mentor</Button>
                </Box>
            </Box>
            {/* <Box className='section-footer'>
                <Typography variant="h6" className='description'>Get Paid to help others reach their goals</Typography>
            </Box> */}
        </BannerStyled>
    );
}

export { Banner };