import React from 'react';
import { Box, styled } from '@mui/material';

import backgroundImage from './../../../../../_theme/images/bg-05.png';
import backgroundImageWeb from './../../../../../_theme/images/bg-05-web.png';

const MentorPreRegisterStyled = styled(({ ...props }) => <Box {...props} />)`
	& .section-1 {
		background-color: #373435;
		& .section-1-content {
			background-image: linear-gradient(rgba(0, 0, 0, 0.3) 80%, rgba(255, 255, 255, 0.3) 90%, rgba(255, 255, 255, 1) 100%), url(${backgroundImage});
			background-repeat: no-repeat;
			background-size: cover;
			position: relative;
			background-position: center;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: ${(props) => `${props.theme.spacing(70 / 8)} ${props.theme.spacing(20 / 8)}`};
			overflow: hidden;
			min-height: 380px;
			${(props) => props.theme.breakpoints.up('sm')} {
				background-image: linear-gradient(rgba(0, 0, 0, 0.3) 80%, rgba(255, 255, 255, 0.3) 90%, rgba(255, 255, 255, 1) 100%), url(${backgroundImageWeb});
				min-height: 580px;
			}

			& .description {
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				color: ${(props) => props.theme.palette.light.main};
				font-weight: 550;
				${(props) => props.theme.breakpoints.up('md')} {
					font-size: ${(props) => props.theme.typography.h1.fontSize};
				}
				& a {
					margin-left: ${(props) => props.theme.spacing(1)};
					font-size: ${(props) => props.theme.typography.h5.fontSize};
				}
			}

			& .MuiStack-root {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			& a {
				color: ${(props) => props.theme.palette.light.main};
				text-decoration: underline;
			}
		}
	}
	& .section-2 {
		& .section-2-content {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: ${(props) => `${props.theme.spacing(30 / 8)} ${props.theme.spacing(20 / 8)}`};

			& .per-month-price-caption {
				font-size: 18px;
				color: ${(props) => props.theme.palette.text.main};
			}
		}

		& .MuiNumberInput-root {
			& button {
				border-color: #8b8a9e;
				border-radius: 4px;
			}
		}

		& .MuiSlider-root {
			padding: 5px 0;
		}
		& .MuiNumberInput-input {
			border: none;
			width: 2rem !important;
			padding: 0;
		}

		& .MuiSlider-track {
			background: ${(props) => props.theme.palette.text.main};
		}

		& .MuiStack-root {
			width: 100%;
		}
	}
`;

export { MentorPreRegisterStyled };
