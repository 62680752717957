import borders from "../../../base/borders";
import typography from "../../../base/typography";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    backgroundPositionX: "25% !important",
    transition: "all 150ms ease-in",
    borderRadius: borders.borderRadius.md,
    fontSize: typography.size.md,
    fontWeight: typography.fontWeightMedium
};
