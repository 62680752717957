import React from 'react';
import { Box, styled } from '@mui/material';
import { DRAWER_WIDTH } from '../../../_helpers';

const MenuBarStyled = styled(({ ...props }) => (<Box {...props} />))`

    & .MuiDrawer-root {

        & .MuiDrawer-paper {

            background-color:${props => props.theme.palette.background.sidenav};
            border: none;

            & .is-hovered {
                & .logo {
                    display :block;
                }
                & .menu-name {
                    display: block!important;
                }
                & .MuiList-root {
                    width: ${DRAWER_WIDTH}px !important;
                    height: 100%;

                    & .MuiCollapse-wrapper{
                        & .MuiList-root {
                            width: 100% !important;
                        }
                    }
                }
            }

            & .MuiTypography-root {
                color: ${props => props.theme.palette.grey[200]};
            }

            .MuiSvgIcon-root {
                color: ${props => props.theme.palette.grey[200]};
            }

            & .menu-bar-action {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding : ${props => `${props.theme.spacing(1)}`};
                min-height: ${props => `${props.theme.functions.pxToRem(54)}`};

                & .logo {
                    margin: ${props => props.theme.spacing(1.5)};
                }

                & .MuiButtonBase-root {
                    min-width:  ${props => props.theme.spacing(2)};
                    padding: ${props => `${props.theme.spacing(1.5)} ${props.theme.spacing(0.75)} ${props.theme.spacing(1.5)} ${props.theme.spacing(1.5)}`};
                    margin-left:0;
                }
            }
            & .menu-list{
                & .MuiList-root {

                    padding : ${props => props.theme.spacing(1)};

                    & .MuiListItemButton-root {
                        border-radius:  ${props => props.theme.borders.borderRadius.md};
                        padding:0;


                        & .MuiListItemIcon-root {
                            min-width:  ${props => props.theme.spacing(2)};
                            padding: ${props => props.theme.spacing(1.5)};
                            font-size:  ${props => props.theme.typography.size.xl};;
                            color: ${props => props.theme.palette.grey[200]};
                        }

                        & .MuiListItemText-root {
                            margin-top: 0;
                            margin-bottom: 0;
                            color: ${props => props.theme.palette.grey[200]};
                            & .MuiTypography-root {
                                font-size:  ${props => props.theme.typography.size.sm};;
                                padding-right: ${props => props.theme.spacing(2)};;
                            }

                        }
                        & .list-item-action {
                            .MuiSvgIcon-root {
                                color: ${props => props.theme.palette.grey[200]};
                            }
                        }

                        &:hover {
                            & .MuiTypography-root {
                                color: ${props => props.theme.palette.primary.main} !important;
                            }
                            & .MuiListItemIcon-root {
                                color: ${props => props.theme.palette.primary.main} !important;
                            }

                            .MuiSvgIcon-root {
                                color: ${props => props.theme.palette.primary.main} !important;
                            }

                            & .list-item-action {
                                .MuiSvgIcon-root {
                                    color: ${props => props.theme.palette.primary.main} !important;
                                }
                            }
                        }
                    }

                    & .MuiCollapse-wrapper{
                        & .MuiList-root {
                            padding : 0px;
                            padding-left:  ${props => props.theme.spacing(4)};;

                            & .MuiButtonBase-root {
                                padding:${props => props.theme.spacing(1)};;

                                &:hover{
                                    &:before{
                                        transform: scale(1);
                                        opacity: 1;
                                    }
                                }

                                    &:before{
                                    content: " ";
                                    background:${props => props.theme.palette.primary.main};
                                    opacity: 0;
                                    transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                    width: ${props => `${props.theme.functions.pxToRem(6)}`};
                                    height: ${props => `${props.theme.functions.pxToRem(6)}`};
                                    transform: scale(0);
                                    transform-origin: center center;
                                    border-radius:  ${props => props.theme.borders.borderRadius.md};
                                    margin-right: ${props => props.theme.spacing(2)};
                                    }
                            }
                        }
                    }

                }
            }

            & .show-menu-name {
                display: block !important
            }
            & .hide-menu-name {
                display: none !important
            }
            & .logo {
                    display :none;
                }
            & .drawer-open {
                width: ${DRAWER_WIDTH}px !important;
                height: 100%;
                & .logo {
                    display :block;
                }
            }

            & .active {
                background-color: ${props => props.theme.palette.primary.main};
                color: ${props => (props.theme.palette.common.white)} !important;
                & .MuiTypography-root {
                    color: ${props => (props.theme.palette.common.white)} !important;
                }
                .MuiSvgIcon-root {
                    color: ${props => (props.theme.palette.common.white)} !important;
                }

                &:hover {
                    color: ${props => props.theme.palette.primary.main};
                    background-color: ${props => (props.theme.palette.common.white)} !important;
                }
            }

            & .sub-menu-active {
                color: ${props => (props.theme.palette.primary.light)} !important;
                & .MuiTypography-root {
                    color: ${props => (props.theme.palette.primary.light)} !important;
                }
                .MuiSvgIcon-root {
                    color: ${props => (props.theme.palette.primary.light)} !important;
                }
                &:hover {
                    color: ${props => props.theme.palette.primary.main};
                }

            }
        }
    }

    & .MuiAppBar-root {
        border-bottom : ${props => `${props.theme.borders.borderWidth[1]} solid ${props.theme.borders.borderColor}`};
        background-color : #fff;
        & .MuiToolbar-root {
            min-height:   ${props => props.theme.spacing(7)};
        }

        & .active {
                background-color: ${props => props.theme.palette.grey[300]} !important;
                color: ${props => (props.theme.palette.primary.main)} !important;
                & .MuiTypography-root {
                    color: ${props => (props.theme.palette.primary.main)} !important;
                }
                .MuiSvgIcon-root {
                    color: ${props => (props.theme.palette.primary.main)} !important;
                }

                &:hover {
                    color: ${props => props.theme.palette.secondary.main};
                }
            }
    }
`;

export { MenuBarStyled }
