import React, { useState } from 'react';
import { Box, Button, Container, Grid, Slider, Stack, Typography } from '@mui/material';
import { MentorPreRegisterStyled } from '.';
import { PreAuth } from '../../../../../_layouts/pre-auth';
import { Animate, FormQuantityInput, RouteLink } from '../../../../../_components';
import { preAuthRoutes } from '../../../../../routes';
import { useNavigate } from 'react-router-dom';
import { handleInputChange, MENTEES_CAPITALIZE } from '../../../../../_helpers';
import { FeaturedMentors, MentorBenefits, WhyYouShouldBeMentor } from '../../_components';
import { AboutApp } from '../../../_components';

const initialPayload = { number_of_mentees: 1, per_month_price: 200 };

const MentorPreRegister = () => {

    /** Initialize plugins and variables */
    let navigate = useNavigate();

    const [payload, setPayload] = useState({ ...initialPayload });
    const [price, setPrice] = useState(initialPayload.number_of_mentees * initialPayload.per_month_price);


    const handleBtnClick = () => {
        navigate(preAuthRoutes('mentor_register_auth_details').path);
    };

    const handleChange = (e) => {
        const { name, value } = handleInputChange(e);
        payload[name] = value;
        setPayload({ ...payload });
        setTotalPrice();
    };

    const setTotalPrice = () => {
        setPrice(payload.number_of_mentees * payload.per_month_price);
    };

    return (
        <PreAuth isheaderwhite='true' havebanner='true'>
            <MentorPreRegisterStyled>
                <Grid container spacing={5}>
                    <Grid item xs={12} >
                        <Animate animate='right-left'>
                            <Box className='section-1'>
                                <Box className='section-1-content'>
                                    <Stack spacing={6}>
                                        <Typography variant="h2" className='description'>Get Paid to Mentor<br /> from anywhere.</Typography>
                                        <Stack spacing={2}>
                                            <Button variant='contained' color='light' className='for-mentors-btn' onClick={handleBtnClick}>Get Started</Button>
                                            <RouteLink to={preAuthRoutes('mentor_sign_in').path} label={<React.Fragment>Or Sign In</React.Fragment>} tabIndex={5} />
                                        </Stack>
                                    </Stack>
                                </Box>
                            </Box>
                        </Animate>
                    </Grid>
                    <Grid item xs={12} >
                        <Container>
                            <Grid container spacing={3}>
                                <Grid item xs={12} >
                                    <Animate>
                                        <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                                            <Grid item xs={12} md={4}>
                                                <Box className='section-2'>
                                                    <Box className='section-2-content'>
                                                        <Stack spacing={2}>
                                                            <Box>
                                                                <Typography color={`primary`} variant="h3" textAlign={`center`} sx={{ fontWeight: 600 }}>Pave their way.</Typography>
                                                                <Typography variant="h3" textAlign={`center`} sx={{ fontWeight: 600 }}>You could earn</Typography>
                                                            </Box>
                                                            <Typography variant="h1" color={`primary`} textAlign={`center`}><span style={{ fontWeight: 700 }}>${price} </span> <span className='per-month-price-caption'>Per Month</span></Typography>
                                                            <Stack spacing={1} direction={`row`} display={`flex`} alignItems={`center`} justifyContent={`space-between`}>
                                                                <Typography variant="h6" textAlign={`center`} sx={{ fontSize: `12px` }}>{`# of ${MENTEES_CAPITALIZE} you work with`}</Typography>
                                                                <FormQuantityInput name="number_of_mentees" value={payload.number_of_mentees} onChange={handleChange} isReadOnly={true} />
                                                            </Stack>
                                                            <Stack spacing={1}>
                                                                <Typography variant="body2" textAlign={`center`} >Monthly Mentorship Price: ${payload.per_month_price}</Typography>
                                                                <Slider name="per_month_price" value={payload.per_month_price} step={10} min={10} max={10 * 200} onChange={handleChange} />
                                                            </Stack>
                                                            {/* <Stack spacing={1}>
                                                                <Typography variant="caption" textAlign={`center`} sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}>By checking this box, you agree to our &nbsp;<RouteLink to={preAuthRoutes('terms_and_conditions').path} label={`Terms and Conditions`} style={{ fontSize: `9px`, color: `#5271ff` }} /></Typography>
                                                            </Stack> */}
                                                        </Stack>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Animate>
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                    <Grid item xs={12} >
                        <Container>
                            <Animate animate='right-left'>
                                <MentorBenefits />
                            </Animate>
                        </Container>
                    </Grid>
                    <Grid item xs={12} >
                        <Container>
                            <Animate >
                                <WhyYouShouldBeMentor />
                            </Animate>
                        </Container>
                    </Grid>
                    <Grid item xs={12} >
                        <Container>
                            <Animate animate='right-left'>
                                <AboutApp />
                            </Animate>
                        </Container>
                    </Grid>
                    <Grid item xs={12} >
                        <Container>
                            <Animate>
                                <FeaturedMentors />
                            </Animate>
                        </Container>
                    </Grid>
                </Grid>
            </MentorPreRegisterStyled>
        </PreAuth>
    );
};

export { MentorPreRegister };;