import React from 'react';
import { BecomePavePartnerStyled } from './BecomePavePartnerStyled';
import { PreAuth } from '../../../_layouts/pre-auth';
import { Box, Button, Container, Grid, List, ListItem, Stack, Typography } from '@mui/material';
import { Animate, RouteLink } from '../../../_components';
import { preAuthRoutes } from '../../../routes';
import { MENTEE_CAPITALIZE } from '../../../_helpers';

const BecomePavePartner = () => {
    return (
        <PreAuth isheaderwhite='true' havebanner='true'>
            <BecomePavePartnerStyled>
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        <Animate animate='right-left'>
                            <Box className='section'>
                                <Box className='section-content'>
                                    <Stack spacing={6} className='w-100'>
                                        <Typography variant="h2" className='description'>Become a Pave <br />  Partner</Typography>
                                    </Stack>
                                </Box>
                            </Box>
                            <Box className='section-footer'>
                                <RouteLink to={`${preAuthRoutes('pave_partner_refer_and_earn').path}`} label={<Button variant='contained' color='dark' >Apply Now</Button>} />
                            </Box>
                        </Animate>
                    </Grid>
                    <Grid item xs={12} >
                        <Container>
                            <Grid container spacing={3}>
                                <Grid item xs={12} >
                                    <Animate>
                                        <Box>
                                            <List sx={{ listStyleType: 'disc' }}>
                                                <ListItem sx={{ display: 'list-item' }}>
                                                    <Typography variant="body2" className='list-item'>Refer friends and colleagues and earn commission</Typography>
                                                    <List sx={{ listStyleType: 'disc' }} className='sub-list'>
                                                        <ListItem sx={{ display: 'list-item', }}><Typography variant="h6" className='list-item'>$25-$40 per Mentor</Typography></ListItem>
                                                        <ListItem sx={{ display: 'list-item', }}><Typography variant="h6" className='list-item'>{`$10-$15 per ${MENTEE_CAPITALIZE}`}</Typography></ListItem>
                                                        <ListItem sx={{ display: 'list-item', }}><Typography variant="h6" className='list-item'>Bonuses for milestones</Typography></ListItem>
                                                    </List>
                                                </ListItem>
                                                <ListItem sx={{ display: 'list-item' }}> <Typography variant="body2" className='list-item'>Post on Social channels about @Join.Pave</Typography> </ListItem>
                                            </List>
                                        </Box>
                                    </Animate>
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                </Grid>
            </BecomePavePartnerStyled>
        </PreAuth>
    );
};

export { BecomePavePartner };