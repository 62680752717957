/**
 * The base colors for the Soft UI Dashboard PRO Material.
 * You can add new color using this file.
 * You can customized the colors for the entire Soft UI Dashboard PRO Material using their file.
 */
const colorPalette = {

  primary: {
    light: "#7FDCB2",
    main: "#56D19A",
    dark: "#35C081",
  },

  secondary: {
    light: "#FDECB0",
    main: "#FBE07E",
    dark: "#F9D44D",
  },

  info: {
    light: "#64B5F6",
    main: "#2196F3",
    dark: "#1976D2",
  },

  success: {
    light: "#81C784",
    main: "#4CAF50",
    dark: "#388E3C"
  },

  warning: {
    light: "#FFB74D",
    main: "#FF9800",
    dark: "#F57C00",
  },

  error: {
    light: "#E57373",
    main: "#F44336",
    dark: "#D32F2F",
  },

  light: {
    light: "#F0F2F5",
    main: "#F4F4F4",
    dark: "#E3E3E3"
  },

  dark: {
    light: "#656A72",
    main: "#222222",
    dark: "#35383B",
  },

  link: {
    light: "#5b5b5b",
    main: "#4d4c4c",
    dark: "#434343",
  },

  grey: {
    100: "#F8F9FA",
    200: "#F0F2F5",
    300: "#DEE2E6",
    400: "#CED4DA",
    500: "#ADB5BD",
    600: "#6C757D",
    700: "#495057",
    800: "#343A40",
    900: "#212529",
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {

  background: colorPalette.background,

  transparent: "transparent",

  common: {
    white: "#FFFFFF",
    black: "#000000",
  },

  grey: colorPalette.grey,

  primary: {
    light: colorPalette.primary.light,
    main: colorPalette.primary.main,
    dark: colorPalette.primary.dark
  },

  secondary: {
    light: colorPalette.secondary.light,
    main: colorPalette.secondary.main,
    dark: colorPalette.secondary.dark
  },

  info: {
    light: colorPalette.info.light,
    main: colorPalette.info.main,
    dark: colorPalette.info.dark
  },

  success: {
    light: colorPalette.success.light,
    main: colorPalette.success.main,
    dark: colorPalette.success.dark
  },

  warning: {
    light: colorPalette.warning.light,
    main: colorPalette.warning.main,
    dark: colorPalette.warning.dark
  },

  error: {
    light: colorPalette.error.light,
    main: colorPalette.error.main,
    dark: colorPalette.error.dark
  },

  light: {
    light: colorPalette.light.light,
    main: colorPalette.light.main,
    dark: colorPalette.light.dark
  },

  dark: {
    light: colorPalette.dark.light,
    main: colorPalette.dark.main,
    dark: colorPalette.dark.dark
  },

  link: {
    light: colorPalette.link.light,
    main: colorPalette.link.main,
    dark: colorPalette.link.dark
  },

  gradients: {
    primary: {
      main: colorPalette.primary.main,
      state: colorPalette.primary.dark
    },

    secondary: {
      main: colorPalette.secondary.main,
      state: colorPalette.secondary.dark,
    },

    info: {
      main: colorPalette.info.main,
      state: colorPalette.info.dark,
    },

    success: {
      main: colorPalette.success.main,
      state: colorPalette.success.dark,
    },

    warning: {
      main: colorPalette.warning.main,
      state: colorPalette.warning.dark,
    },

    error: {
      main: colorPalette.error.main,
      state: colorPalette.error.dark,
    },

    light: {
      main: colorPalette.light.main,
      state: colorPalette.light.dark,
    },

    dark: {
      main: colorPalette.dark.main,
      state: colorPalette.dark.dark,
    },
  },

  coloredShadows: {
    primary: colorPalette.primary.main,
    secondary: colorPalette.secondary.light,
    info: colorPalette.info.light,
    success: colorPalette.success.light,
    warning: colorPalette.warning.light,
    error: colorPalette.error.light,
    light: colorPalette.light.light,
    dark: colorPalette.dark.light,
  },

};
