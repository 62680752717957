import React from 'react';
import { Box, styled } from '@mui/material';

const FooterStyled = styled(({ ...props }) => <Box {...props} />)`
	background: ${(props) => props.theme.palette.dark.main};
	padding:  ${(props) => `${props.theme.spacing(2)} ${0}`};
	margin-top:  ${(props) => `${props.theme.spacing(3)}`};
	& .MuiTypography-root {
		color: ${(props) => props.theme.palette.common.white} !important;
	}
	& .company-stack {
		& ::before {
			content: '•'; /* Unicode character for a bullet point */
			margin-right:${(props) => `${props.theme.spacing(0.5)}`};/* Adjust spacing as needed */
			color: ${(props) => props.theme.palette.secondary.main} !important;
		}
	}
	& .MuiCardMedia-root{
		margin:0;
	}
	& .social-media-icons {
		& .MuiCardMedia-root{
			width: ${(props) => `${props.theme.functions.pxToRem(40)}`};
		}
	}
	& .privacy-terms-stack {
		& :not(:last-child)::after {
			content: '|'; /* Unicode character for a bullet point */
			margin-left:${(props) => `${props.theme.spacing(0.5)}`};/* Adjust spacing as needed */
			color: ${(props) => props.theme.palette.secondary.main} !important;

		}
	}
`;

export { FooterStyled };
