import React from 'react';
import { Box, SwipeableDrawer, styled } from '@mui/material';

const MenuBarStyled = styled(({ ...props }) => <Box {...props} />)`
	& .menu-bar-web {
		& .MuiButton-root {
			color: ${(props) => (props.ismenuwhite === 'true' ? props.theme.palette.common.white : props.theme.palette.common.black)} !important;
			padding: 8px 18px;
		}
	}
	& .hamburger {
		color: ${(props) => (props.ismenuwhite === 'true' ? props.theme.palette.common.white : props.theme.palette.common.black)} !important;
	}
	`;

const SwipeableDrawerStyled = styled(({ ...props }) => <SwipeableDrawer {...props} />)`
	& .MuiDrawer-paper {
		background-color: ${(props) => props.theme.palette.background.dark};
		padding: 30px 30px 0;
		border: none;
		margin-left: 30px;
		border-top-left-radius: 30px;
		border-bottom-left-radius: 30px;
		& .menu-items {
			width: 100%;
			padding: 12px 0 0;
			& .MuiButtonBase-root {
				padding: 12px 0 12px;
				width: 100%;
				color: ${(props) => (props.ismenuwhite === 'true' ? props.theme.palette.common.white : props.theme.palette.common.black)} !important;
				/* color: ${(props) => (props.ismenuwhite === 'true' ? props.theme.palette.common.white : props.theme.palette.text.main)}; */
				justify-content: flex-start !important;
				& .MuiSvgIcon-root {
					color: ${(props) => (props.ismenuwhite === 'true' ? props.theme.palette.common.white : props.theme.palette.common.black)} !important;
				}
			}
		}
	}
`;

export { MenuBarStyled, SwipeableDrawerStyled };
