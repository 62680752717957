import {
	MENTOR_REGISTER_AUTH_DETAILS_REQUEST, MENTOR_REGISTER_AUTH_DETAILS_SUCCESS, MENTOR_REGISTER_AUTH_DETAILS_FAILURE,
	MENTOR_REGISTER_BASIC_DETAILS_REQUEST, MENTOR_REGISTER_BASIC_DETAILS_SUCCESS, MENTOR_REGISTER_BASIC_DETAILS_FAILURE,
	MENTOR_REGISTER_CHECKOUT_REQUEST, MENTOR_REGISTER_CHECKOUT_SUCCESS, MENTOR_REGISTER_CHECKOUT_FAILURE,
	MENTOR_SIGN_IN_REQUEST, MENTOR_SIGN_IN_SUCCESS, MENTOR_SIGN_IN_FAILURE,
	MENTOR_SEND_PWD_RST_LINK_REQUEST, MENTOR_SEND_PWD_RST_LINK_SUCCESS, MENTOR_SEND_PWD_RST_LINK_FAILURE,
	MENTOR_RESET_PASSWORD_REQUEST, MENTOR_RESET_PASSWORD_SUCCESS, MENTOR_RESET_PASSWORD_FAILURE,
	CONTACT_US_REQUEST, CONTACT_US_SUCCESS, CONTACT_US_FAILURE,
	CHECK_MENTOR_AUTH_STATUS_REQUEST, CHECK_MENTOR_AUTH_STATUS_SUCCESS, CHECK_MENTOR_AUTH_STATUS_FAILURE
} from "../../actions";

/**
 * This is defining the initial state of the Redux store for the application.
 * It contains several properties with their initial values:
 * */
const initialState = {
	register_mentor_auth_details_loading: false, register_mentor_auth_details: {}, register_mentor_auth_details_errors: {},
	register_mentor_basic_details_loading: false, register_mentor_basic_details: {}, register_mentor_basic_details_errors: {},
	register_mentor_checkout_loading: false, register_mentor_checkout: {}, register_mentor_checkout_errors: {},
	sign_in_mentor_loading: false, sign_in_mentor: {}, sign_in_mentor_errors: {},
	send_mentor_pwd_rst_link_loading: false, send_mentor_pwd_rst_link_request: {}, send_mentor_pwd_rst_link: {}, send_mentor_pwd_rst_link_errors: {},
	reset_mentor_password_loading: false, reset_mentor_password_errors: {},
	contact_us_loading: false, contact_us: {}, contact_us_errors: {},
	mentor_auth_status_loading: false, mentor_auth_status: {}, mentor_auth_status_error: {}
};


export function MentorAuthReducer(state = initialState, action) {
	switch (action.type) {

		/** begin::Register redux */
		case MENTOR_REGISTER_AUTH_DETAILS_REQUEST: {
			return {
				register_mentor_auth_details_loading: true,
			};
		}
		case MENTOR_REGISTER_AUTH_DETAILS_SUCCESS: {
			return {
				register_mentor_auth_details_loading: false,
				register_mentor_auth_details: action.data,
				register_mentor_auth_details_errors: {}
			};
		}
		case MENTOR_REGISTER_AUTH_DETAILS_FAILURE: {
			return {
				register_mentor_auth_details_loading: false,
				register_mentor_auth_details_errors: action.errors
			};
		}
		/** end::Register redux */

		/** begin::Register redux */
		case MENTOR_REGISTER_BASIC_DETAILS_REQUEST: {
			return {
				register_mentor_basic_details_loading: true,
			};
		}
		case MENTOR_REGISTER_BASIC_DETAILS_SUCCESS: {
			return {
				register_mentor_basic_details_loading: false,
				register_mentor_basic_details: action.data,
				register_mentor_basic_details_errors: {}
			};
		}
		case MENTOR_REGISTER_BASIC_DETAILS_FAILURE: {
			return {
				register_mentor_basic_details_loading: false,
				register_mentor_basic_details_errors: action.errors
			};
		}
		/** end::Register redux */

		/** begin::Register redux */
		case MENTOR_REGISTER_CHECKOUT_REQUEST: {
			return {
				register_mentor_checkout_loading: true,
			};
		}
		case MENTOR_REGISTER_CHECKOUT_SUCCESS: {
			return {
				register_mentor_checkout_loading: false,
				register_mentor_checkout: action.data,
				register_mentor_checkout_errors: {}
			};
		}
		case MENTOR_REGISTER_CHECKOUT_FAILURE: {
			return {
				register_mentor_checkout_loading: false,
				register_mentor_checkout_errors: action.errors
			};
		}
		/** end::Register redux */

		/** begin::Sign in redux */
		case MENTOR_SIGN_IN_REQUEST: {
			return {
				sign_in_mentor_loading: true,
				sign_in_mentor_user: {},
				sign_in_mentor_errors: {}
			};
		}
		case MENTOR_SIGN_IN_SUCCESS: {
			return {
				sign_in_mentor_loading: false,
				sign_in_mentor_user: action.data,
				sign_in_mentor_errors: {}
			};
		}
		case MENTOR_SIGN_IN_FAILURE: {
			return {
				sign_in_mentor_loading: false,
				sign_in_mentor_user: {},
				sign_in_mentor_errors: action.errors
			};
		}
		/** end::Sign in redux */

		/** begin::Send password reset link in redux */
		case MENTOR_SEND_PWD_RST_LINK_REQUEST: {
			return {
				send_mentor_pwd_rst_link_loading: true,
				send_mentor_pwd_rst_link_request: action.request,
				send_mentor_pwd_rst_link: {}
			};
		}
		case MENTOR_SEND_PWD_RST_LINK_SUCCESS: {
			return {
				send_mentor_pwd_rst_link_loading: false,
				send_mentor_pwd_rst_link: action.data,
			};
		}
		case MENTOR_SEND_PWD_RST_LINK_FAILURE: {
			return {
				send_mentor_pwd_rst_link_loading: false,
				send_mentor_pwd_rst_link: {},
				send_mentor_pwd_rst_link_errors: action.errors
			};
		}
		/** end::Send password reset link in redux */

		/** begin::Reset password redux */
		case MENTOR_RESET_PASSWORD_REQUEST: {
			return {
				reset_mentor_password_loading: true,
			};
		}
		case MENTOR_RESET_PASSWORD_SUCCESS: {
			return {
				reset_mentor_password_loading: false,
			};
		}
		case MENTOR_RESET_PASSWORD_FAILURE: {
			return {
				reset_mentor_password_loading: false,
				reset_mentor_password_errors: action.errors
			};
		}
		/** end::Reset password redux */

		/** begin::Contact us redux */
		case CONTACT_US_REQUEST: {
			return {
				contact_us_loading: true,
			};
		}
		case CONTACT_US_SUCCESS: {
			return {
				contact_us_loading: false,
				contact_us: action.data
			};
		}
		case CONTACT_US_FAILURE: {
			return {
				contact_us_loading: false,
				contact_us_errors: action.errors
			};
		}
		/** end::Contact us redux */

		/** begin::Contact us redux */
		case CHECK_MENTOR_AUTH_STATUS_REQUEST: {
			return {
				mentor_auth_status_loading: true,
			};
		}
		case CHECK_MENTOR_AUTH_STATUS_SUCCESS: {
			return {
				mentor_auth_status_loading: false,
				mentor_auth_status: action.data
			};
		}
		case CHECK_MENTOR_AUTH_STATUS_FAILURE: {
			return {
				mentor_auth_status_loading: false,
				mentor_auth_status_errors: action.errors
			};
		}
		/** end::Contact us redux */

		default:
			return state;
	}
}
