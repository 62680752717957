import React from "react";
import { InputLabel, OutlinedInput } from "@mui/material";
import { FormHelperText } from "@mui/material";
import { maskInput } from "../_helpers";
import { FormInputStyled } from ".";

const FormInput = ({ name, label, placeholder, type = 'text', className, id, value, onChange, isReadOnly = false, maxLength = 255, maskType, tabIndex, error, info, ...props }) => {

    const checkKeyPress = (e) => {
        return maskInput(maskType, e);
    };

    const handleChange = (e) => {
        onChange(e);
    };

    return (
        <FormInputStyled fullWidth>
            <InputLabel htmlFor={name}>{label}</InputLabel>
            <OutlinedInput
                aria-describedby={`helper_${name}`}
                type={type}
                placeholder={placeholder ? placeholder : label}
                name={name}
                value={value}
                onChange={handleChange}
                onKeyDown={checkKeyPress}
                label={label}
                error={error ? true : false}
                inputProps={{ maxLength: maxLength }}
                readOnly={isReadOnly}
                {...props}
            />
            {(info) && <FormHelperText id={`helper_${name}`}>{info}</FormHelperText>}
            {(error) && <FormHelperText id={`error_${name}`} error>{error}</FormHelperText>}
        </FormInputStyled>
    );
};

export { FormInput };
