import monthCalendar from "../../../../_root/components/form/date-and-time-pickers/monthCalendar";
import colors from "../../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    ...monthCalendar,
    styleOverrides: {
        ...monthCalendar.styleOverrides,
        root: {
            ...monthCalendar.styleOverrides.root,
            color: colors.text.main,
        }
    }
};
