import { FormControl, styled } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

const FormDatePickerStyled = styled(({ ...props }) => <FormControl {...props} />)``

const StyledDatePicker = styled(({ ...props }) => <DatePicker {...props} />)`
	& .MuiPaper-root {
		padding: 0; // Adjust the padding as needed
	}
`

export { FormDatePickerStyled, StyledDatePicker }
