import borders from "../../base/borders";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultProps: {},
  styleOverrides: {
    root: {
      borderRadius: `${borders.borderRadius.card}`
    },
  },
  variants: []
};
