import React from 'react';
import { Box, styled } from '@mui/material';

const PreAuthStyled = styled(({ ...props }) => <Box {...props} />)`
	& .grid-wrapper {
		margin-top: ${(props) => (props.havebanner === 'true' ? 0 : props.theme.spacing(8))};
		${(props) => props.theme.breakpoints.up('md')} {
			margin-top: ${(props) => (props.havebanner === 'true' ? 0 : props.theme.spacing(11))};
		}
	}
`;

export { PreAuthStyled };
