import {
	MENTEE_REGISTER_AUTH_DETAILS_REQUEST, MENTEE_REGISTER_AUTH_DETAILS_SUCCESS, MENTEE_REGISTER_AUTH_DETAILS_FAILURE,
	MENTEE_REGISTER_BASIC_DETAILS_REQUEST, MENTEE_REGISTER_BASIC_DETAILS_SUCCESS, MENTEE_REGISTER_BASIC_DETAILS_FAILURE,
	MENTEE_REGISTER_CHECKOUT_REQUEST, MENTEE_REGISTER_CHECKOUT_SUCCESS, MENTEE_REGISTER_CHECKOUT_FAILURE,
	MENTEE_SIGN_IN_REQUEST, MENTEE_SIGN_IN_SUCCESS, MENTEE_SIGN_IN_FAILURE,
	MENTEE_SEND_PWD_RST_LINK_REQUEST, MENTEE_SEND_PWD_RST_LINK_SUCCESS, MENTEE_SEND_PWD_RST_LINK_FAILURE,
	MENTEE_RESET_PASSWORD_REQUEST, MENTEE_RESET_PASSWORD_SUCCESS, MENTEE_RESET_PASSWORD_FAILURE,
} from "../../actions";

/**
 * This is defining the initial state of the Redux store for the application.
 * It contains several properties with their initial values:
 * */
const initialState = {
	register_mentee_auth_details_loading: false, register_mentee_auth_details: {}, register_mentee_auth_details_errors: {},
	register_mentee_basic_details_loading: false, register_mentee_basic_details: {}, register_mentee_basic_details_errors: {},
	register_mentee_checkout_loading: false, register_mentee_checkout: {}, register_mentee_checkout_errors: {},
	sign_in_mentee_loading: false, sign_in_mentee: {}, sign_in_mentee_errors: {},
	send_mentee_pwd_rst_link_loading: false, send_mentee_pwd_rst_link_request: {}, send_mentee_pwd_rst_link: {}, send_mentee_pwd_rst_link_errors: {},
	reset_mentee_password_loading: false, reset_mentee_password_errors: {},
	contact_us_loading: false, contact_us: {}, contact_us_errors: {},
};


export function MenteeAuthReducer(state = initialState, action) {
	switch (action.type) {

		/** begin::Register redux */
		case MENTEE_REGISTER_AUTH_DETAILS_REQUEST: {
			return {
				register_mentee_auth_details_loading: true,
			};
		}
		case MENTEE_REGISTER_AUTH_DETAILS_SUCCESS: {
			return {
				register_mentee_auth_details_loading: false,
				register_mentee_auth_details: action.data,
				register_mentee_auth_details_errors: {}
			};
		}
		case MENTEE_REGISTER_AUTH_DETAILS_FAILURE: {
			return {
				register_mentee_auth_details_loading: false,
				register_mentee_auth_details_errors: action.errors
			};
		}
		/** end::Register redux */

		/** begin::Register redux */
		case MENTEE_REGISTER_BASIC_DETAILS_REQUEST: {
			return {
				register_mentee_basic_details_loading: true,
			};
		}
		case MENTEE_REGISTER_BASIC_DETAILS_SUCCESS: {
			return {
				register_mentee_basic_details_loading: false,
				register_mentee_basic_details: action.data,
				register_mentee_basic_details_errors: {}
			};
		}
		case MENTEE_REGISTER_BASIC_DETAILS_FAILURE: {
			return {
				register_mentee_basic_details_loading: false,
				register_mentee_basic_details_errors: action.errors
			};
		}
		/** end::Register redux */

		/** begin::Register redux */
		case MENTEE_REGISTER_CHECKOUT_REQUEST: {
			return {
				register_mentee_checkout_loading: true,
			};
		}
		case MENTEE_REGISTER_CHECKOUT_SUCCESS: {
			return {
				register_mentee_checkout_loading: false,
				register_mentee_checkout: action.data,
				register_mentee_checkout_errors: {}
			};
		}
		case MENTEE_REGISTER_CHECKOUT_FAILURE: {
			return {
				register_mentee_checkout_loading: false,
				register_mentee_checkout_errors: action.errors
			};
		}
		/** end::Register redux */

		/** begin::Sign in redux */
		case MENTEE_SIGN_IN_REQUEST: {
			return {
				sign_in_mentee_loading: true,
				sign_in_mentee_user: {},
				sign_in_mentee_errors: {}
			};
		}
		case MENTEE_SIGN_IN_SUCCESS: {
			return {
				sign_in_mentee_loading: false,
				sign_in_mentee_user: action.data,
				sign_in_mentee_errors: {}
			};
		}
		case MENTEE_SIGN_IN_FAILURE: {
			return {
				sign_in_mentee_loading: false,
				sign_in_mentee_user: {},
				sign_in_mentee_errors: action.errors
			};
		}
		/** end::Sign in redux */

		/** begin::Send password reset link in redux */
		case MENTEE_SEND_PWD_RST_LINK_REQUEST: {
			return {
				send_mentee_pwd_rst_link_loading: true,
				send_mentee_pwd_rst_link_request: action.request,
				send_mentee_pwd_rst_link: {}
			};
		}
		case MENTEE_SEND_PWD_RST_LINK_SUCCESS: {
			return {
				send_mentee_pwd_rst_link_loading: false,
				send_mentee_pwd_rst_link: action.data,
			};
		}
		case MENTEE_SEND_PWD_RST_LINK_FAILURE: {
			return {
				send_mentee_pwd_rst_link_loading: false,
				send_mentee_pwd_rst_link: {},
				send_mentee_pwd_rst_link_errors: action.errors
			};
		}
		/** end::Send password reset link in redux */

		/** begin::Reset password redux */
		case MENTEE_RESET_PASSWORD_REQUEST: {
			return {
				reset_mentee_password_loading: true,
			};
		}
		case MENTEE_RESET_PASSWORD_SUCCESS: {
			return {
				reset_mentee_password_loading: false,
			};
		}
		case MENTEE_RESET_PASSWORD_FAILURE: {
			return {
				reset_mentee_password_loading: false,
				reset_mentee_password_errors: action.errors
			};
		}
		/** end::Reset password redux */

		default:
			return state;
	}
}
