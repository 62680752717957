import React from 'react';
import { AppBar, styled } from '@mui/material';

const HeaderStyled = styled(({ ...props }) => <AppBar position="absolute" {...props} />)`
	z-index: 1200;
	background-color: ${(props) => (props.isheaderwhite === 'true' ? `transparent` : props.theme.palette.common.white)};
	& svg {
		fill:${(props) => (props.isheaderwhite === 'true' ? props.theme.palette.common.white : props.theme.palette.common.black)};
	}
	& .logo-box {
		display: flex;
		align-items: center;
		justify-content: center;
		${(props) => props.theme.breakpoints.up('md')} {
			& svg {
				min-height: 40px;
			}
		}
	}
`;

export { HeaderStyled };
