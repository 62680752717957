import React from 'react'
import { styled, Box } from '@mui/material';

const ProfileStyled = styled(({ ...props }) => (<Box {...props} />))`

    & .MuiList-root {
        & .MuiMenuItem-root {
            margin-bottom : ${props => `${props.theme.spacing(1)}`};
            background-color: ${props => props.theme.palette.background.card};
            padding: ${props => `${props.theme.spacing(1.5)} ${props.theme.spacing(2)}`};
        }
        & .MuiListItemText-root{
            & .MuiTypography-root {
              font-size:  ${props => props.theme.typography.size.sm};
            }
        }
        & .active {
            background-color: ${props => props.theme.palette.primary.main};
            & .MuiTypography-root {
             color: ${props => (props.theme.palette.common.white)} !important;
            }
            .MuiSvgIcon-root {
                color: ${props => (props.theme.palette.common.white)} !important;
            }
        }
    }

    & .MuiCard-root{
        padding: ${props => `${props.theme.spacing(3)}`};
        & .MuiCardHeader-root {
            margin-bottom:0;
        }
        & .MuiCardContent-root{
            &:last-child {
                padding-bottom: ${props => `${props.theme.spacing(1)}`};
            }
        }
    }

    & .MuiAlert-root {
        & .MuiButton-root {
            padding: ${props => `0 0 0 ${props.theme.spacing(1)}`};
        }
    }

`;

export { ProfileStyled }