/**
 * The base colors for the Soft UI Dashboard PRO Material.
 * You can add new color using this file.
 * You can customized the colors for the entire Soft UI Dashboard PRO Material using their file.
 */

import colors from "../../_root/base/colors";

const colorPalette = {

  background: {
    default: "#FFFFFF",
    sidenav: "#1E1E2D",
    card: "#EFEFEF",
    paper: "#EFEFEF",
    input: "#FFFFFF"
  },

  text: {
    light: "#A2A0A0",
    main: "#A2A0A0",
    dark: "#696767",
    // dark: "#171717",
  },

};

// eslint-disable-next-line import/no-anonymous-default-export
export default {

  ...colors,

  background: colorPalette.background,

  text: {
    light: colorPalette.text.light,
    main: colorPalette.text.main,
    dark: colorPalette.text.dark,
    primary: colors.primary.main,
    secondary: colors.secondary.main,
    disabled: colors.grey[500]
  },

  badgeColors: {
    primary: {
      background: colors.primary.main,
      text: colorPalette.text.light
    },

    secondary: {
      background: colors.secondary.main,
      text: colorPalette.text.light
    },

    info: {
      background: colors.info.main,
      text: colorPalette.text.light
    },

    success: {
      background: colors.success.main,
      text: colorPalette.text.light
    },

    warning: {
      background: colors.warning.main,
      text: colorPalette.text.light
    },

    error: {
      background: colors.error.main,
      text: colorPalette.text.light
    },

    light: {
      background: colors.light.main,
      text: colorPalette.text.dark
    },

    dark: {
      background: colors.dark.main,
      text: colorPalette.text.light
    },
  },

};
