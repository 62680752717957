import React from 'react'
import { Avatar, Grid, Typography, Box, Chip, CardContent, Stack, Card } from '@mui/material'
import { AccountCircle, Email, Phone } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { ProfileDetailsStyled } from '.';

function ProfileDetails() {

    /* Destructuring the state from redux store. */
    const { user } = useSelector((state) => state.ProfileReducer);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <ProfileDetailsStyled>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} display="flex" justifyContent="center" alignItems="center">
                                    <Box display="flex" justifyContent="center" alignItems="center">
                                        <Avatar alt={user.short_name} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                    <Stack spacing={1}>
                                        <Typography variant="h2" color="primary">{user.name}</Typography>
                                        <Typography variant="h6"><AccountCircle />&nbsp;{user.role}&nbsp;|&nbsp;<Chip size='small' label={user.status} color={user.status === 'active' ? 'success' : 'error'} /></Typography>
                                        {user.mobile_number && <Typography variant="body1"><Phone />&nbsp;{user.mobile_number}</Typography>}
                                        <Typography variant="body1"><Email />&nbsp;{user.email}</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </ProfileDetailsStyled>
            </Grid>
        </Grid>
    )
}


export { ProfileDetails };


