
import React from 'react';
import { StyledPageHeader } from '.';

function PageHeader({ title, action = null, ...props }) {
    return (
        <StyledPageHeader title={title} titleTypographyProps={{ variant: 'h5', component: 'h5' }} subheaderTypographyProps={{ variant: 'caption', component: 'caption', color: 'text' }} action={action} {...props} />
    );
}

export { PageHeader };
