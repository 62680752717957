module.exports = {
    /**
     * function to validate the registration form inputs
     * @param {String} name Input name
     * @param {String} value Input value
     * @param {Object} [data={}] All input values stored in the state
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    validate: (name, value, data = {}) => {
        //eslint-disable-next-line
        const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        const errors = {};
        switch (name) {
            case 'first_name': {
                errors.first_name = '';
                if (value === '')
                    errors.first_name = 'Enter your first name.';
                break;
            } case 'last_name': {
                errors.last_name = '';
                if (value === '')
                    errors.last_name = 'Enter your last name.';
                break;
            } case 'dob': {
                errors.dob = '';
                if (value === '')
                    errors.dob = 'Enter your date of birth.';
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
};