import React, { useEffect, useState } from 'react';
import { PreAuth } from '../../../../_layouts/pre-auth';
import { BecomePavePartnerReferAndEarnFormStyled } from './BecomePavePartnerReferAndEarnFormStyled';
import { Box, CardMedia, Grid, IconButton, Stack, Typography } from '@mui/material';
import { Animate, FormInput, RouteLink, SubmitButton } from '../../../../_components';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalAction, ReferAndEarnAction } from '../../../../redux/actions';
import { APP_ENDPOINT, handleInputChange, validateForm } from '../../../../_helpers';
import { validate } from './BecomePavePartnerReferAndEarnFormValidation';
import { ContentCopy } from '@mui/icons-material';

import instagramIcon from '../../../../_theme/images/social-media-icons/instagram-icon.png';
import xIcon from '../../../../_theme/images/social-media-icons/x-icon.png';
import tikTokIcon from '../../../../_theme/images/social-media-icons/tik-tok-icon.png';


// Initialize form input values to null
const inputs = { email: '', organization: '', third_party_type: 'pave_partner' };

const BecomePavePartnerReferAndEarnForm = () => {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /* Destructuring the state from redux store. */
    const { reference_details_loading, reference_details, reference_details_errors } = useSelector((state) => state.ReferAndEarnReducer);
    const getReferenceDetails = (params) => dispatch(ReferAndEarnAction.getReferenceDetails(params));

    /* Initializing the state with the default values. */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });

    const [emptyWarning, setEmptyWarning] = useState(false);

    /* This is a react hook which is used to update the state when the value changes. */
    useEffect(() => {
        setErrors({ ...reference_details_errors });
    }, [reference_details_errors]);

    /**
      * It takes the name and value of the input, validates it, and sets the errors and data state
      * @param {Object} e form object
      * @param {String} e.name input name
      * @param {String} e.value input value
      * @return {null}
      * @author Akshay N
      * @created_at 20/05/2023
      */
    function handleChange(e) {
        setEmptyWarning(false);
        const { name, value } = handleInputChange(e);
        /* Validating the input and setting the errors state. */
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
      * The function handles the submit event of the form. It prevents the default action of the form,
      * sets the isSubmitted state to true, and then validates the form. If the form is valid, it
      * dispatches the corresponding action
      * @param {Object} e the event object
      * @return {null}
      * @author Akshay N
      * @created_at 20/05/2023
      */
    function handleSubmit(e) {

        e.preventDefault();
        setAction({ isSubmitted: true });

        /* Iterating through the data object and validating each key value pair. */
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value);
            formErrors[name] = inputError[name];
        }

        /* Checking if the form is valid or not. If the form is not valid, it sets the errors state and returns. */
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchRegisterAction();
    }

    /**
     * function to dispatch registration api
     * @param {null}
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    async function dispatchRegisterAction() {
        const registered = await getReferenceDetails(data);
        if (registered && registered.status === 1) {

            setEmptyWarning(false);
            /**
            * Checking if the user is redirected from a page, and
            * if so, redirects to that page, else redirects to the sign in page.
            */
            // let redirectPath = `${preAuthRoutes('mentor_register_basic_details').path}?authToken=${registered.data._id}`;
            // navigate(redirectPath);
        }
    }

    const copyCodeToClipboard = () => {
        navigator.clipboard.writeText(`${reference_details ? `${APP_ENDPOINT}?ref=${reference_details}` : ``}`);
        dispatch(GlobalAction.showToastMessage(true, `Copied to clipboard.`));
    };

    const handleDiv = (e) => {
        if (!reference_details) {
            setEmptyWarning(true);
        }
    };

    return (
        <PreAuth>
            <BecomePavePartnerReferAndEarnFormStyled>
                <Grid container spacing={3} direction={`column`} className='content' display={`flex`} justifyContent={`center`} alignItems={`center`}>
                    <Grid item xs={4} >
                        <Stack>
                            <Animate >
                                <form onSubmit={handleSubmit} noValidate className='w-100'>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={3}
                                                label='Email address'
                                                name='email'
                                                value={data.email}
                                                error={action.isSubmitted && errors.email ? errors.email : ''}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={3}
                                                label='Organization/Company (Optional)'
                                                name='organization'
                                                value={data.organization}
                                                error={action.isSubmitted && errors.organization ? errors.organization : ''}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <SubmitButton color='primary' label={'Submit'} fullWidth loading={reference_details_loading} />
                                        </Grid>
                                    </Grid>
                                </form>
                            </Animate>
                            <Box onClick={handleDiv} >
                                {emptyWarning && <Box sx={{ color: `red`, fontSize: `12px` }}>Please enter above information and press submit</Box>}
                                <Box className={!reference_details ? 'disabled' : ''} sx={{ width: `100%`, pt: 4 }} >
                                    <Animate>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" alignItems="center" justifyContent="center" >
                                                <Stack spacing={1} direction={'row'} className='w-100' display={`flex`} alignItems={`center`} justifyContent={`space-between`}>
                                                    <Typography variant="body2" color="initial">Generate Personal Link:</Typography>
                                                    {/* <SubmitButton label={'Generate'} loading={reference_details_loading} size="small" style={{ fontSize: `12px`, fontWeight: `400` }} /> */}
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  >
                                                <Stack spacing={1} direction={'row'} display={`flex`} alignItems={`center`} justifyContent={`center`} sx={{ border: `1px solid #333` }}>
                                                    <Typography variant="body2" component={`span`} sx={{ fontSize: `10px` }}>{reference_details ? `${APP_ENDPOINT}?ref=${reference_details}` : ``}</Typography>
                                                    <IconButton IconButton onClick={copyCodeToClipboard}> <ContentCopy /> </IconButton>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  >
                                                <Stack spacing={3}>
                                                    <Typography variant="body2">Share link on your Instagram/other social channels </Typography>
                                                    <Stack direction={'row'} spacing={6} display={'flex'} alignItems={'center'} justifyContent={`space-evenly`}>
                                                        <RouteLink to={'https://instagram.com'} label={<CardMedia component={`img`} src={instagramIcon} sx={{ width: `50px` }} />} target="_blank" />
                                                        <RouteLink to={'https://twitter.com'} label={<CardMedia component={`img`} src={xIcon} sx={{ width: `50px` }} />} target="_blank" />
                                                        <RouteLink to={'https://tiktok.com'} label={<CardMedia component={`img`} src={tikTokIcon} sx={{ width: `50px` }} />} target="_blank" />
                                                    </Stack>
                                                    <Typography variant="body2">Text/email the link to teammates, friends, and people in your network</Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Animate>
                                </Box>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </BecomePavePartnerReferAndEarnFormStyled>
        </PreAuth>
    );
};

export { BecomePavePartnerReferAndEarnForm };