import React from 'react';
import { SpotlightStyled } from './SpotlightStyled';
import { Box, CardMedia, Grid, Stack, Typography } from '@mui/material';

import spotlightImage from './../../../../../_theme/images/spotlight.jpg';
import { Animate } from '../../../../../_components';
import bannerImage from './../../../../../_theme/images/banner-behind-numbers-image.png';

const Spotlight = () => {
    return (
        <SpotlightStyled>
            <Typography variant="h5" className='heading'>Spotlight: Youth Mentorship</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} display={`flex`} justifyContent={`left`} alignItems={`center`}>
                    <Stack spacing={2} className='main-stack'>
                        <Grid container spacing={0} >
                            <Grid item xs={5} md={5} display={`flex`} alignItems={`center`} justifyContent={`center`} >
                                <Box className='spotlight-media' sx={{ borderRadius: `16px` }}>
                                    <Box style={{ background: `url(${spotlightImage}) center center` }} sx={{ borderRadius: `16px` }} />
                                </Box>
                            </Grid>
                            <Grid item xs={7} md={7} display={`flex`} alignItems={`center`} justifyContent={`start`} sx={{ p: 2 }} >
                                <Typography variant="h5" sx={{ fontWeight: 700 }}>The effects of youth having a Mentor when at risk of falling off track</Typography>
                            </Grid>
                        </Grid>
                        <Typography variant="body2" sx={{ fontSize: `18px` }}><b>55%</b> enroll in college</Typography>
                        <Typography variant="body2" sx={{ fontSize: `18px` }}><b>78%</b> volunteer regularly</Typography>
                        <Typography variant="body2" sx={{ fontSize: `18px` }}><b>90%</b> want to become a Mentor</Typography>
                        <Typography variant="body2" sx={{ fontSize: `18px` }}><b>130%</b> more likely to go on to hold leadership positions</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6} display={`flex`} alignItems={`center`} justifyContent={`center`} sx={{ display: { md: `none` } }}>
                    <Animate>
                        <CardMedia component={`img`} src={bannerImage} />
                    </Animate>
                </Grid>
            </Grid>
        </SpotlightStyled>
    );
};

export { Spotlight };