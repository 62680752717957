import pickersToolbar from "../../../../_root/components/form/date-and-time-pickers/pickersToolbar";
import colors from "../../../base/colors";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    ...pickersToolbar,
    styleOverrides: {
        ...pickersToolbar.styleOverrides,
        root: {
            ...pickersToolbar.styleOverrides.root,
            "& .MuiTypography-root": {
                color: colors.text.main
            }
        },
        content: {
            "& .MuiTypography-root": {
                color: colors.text.main
            }
        }
    }
};
