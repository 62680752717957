import React from 'react';
import { Box, styled } from '@mui/material';

const AboutAppStyled = styled(({ ...props }) => <Box {...props} />)`
	& .MuiPaper-root {
		padding: ${(props) => props.theme.spacing(4)};
		border-radius: ${(props) => props.theme.borders.borderRadius.section};
		min-height: ${(props) => `${props.theme.functions.pxToRem(180)}`};
		display: flex;
		align-items: flex-start;
		background-color: #f1f2f2;
		& .MuiStack-root {
			width: 100%;
			& .MuiCardMedia-root {
				height: ${(props) => `${props.theme.functions.pxToRem(48)}`};
				width: ${(props) => `${props.theme.functions.pxToRem(48)}`};
			}
			& .MuiTypography-h5 {
				color: #373435;
			}
			& .MuiTypography-body1 {
				color: #737373;
			}
		}
	}
	& .swiper-button-next {
		display: none;
	}
	& .swiper-button-prev {
		display: none;
	}
	& .SwiperButtons {
		position: absolute;
		top: 0;
		right: 0;
	}
`;

export { AboutAppStyled };
