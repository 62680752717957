import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormHelperText } from '@mui/material';
import { FormDatePickerStyled } from '.';
import { useState } from 'react';
import dayjs from 'dayjs';
import { useEffect } from 'react';


function FormDatePicker({ label, name, placeholder, onChange, tabIndex, id, error, isReadOnly = false, pattern, maskType, minDate = null, value, info, inputFormat, ...props }) {

    const [val, setVal] = useState((value && value !== '') ? dayjs(value) : null);
    const [err, setErr] = useState(error);

    const handleChange = (e) => {
        setVal(e);
        onChange({ target: { type: `date_time`, name: name, value: dayjs(e).isValid() ? e.toISOString() : null } });
    };

    useEffect(() => {
        setErr(error);
    }, [error]);

    const setInputError = (e) => {
        const errorMessages = {
            'maxDate': '',
            'minDate': 'Please select a date in the first quarter of 2022',
            'invalidDate': 'Your date is not valid',
            'disableFuture': 'Your date is not valid',
        };
        setErr(errorMessages[e] || '');
    };


    return (

        <FormDatePickerStyled fullWidth >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    value={val}
                    onChange={handleChange}
                    label={label}
                    minDate={minDate}
                    inputFormat={inputFormat ?? 'MM/dd/yyyy'}
                    readOnly={isReadOnly}
                    onError={(newError) => setInputError(newError)}
                    slotProps={{
                        dialog: { className: 'MuiDatePickerDialog' },
                        textField: { error: err }
                    }}
                    {...props}
                />
            </LocalizationProvider>
            {(info) && <FormHelperText id={`helper_${name}`}>{info}</FormHelperText>}
            {(err) && <FormHelperText id={`error_${name}`} error>{err}</FormHelperText>}
        </FormDatePickerStyled>

    );
}

export { FormDatePicker };