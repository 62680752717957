import React from "react";
import Typography from '@mui/material/Typography';
import { Box, Button } from "@mui/material";
import { RouteLink } from "../../../_components";
import { IOS_APP_URL } from "../../../_helpers";

function Dashboard() {
    return (
        <React.Fragment>
            <Box sx={{ width: `100%`, height: `90vh`, display: `flex`, alignItems: `center`, justifyContent: `center` }}>
                <RouteLink  to={IOS_APP_URL} label={<Button variant='contained' color='primary' className='btn-download' sx={{ backgroundColor: `#48b1ff` }} fullWidth>Download the Pave iOS App</Button>} target="_blank" />
            </Box>
        </React.Fragment>
    );
}

export { Dashboard };
