module.exports = {

    /**
     * function to validate the sign in form inputs
     * @param {String} name Input name
     * @param {String} value Input value
     * @param {Object} [data={}] All input values stored in the state
     * @return {null}
     * @author Akshay N
     * @created_at 20/05/2023
     */
    validate: (name, value, data = {}) => {
        //eslint-disable-next-line
        const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        const errors = {};
        switch (name) {
            /* Checking if the email is valid or not. */
            case 'email': {
                errors.email = validEmailRegex.test(value) ? '' : 'Email provided is invalid';
                if (value === '')
                    errors.email = 'Enter an email address';
                break;
            }
            /* Checking if the password is empty or not. */
            case 'password': {
                errors.password = '';
                if (value === '')
                    errors.password = 'Enter your password';
                break;
            }
            default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }
};