import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import { Container, IconButton, Toolbar } from '@mui/material';
import { Box } from '@mui/system';
import { getPreElementNameByPath, preAuthRoutes } from '../../../routes';
import { HeaderStyled } from '.';
import { useContext } from 'react';
import { AppNameContext } from '../../../_contexts';
import { Logo } from '../../../_components/logo';
import { RouteLink } from '../../../_components';
import { MenuBar } from '../menubar';
import { ArrowBack } from '@mui/icons-material';

function Header({ isheaderwhite, ...props }) {

    /** Initialize plugins and variables */
    const location = useLocation();
    const navigate = useNavigate();
    const APP_NAME = useContext(AppNameContext);

    const params = new URLSearchParams(location.search);
    const referenceId = params.get('ref') ?? sessionStorage.getItem('reference_id');

    /* Initializing the state with the default values. */
    const [pageTitle, setPageTitle] = useState('Loading');

    useEffect(() => {
        setPageTitle(getPreElementNameByPath(location.pathname));
    }, [location]);

    const goBack = () => {
        // Use the navigate function to navigate back
        navigate(-1);
    };

    useEffect(() => {
        sessionStorage.setItem('reference_id', referenceId);
    }, [referenceId]);


    return (
        <Fragment>
            <Helmet>
                <title>{`${APP_NAME}  ${pageTitle ? `| ${pageTitle}` : ''} `}</title>
            </Helmet>
            <HeaderStyled elevation={0} isheaderwhite={isheaderwhite}>
                <Container>
                    <Toolbar disableGutters={true}>
                        {location.pathname !== '/' && <IconButton sx={{ display: { xs: `block`, md: `none` } }} onClick={goBack}><ArrowBack /></IconButton>}
                        <Box sx={{ flexGrow: 1, display: { xs: `block`, md: `none` } }}></Box>
                        <Box className='logo-box'>
                            <RouteLink to={preAuthRoutes('landing_page').path} label={<Logo className='logo' fill={isheaderwhite === 'true' ? `#fff` : `#000`} />} />
                        </Box>
                        <Box sx={{ flexGrow: 1 }}></Box>
                        <MenuBar ismenuwhite={isheaderwhite} />
                    </Toolbar>
                </Container>
            </HeaderStyled>
        </Fragment>
    );
}

export { Header };
