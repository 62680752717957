import { Home, Password, Person, ManageAccounts, Notifications as NotificationsIcon, RowingOutlined, Warning } from "@mui/icons-material";
import { AccountStatus, ActivityLog, ChangePassword, Notifications, Profile, ProfileContactUpdate, ProfileDetails, ProfileUpdate } from "../app/post-auth/mentor/profile";
import { ErrorElement } from "../_components/layout/error-element";
import { Dashboard } from "../app/post-auth/dashboard";

/**
 * path: the URL path of the route
 * name: the name of the route, used for display purposes
 * element: the React component to be rendered when the route is accessed
 * layout: the layout component to be used for rendering the route
 * isMenuItem: a boolean flag to indicate whether the route should be displayed in a menu
 * menuIcon: the icon to be used for the route in the menu
 * module: the module that the route belongs to
 * isModuleMenuItem: a boolean flag to indicate whether the route should be displayed in the module's menu
 * errorElement: the React component to be rendered when an error occurs while rendering the route
 * menuPermission: the permission required to access the route
 */

const allRoutes = {

    /** begin::home module */
    'dashboard': {
        path: "/dashboard",
        name: "Dashboard",
        element: <Dashboard />,
        layout: "/post-auth",
        menuIcon: Home,
        module: 'dashboard',
        isMenuItem: true,
        isModuleMenuItem: true,
        errorElement: <ErrorElement />,
        menuPermission: 'dashboards'
    },
    /** end::home module */
    /** begin::profiles module */
    'profile': {
        path: "/profile/:type?",
        name: "Profile",
        element: <Profile />,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Person,
        module: 'profile',
        isModuleMenu: true,
        isModuleMenuItem: false,
        errorElement: <ErrorElement />,
    },
    'profile_details': {
        path: "/profile/details",
        name: "Profile",
        element: <ProfileDetails />,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Person,
        module: 'profile',
        isModuleMenuItem: true,
        errorElement: <ErrorElement />,
        parentRoute: 'profile'
    },
    // 'profile_update': {
    //     path: "/profile/update",
    //     name: "Profile Update",
    //     element: <ProfileUpdate />,
    //     layout: "/post-auth",
    //     isMenuItem: false,
    //     menuIcon: ManageAccounts,
    //     module: 'profile',
    //     isModuleMenuItem: true,
    //     errorElement: <ErrorElement />,
    //     parentRoute: 'profile'
    // },
    'profile_update_contact_info': {
        path: "/profile/update",
        name: "Update Contact Info",
        element: <ProfileContactUpdate />,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: ManageAccounts,
        module: 'profile',
        isModuleMenuItem: true,
        errorElement: <ErrorElement />,
        parentRoute: 'profile'
    },
    // 'change_password': {
    //     path: "/profile/change-password",
    //     name: "Change Password",
    //     element: <ChangePassword />,
    //     layout: "/post-auth",
    //     menuIcon: Password,
    //     isMenuItem: false,
    //     module: 'profile',
    //     errorElement: <ErrorElement />,
    //     isModuleMenuItem: true,
    //     parentRoute: 'profile'
    // },
    // 'notifications': {
    //     path: "/profile/notifications",
    //     name: "Notifications",
    //     element: <Notifications />,
    //     layout: "/post-auth",
    //     menuIcon: NotificationsIcon,
    //     isMenuItem: false,
    //     module: 'profile',
    //     isModuleMenuItem: true,
    //     parentRoute: 'profile'
    // },
    // 'activity_log': {
    //     path: "/profile/activity",
    //     name: "Activity Log",
    //     element: <ActivityLog />,
    //     layout: "/post-auth",
    //     menuIcon: RowingOutlined,
    //     isMenuItem: false,
    //     module: 'profile',
    //     errorElement: <ErrorElement />,
    //     isModuleMenuItem: true,
    //     parentRoute: 'profile'
    // },
    // 'account_status': {
    //     path: "/profile/status",
    //     name: "Account Status",
    //     element: <AccountStatus />,
    //     layout: "/post-auth",
    //     menuIcon: Warning,
    //     isMenuItem: false,
    //     module: 'profile',
    //     isModuleMenuItem: true,
    //     parentRoute: 'profile'
    // },
    /** end::profiles module */

};

/**
 * The function returns all routes or a specific set of routes based on the key type provided.
 * @param {String} [keyType=null] The parameter `keyType` is an optional parameter that can be passed to
 * the `postAuthRoutes` function. If a `keyType` is provided, the function will return only the routes associated
 * with that `keyType` from the `allRoutes` object.
 * @return {Object} The function takes an optional parameter `keyType` and returns either all the routes in the
 * `allRoutes` object if `keyType` is not provided or the routes corresponding to the `keyType` if it is provided.
 * @author Akshay N
 * @created_at 21/05/2023
 */
const postAuthRoutes = (keyType = null) => {
    return keyType ? allRoutes[keyType] : allRoutes;
};

/**
 * This function filters all routes based on whether they are menu items or module menu items with a  specific module,
 * and returns the filtered routes with modified paths.
 * @param {String} [module=false] - The `module` parameter is an optional string parameter that specifies the name of
 * a module.  If provided, the function will filter the routes to only include those that have `isModuleMenuItem` set to
 * `true` and whose `module` property matches the provided `module` string.
 * @returns {Object} The function `postAuthMenuRoutes` is returning an array of route objects that have either `isMenuItem`
 * or `isModuleMenuItem` properties set to `true`.If the `module` parameter is provided, it filters the routes to only
 * include those with `isModuleMenuItem` set to `true` and matching the  provided `module` name.
 * @author Akshay N
 * @created_at 02/04/2023
 */
const postAuthMenuRoutes = (module = false) => {
    /* The below code is a JavaScript function that filters an array of objects representing routes.
    The function takes an optional argument `module`, which is used to filter the routes based on
    whether they are module menu items or regular menu items. */
    return Object.values(allRoutes).filter(item => {
        return !module
            ? (item.isMenuItem === true)
            : (item.isModuleMenuItem === true && item.module === module)
                ? { ...item, path: item.path.split("/").pop() }
                : null;
    });
};

/**
 * The function creates an array of post-authentication menu bar routes by iterating over an object of routes
 * and organizing them based on their parent routes.
 * @param {String} [module=false] - This is the module name. If you want to get all the menu items, then pass
 * false. If you want to get all the menu items for a specific module, then pass the module name.
 * @return {Array} The function `postAuthMenuBarRoutes` is returning an array of objects that represent the menu bar
 * routes for a post-authentication user.  The objects in the array contain information about each route, such as
 * the route's name, path, and whether it is a child of another route. The function iterates over an input object called
 * `allRoutes`, filters out routes that are not menu items, and organizes the remaining routes into return array.
 * @author Akshay N
 * @created_at 02/04/2023
 */
const postAuthMenuBarRoutes = () => {

    const postSignInRoutes = allRoutes;

    let postRoutes = [];

    // Iterating over the object.
    for (const key in postSignInRoutes) {

        // Checking if the object has the property.
        if (postSignInRoutes.hasOwnProperty(key)) {
            if (postSignInRoutes[key].isMenuItem) {

                // Checking if the route has a parent route.
                if (postSignInRoutes[key].parentRoute) {

                    // Finding the index of the parent route in the array of objects.
                    const parentRoute = postSignInRoutes[key].parentRoute;
                    var postRouteIndex = postRoutes.findIndex(function (route) {
                        return route.slug === parentRoute;
                    });

                    // Checking if the parent route has any children. If not, it is creating an empty array
                    // and pushing the child route into it. */
                    if (!postRoutes[postRouteIndex]['children'])
                        postRoutes[postRouteIndex]['children'] = [];

                    postRoutes[postRouteIndex]['children'].push({ ...postSignInRoutes[key], slug: key });

                } else
                    postRoutes.push({ ...postSignInRoutes[key], slug: key });
            }
        }
    }

    return postRoutes;
};

/**
 * This function returns the name of a post element based on its path.
 * @param {String} path - The `path` parameter is a string representing the URL path of a route in a web application.
 * @returns {String} The function `getPostElementNameByPath` returns the name of the element associated with the
 * given path, which is obtained by searching for the path in the `allRoutes` object and returning the
 * corresponding `name` property. If no matching path is found, an empty string is returned.
 * @author Akshay N
 * @created_at 02/04/2023
 */
const getPostElementNameByPath = (path) => {
    if (path) {
        const elementItem = Object.values(allRoutes).find(item => item.path === path);
        return elementItem ? elementItem.name : '';
    }
};

export { postAuthRoutes, postAuthMenuRoutes, postAuthMenuBarRoutes, getPostElementNameByPath };
