import { Paper, Stack, Typography, CardMedia } from '@mui/material';
import React from 'react';
import { AboutAppStyled } from '.';
import { SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper } from 'swiper/react';

import findMatchSvg from './../../../../_theme/images/find-a-match.svg';
import ReachOutSvg from './../../../../_theme/images/reach-out.svg';
import StartMentorshipSvg from './../../../../_theme/images/start-mentorship.svg';

let data = [
    {
        icon: findMatchSvg,
        title: "Find a match",
        body: "Browse and discover potential matches"

    },
    {
        icon: ReachOutSvg,
        title: "Reach out",
        body: "Align on mentorship fit and goals"

    },
    {
        icon: StartMentorshipSvg,
        title: "Start Mentorship",
        body: "Subscription is requested, purchased, and begins"

    }
];

const AboutApp = () => {
    return (
        <AboutAppStyled >
            <Swiper slidesPerView={3} spaceBetween={30} navigation loop={true} modules={[Autoplay, Navigation]} autoplay={{ delay: 30000, disableOnInteraction: false }} breakpoints={{ 0: { slidesPerView: 1.3, }, 576: { slidesPerView: 1.7, }, 768: { slidesPerView: 2.2, }, 992: { slidesPerView: 3 }, 1200: { slidesPerView: 3 }, 1400: { slidesPerView: 3 } }}>
                {data.map((content, i) =>
                    <SwiperSlide key={i}>
                        <Paper elevation={0}>
                            <Stack spacing={1} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                                <CardMedia component={`img`} src={content.icon} />
                                <Typography variant="h5">{content.title}</Typography>
                                <Typography variant="body1" textAlign={`center`}>{content.body}</Typography>
                            </Stack>
                        </Paper>
                    </SwiperSlide>
                )}
            </Swiper>
        </AboutAppStyled>
    );
};

export { AboutApp };